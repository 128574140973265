export const newTeamSettings = {
    playerNames: [],
    notesUrl: "",
    ouijaChar: "",
    recentId: "",
    color: "",
    finishedGame: false,
    finishingTime: "",
    score: "",

    syncedObjects: [
        { id : "potionStrength", state : "default", type : "Inventory" },
        { id : "potionVision", state : "default", type : "Inventory" },      
        { id : "wand", state : "default", type : "Inventory" },
        { id : "cat", state : "default", type : "Inventory" },
        { id : "witchesHat", state : "default", type : "Inventory" },
        { id : "broom", state : "default", type : "Inventory" },
        
        { id : "ouija", state : "default", type : "Puzzle" },
        { id : "bookshelf", state : "default", type : "Puzzle" },
        { id : "lightPuzzle", state : "default", type : "Puzzle" },
        { id : "cemeteryPuzzle", state : "default", type : "Puzzle" },
        { id : "chessPuzzle", state : "default", type : "Puzzle" },
        { id : "frontDoor", state : "default", type : "Puzzle" },
        { id : "libraryDoor", state : "default", type : "Puzzle" },
        { id : "kitchenDoor", state : "default", type : "Puzzle" },
        { id : "bedroomdoor", state : "default", type : "Puzzle" },
        { id : "completedgame", state : "default", type : "Puzzle" },

        { id : "spider1", state : "default", type : "Spider" },
        { id : "spider2", state : "default", type : "Spider" },
        { id : "spider3", state : "default", type : "Spider" },
        { id : "spider4", state : "default", type : "Spider" },
        { id : "spider5", state : "default", type : "Spider" },
        { id : "spider6", state : "default", type : "Spider" },
        { id : "spider7", state : "default", type : "Spider" },
        { id : "spider8", state : "default", type : "Spider" },
        { id : "spider9", state : "default", type : "Spider" },

        { id : "candy1", state : "default", type : "Candy" },
        { id : "candy2", state : "default", type : "Candy" },
        { id : "candy3", state : "default", type : "Candy" },
        { id : "candy4", state : "default", type : "Candy" },
        { id : "candy5", state : "default", type : "Candy" },
        { id : "candy6", state : "default", type : "Candy" },
        { id : "candy7", state : "default", type : "Candy" },
        { id : "candy8", state : "default", type : "Candy" },
        { id : "candy9", state : "default", type : "Candy" },
        { id : "candy10", state : "default", type : "Candy" },
        { id : "candy11", state : "default", type : "Candy" },
        { id : "candy12", state : "default", type : "Candy" },
        { id : "candy13", state : "default", type : "Candy" },
        { id : "candy14", state : "default", type : "Candy" },
        { id : "candy15", state : "default", type : "Candy" },
        { id : "candy16", state : "default", type : "Candy" },
        { id : "candy17", state : "default", type : "Candy" },

        { id : "broomRiddle", state : "default", type : "Puzzle" },
        { id : "wandRiddle", state : "default", type : "Puzzle" },
        { id : "catRiddle", state : "default", type : "Puzzle" },
        { id : "hatRiddle", state : "default", type : "Puzzle" },
    ],

    clues: {
        ouija: 0,
        kitchen: 0,
        appleBobbing: 0,
        strengthPotion: 0,
        chess: 0,
        bookShelf: 0,
        light: 0,
        potionVision: 0,
        frontDoor: 0,
        hedgeMaze: 0,
        coffin: 0,
        pumpkin: 0,
    }
}

export const resetTeamSettings = {
    finishedGame: false,
    finishingTime: "",
    ouijaChar: "",
    recentId: "",
    color: "",
    score: "",
    
    syncedObjects: [
        { id : "potionStrength", state : "default", type : "Inventory" },
        { id : "potionVision", state : "default", type : "Inventory" },     
        { id : "wand", state : "default", type : "Inventory" },
        { id : "cat", state : "default", type : "Inventory" },
        { id : "witchesHat", state : "default", type : "Inventory" },
        { id : "broom", state : "default", type : "Inventory" },
        
        { id : "ouija", state : "default", type : "Puzzle" },
        { id : "bookshelf", state : "default", type : "Puzzle" },
        { id : "lightPuzzle", state : "default", type : "Puzzle" },
        { id : "cemeteryPuzzle", state : "default", type : "Puzzle" },
        { id : "chessPuzzle", state : "default", type : "Puzzle" },
        { id : "frontDoor", state : "default", type : "Puzzle" },
        { id : "libraryDoor", state : "default", type : "Puzzle" },
        { id : "kitchenDoor", state : "default", type : "Puzzle" },
        { id : "bedroomdoor", state : "default", type : "Puzzle" },
        { id : "completedgame", state : "default", type : "Puzzle" },

        { id : "spider1", state : "default", type : "Spider" },
        { id : "spider2", state : "default", type : "Spider" },
        { id : "spider3", state : "default", type : "Spider" },
        { id : "spider4", state : "default", type : "Spider" },
        { id : "spider5", state : "default", type : "Spider" },
        { id : "spider6", state : "default", type : "Spider" },
        { id : "spider7", state : "default", type : "Spider" },
        { id : "spider8", state : "default", type : "Spider" },
        { id : "spider9", state : "default", type : "Spider" },

        { id : "candy1", state : "default", type : "Candy" },
        { id : "candy2", state : "default", type : "Candy" },
        { id : "candy3", state : "default", type : "Candy" },
        { id : "candy4", state : "default", type : "Candy" },
        { id : "candy5", state : "default", type : "Candy" },
        { id : "candy6", state : "default", type : "Candy" },
        { id : "candy7", state : "default", type : "Candy" },
        { id : "candy8", state : "default", type : "Candy" },
        { id : "candy9", state : "default", type : "Candy" },
        { id : "candy10", state : "default", type : "Candy" },
        { id : "candy11", state : "default", type : "Candy" },
        { id : "candy12", state : "default", type : "Candy" },
        { id : "candy13", state : "default", type : "Candy" },
        { id : "candy14", state : "default", type : "Candy" },
        { id : "candy15", state : "default", type : "Candy" },
        { id : "candy16", state : "default", type : "Candy" },
        { id : "candy17", state : "default", type : "Candy" },

        { id : "broomRiddle", state : "default", type : "Puzzle" },
        { id : "wandRiddle", state : "default", type : "Puzzle" },
        { id : "catRiddle", state : "default", type : "Puzzle" },
        { id : "hatRiddle", state : "default", type : "Puzzle" },
    ],

    clues: {
        ouija: 0,
        kitchen: 0,
        appleBobbing: 0,
        strengthPotion: 0,
        chess: 0,
        bookShelf: 0,
        light: 0,
        potionVision: 0,
        frontDoor: 0,
        hedgeMaze: 0,
        coffin: 0,
        pumpkin: 0,
    }
}

export const newGameSettings = {
    hasGameStarted: false,
    gameLength: 60,
    startTime: "",
    scoresOpen: false,
    teamNames: [],
    scheduleTime: "",
    hasFacilitators: false,
    canGameBeStarted: false,
    seatsMax: 0,
    seatsCurrent: 0,
    users: [],
    archived: false,
}

export const resetGameSettings = {
    hasGameStarted: false,
    startTime: "",
    scoresOpen: false,
    canGameBeStarted: false,
    seatsCurrent: 0,
    users: [],
    archived: false,
}

export const MainHousePuzzles = [
    {id: "bedroomdoor", title: "Ouija Puzzle", clueid: "ouija", baseScore: 1000, timeExpected: 360},
    {id: "kitchenDoor", title: "Pumpkin Puzzle", clueid: "pumpkin", baseScore: 500, timeExpected: 540},
    {id: "wand", title: "Apple Bobbing", clueid: "appleBobbing", baseScore: 250, timeExpected: 630},
    {id: "potionStrength", title: "Strength Puzzle", clueid: "strengthPotion", baseScore: 750, timeExpected: 900},
    {id: "potionVision", title: "Vision Puzzle", clueid: "potionVision", baseScore: 750, timeExpected: 2610},
    {id: "frontDoor", title: "Front Door Puzzle", clueid: "frontDoor", baseScore: 500, timeExpected: 2790},
]

export const LibraryPuzzles = [
    //{id: "chessPuzzle", title: "Chess Puzzle", clueid: "chess"},
    {id: "witchesHat", title: "Chess Puzzle", clueid: "chess", baseScore: 500, timeExpected: 1080},
    {id: "bookshelf", title: "Bookshelf Puzzle", clueid: "bookShelf", baseScore: 1500, timeExpected: 1620},
    {id: "lightPuzzle", title: "Light Puzzle", clueid: "light", baseScore: 2000, timeExpected: 2340},
]

export const FrontYardPuzzles = [
    {id: "cat", title: "Hedge Maze", clueid: "hedgeMaze", baseScore: 250, timeExpected: 2880},
    {id: "cemeteryPuzzle", title: "Cemetery Puzzle", clueid: "coffin", baseScore: 1750, timeExpected: 3510},
]

export const FinalPuzzles = [
    {id: "completedgame", title: "Complete Game", baseScore: 250, timeExpected: 3600},
]

export const MainHouseCollectibles = [
    {id: "spider1", title: "Ouija table", type: "spider"},
    {id: "spider5", title: "Fireplace", type: "spider"},
    {id: "spider6", title: "Under cooktop", type: "spider"},
    {id: "candy1", title: "Bedroom", type: "candy"},
    {id: "candy2", title: "Mannequin", type: "candy"},
    {id: "candy8", title: "Fireplace", type: "candy"},
    {id: "candy9", title: "Oven", type: "candy"},
    {id: "candy10", title: "Wine rack", type: "candy"},
    {id: "candy11", title: "Cookpot", type: "candy"},
]

export const LibraryCollectibles = [
    {id: "spider7", title: "Bookshelf", type: "spider"},
    {id: "spider8", title: "Hallway", type: "spider"},
    {id: "spider9", title: "Balcony", type: "spider"},
    {id: "candy12", title: "Book pile", type: "candy"},
    {id: "candy13", title: "Chess board", type: "candy"},
    {id: "candy14", title: "Top of stairs", type: "candy"},
    {id: "candy15", title: "Master chair", type: "candy"},
    {id: "candy16", title: "Master bed", type: "candy"},
    {id: "candy17", title: "Balcony", type: "candy"},
]

export const FrontYardCollectibles = [
    {id: "spider2", title: "Top right on house", type: "spider"},
    {id: "spider3", title: "Maze", type: "spider"},
    {id: "spider4", title: "Chimney", type: "spider"},
    {id: "candy3", title: "Bench", type: "candy"},
    {id: "candy4", title: "Under porch steps", type: "candy"},
    {id: "candy5", title: "Gravestone", type: "candy"},
    {id: "candy6", title: "Tree", type: "candy"},
    {id: "candy7", title: "Hedge entrance tree candy", type: "candy"},
]

export const scenes = [
    {
        id: "Main House",
        collectibles: MainHouseCollectibles,
        puzzles: MainHousePuzzles,
        
    },
    {
        id: "Library",
        collectibles: LibraryCollectibles,
        puzzles: LibraryPuzzles,
    },
    {
        id: "Front Yard",
        collectibles: FrontYardCollectibles,
        puzzles: FrontYardPuzzles,
    },
    {   
        id: "Final",
        puzzles: FinalPuzzles,
    }
]

export const calculateAverageTeam = (teamList, placeholder) => {
    
    let averageTeam = {
        id: "average",
        data: {...teamList[0].data, name: "Average"}
    };

    console.log(averageTeam);

    return averageTeam;
}

export const calculateScore = (teamDetails, startTime, callback) => {
    const bonusGameStart = "1666055514183";
    const teamScores = [];
        Object.values(teamDetails).map((team, i) => { 

            //for every team, calculate team score
            let teamScore = 0;
            let teamInfo = {};
            //console.log(team.data.syncedObjects);
            
            //go through all synced objects and calculate the score per puzzle
            Object.values(team.data.syncedObjects).map((object, i) => { 
                if (object.type === "Spider") {
                    if (object.state === 'complete') {
                        teamScore += 50;
                    }
                } else if (object.type === "Candy") {
                    if (object.state === 'complete') {
                        teamScore += 25;
                    }
                } else {
                    
                    Object.values(scenes).map((scene, i) => {
                        if(team.type) {
                            teamScore += calculateScorePerScene(scene.puzzles, object, team, bonusGameStart);
                        } else if (team.type === undefined) {
                            teamScore += calculateScorePerScene(scene.puzzles, object, team, startTime);                
                        }
                    }) 
                }
                
            })
            teamInfo = {...team.data, type: team.type, score: teamScore};
            teamScores.push(teamInfo);
        })

        teamScores.sort((a,b) => { 
            //return (b.score - a.score);
            if (a.type !== undefined) {
                return 1;
            } else if (b.type !== undefined) {
                return -1;
            } else {
                return (b.score - a.score);  
            }
        })

        if (callback) {
            callback(teamScores);
        }
        return teamScores;
}
export const calculateScorePerScene = (scene, object, team, startTime) => {

    let sceneScore = 0;

    Object.values(scene).map((puzzle, i) => {
                
        if (object.id === puzzle.id) {
            
            sceneScore += calculateScorePerPuzzle(puzzle, object, team, startTime);             
        }
    })
    return sceneScore;
}

export const calculateScorePerPuzzle = (puzzle, object, team, startTime) => { 
        
    let finalScore = 0;
    let baseScore = 0;
    if (puzzle.baseScore) {

        baseScore = puzzle.baseScore;
        const maxPlusMinus = baseScore*0.3;

        if (puzzle.clueid) {
            if(team.data.clues[puzzle.clueid] > 0) {
                baseScore -= maxPlusMinus*team.data.clues[puzzle.clueid];
            }
        }
        
        if (object.timestamp) {
            const timeElapsedExpected = puzzle.timeExpected;
            const timeElasped = (object.timestamp.toDate().getTime() - startTime) / 1000;

            let scorePlusMinus = (timeElapsedExpected - timeElasped)/timeElapsedExpected * baseScore;
            if (timeElasped < 3600) {

                if (Math.abs(scorePlusMinus) > maxPlusMinus) {
                    
                    let sign = 1;
                    if (scorePlusMinus < 0) {
                        sign = -1; 
                    }
                    scorePlusMinus = maxPlusMinus*sign;
                    
                }
                finalScore = Math.trunc(baseScore + scorePlusMinus);
            }
            
        }
    }
    return (finalScore);
}
