import React from 'react';
import { FormGroup, InputGroup, Input, InputGroupText } from 'reactstrap';
import { GreyLabel, SmallFormFeedback, Colors, HighlightedInputGroupText } from '../../../../utils/budget-tool-specific';
import styled from 'styled-components';

const FormInputExperience = (props) => {

    const handleInputChange = (value) => {
        const newData = {...props.data};
        newData[props.id].value = value;
        props.setData(newData);
    }

    const toggle = () => {
        props.toggleAction(!props.toggleValue);
    }

    return (
        <React.Fragment>       
            {<FormGroup>
                <GreyLabel for={props.id}>{props.title}</GreyLabel>
                <InputGroup>
                    {props.currency && <HighlightedInputGroupText>{props.currency}</HighlightedInputGroupText> }
                    <HighlightedInput invalid={props.data[props.id].invalid} 
                                    type={props.type} 
                                    disabled={props.client}
                                    value={props.data[props.id].value} 
                                    onChange={e => handleInputChange(e.target.value)}
                                    fontSize={props.fontSize ? props.fontSize : "16px"}
                                    readOnly={props.client}> 
                        {props.options}
                    </HighlightedInput>
                    {props.contingency && <HighlightedInputGroupText>%
                    </HighlightedInputGroupText> }
                    <SmallFormFeedback>Required</SmallFormFeedback>
                    {props.toggle && <InputGroupText style={{marginRight:"1px", backgroundColor:"white", border:"none", fontSize:"12px", color:"black"}}>
                        {props.toggleTitle}
                    </InputGroupText>}
                    {props.toggle && <Input
                        addon
                        aria-label="Checkbox for following text input"
                        type="checkbox"
                        checked={props.toggleChecked}
                        value = {props.toggleValue}
                        onChange={() => toggle()}
                    />}
                </InputGroup>
            </FormGroup> }
        </React.Fragment>
    )
} 

export default FormInputExperience;

export const HighlightedInput = styled(Input)`
    font-size: ${props => props.fontSize ? props.fontSize : "16px"};
    margin: ${props => props.fontSize === "16px" ? "" : "0px 0px"};
    border: none;
    border-bottom: 1px solid ${Colors.primaryLight};
    border-radius: 0px;
    background-color: ${Colors.primaryLight};
    color: ${Colors.primaryDark};
    padding: ${props => props.fontSize === "16px" ? "5px 5px" : ""};
    &:hover {
        border-bottom: 1px solid ${Colors.primaryAccent};
    }
`