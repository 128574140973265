import React, { useState } from "react";
import styled from "styled-components";
import AdminGameButton from './admin-game-button';
import ArtemisAdmin from './games/artemis/artemis-admin';
import PirateShipAdmin from "./games/pirate-ship/pirate-ship-admin";
import OmegaZombiesAdmin from "./games/omega-protocol-zombies-facilitatorless/omega-protocol-zombies-admin";
import Halloween2023Admin from "./games/halloween/halloween-2023-admin";
import HolidayHouseAdmin from "./games/holiday-house/holiday-house-admin";

export const Admin = () => {
    const [selectedGame, setSelectedGame] = useState("");
    const [selectedInstance, setSelectedInstance] = useState({});
    const [background, setBackground] = useState("https://cdn.moniker-virtual.com/artemis/images/background.png");

    const select = (game, currentGame, setGame) => {
        setBackground("https://cdn.moniker-virtual.com/" + game + "/images/background.png");
        setSelectedInstance({});
        if (currentGame === game){
            setGame("");
        }
        else{
            setGame(game);
        }
    };

    const getGameComponent = (game) => {
        if (game === ""){
            return null;
        }
        else if (game === "artemis"){
            return <ArtemisAdmin selectedGame={selectedGame} selectedInstance={selectedInstance} setSelectedInstance={setSelectedInstance}/>;
        }
        else if(game ==="pirate-ship"){
            return <PirateShipAdmin selectedGame={selectedGame} selectedInstance={selectedInstance} setSelectedInstance={setSelectedInstance} />;
        }
        else if(game === "omega-protocol-zombies"){
            return <OmegaZombiesAdmin selectedGame={selectedGame} selectedInstance={selectedInstance} setSelectedInstance={setSelectedInstance} />;
        }
        else if(game === "halloween-2023"){
            return <Halloween2023Admin selectedGame={selectedGame} selectedInstance={selectedInstance} setSelectedInstance={setSelectedInstance} />;
        }
        else if(game === "holiday-house"){
            return <HolidayHouseAdmin selectedGame={selectedGame} selectedInstance={selectedInstance} setSelectedInstance={setSelectedInstance} />;
        }
    };

    return(
        <React.Fragment>
            <FullPageDiv>
                <GameSelectionSideBarDiv>
                    <Wrapper onClick={() => {select("omega-protocol-zombies", selectedGame, setSelectedGame)}}>
                        <AdminGameButton selected={"omega-protocol-zombies" === selectedGame} icon="biohazard" />
                    </Wrapper>
                    <Wrapper onClick={() => {select("artemis", selectedGame, setSelectedGame)}}>
                        <AdminGameButton selected={"artemis" === selectedGame} icon="space-shuttle" />
                    </Wrapper>
                    <Wrapper onClick={() => {select("pirate-ship", selectedGame, setSelectedGame)}}>
                        <AdminGameButton selected={"pirate-ship" === selectedGame} icon="skull-crossbones" />
                    </Wrapper>
                    <Wrapper onClick={() => {select("halloween-2023", selectedGame, setSelectedGame)}}>
                        <AdminGameButton selected={"halloween-2023" === selectedGame} icon="ghost" />
                    </Wrapper>
                    <Wrapper onClick={() => {select("holiday-house", selectedGame, setSelectedGame)}}>
                        <AdminGameButton selected={"holiday-house" === selectedGame} icon="tree" />
                    </Wrapper>
                </GameSelectionSideBarDiv>
                {getGameComponent(selectedGame)}
            </FullPageDiv>
            <Background background={background}></Background>
            <BackgroundCover></BackgroundCover>
        </React.Fragment>
    )
}

export default Admin;

const BackgroundCover = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0px;
    left: 0px;
    z-index: -1;
`;

const Background = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-image: url(${props => props.background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: white;
    top: 0px;
    left: 0px;
    z-index: -2;
`;

const FullPageDiv = styled.div`
    height: 100vh;
`;

const InstanceDiv = styled.div`
    background-color: green;
    width: auto;
    height: 100vh;
    margin-left: 240px;
`;

const Wrapper = styled.div`
`;

const GameSelectionSideBarDiv = styled.div`
    height: 100vh; 
    width: 80px; 
    position: fixed;
    z-index: 1; 
    top: 0; 
    left: 0;
    background-color: #111;
    overflow-x: hidden; 
    padding-top: 20px;
`;

const GameSideBarDiv = styled.div`
    height: 100vh; 
    width: 160px; 
    position: fixed;
    z-index: 1; 
    top: 0; 
    left: 80px;
    background-color: red;
    overflow-x: hidden; 
    padding-top: 20px;
`;
