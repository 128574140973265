import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from 'reactstrap';

export const ArtemisScoreScreenItem = (props) => {

    return(
        <StyledContainer side={props.side}>
            <StyledRow>
                <StyledCol xs="4">
                    <StyledImage src={"https://cdn.moniker-virtual.com/artemis/images/items/" + props.item.name + ".png"} />
                </StyledCol>
                <StyledCol>
                    <Container>
                        <Row>
                            <StyledCol>
                                <StyledH2>{props.item.name}</StyledH2>
                            </StyledCol>
                        </Row>
                        <Row>
                            <StyledCol>
                                <StyledP>{props.item.description}</StyledP>
                            </StyledCol>
                        </Row>
                    </Container>
                </StyledCol>
            </StyledRow>
        </StyledContainer>
    );
}

export default ArtemisScoreScreenItem;

const StyledContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: ${props => props.side};
`;

const StyledRow = styled(Row)`
    margin-bottom: 20px;
    border-radius: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: white;
    width: 75%;
    background-color: rgba(0,0,0,0.5);
`;

const StyledH2 = styled.h2`
    color: white;
`;

const StyledP = styled.p`
    color: white;
    margin: 0px;
`;

const StyledImage = styled.img`
    width: 200px;
    height: 200px;
    border-radius: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: white;
    margin: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
`;

const StyledCol = styled(Col)`
    display: flex;    
    margin: 0px;
    align-items: center;
`;
