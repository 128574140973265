import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
//import Unity, { UnityContext } from "react-unity-webgl";
import styled, { createGlobalStyle } from 'styled-components'
import LoadingWheel from '../../assets/images/LoadingWheel.svg';
import { isMobile } from 'react-device-detect';
import { halloween2023DB } from "../../utils/database/firebase-halloween-2023";
import { deleptualAnalyticsDB } from "../../utils/database/firebase-deleptual-analytics";
import { doc, getDoc, runTransaction, onSnapshot } from "@firebase/firestore";
import { getUTCTimeV2 as getUTCTime, startGameV3 as startGame, listenToDocumentV3 as listenToDocument } from '../../utils/firestore-functions';
import { DateTime, Duration } from "luxon";
import { useCookies } from 'react-cookie';

const Halloween2023 = (props) => {
    const [cookies, setCookie] = useCookies();
    const [progression, setProgression] = useState(0);
    const [loading, setLoading] = useState(true);
    const [players, setPlayers] = useState({});
    /*
    const [halloweenEscapeUnityContext]= useState(new UnityContext({
      loaderUrl: "https://cdn.moniker-virtual.com/halloween-2023/webgl.loader.js",
      dataUrl: "https://cdn.moniker-virtual.com/halloween-2023/webgl.data",
      frameworkUrl: "https://cdn.moniker-virtual.com/halloween-2023/webgl.framework.js",
      codeUrl: "https://cdn.moniker-virtual.com/halloween-2023/webgl.wasm",
      streamingAssetsUrl: "https://cdn.moniker-virtual.com/halloween-2023/StreamingAssets",
}))
*/
      
let { collection } = useParams();
let { document } = useParams();

/*
    const calculateScore = (data, startTime) => {
      var puzzles = ["bedroomdoor", "kitchenDoor", "bookshelf", "lightPuzzle", "frontDoor"];
      var score = 0;
      const list = data["syncedObjects"];
      puzzles.forEach(puzzle => {
          let x = (CheckForSyncObject(list, puzzle, "Complete"));
          if (x !== null){
              console.log("Puzzle: " + puzzle + " Time: " + x);
              let dtstart = new DateTime.fromMillis(parseInt(startTime));
              let dtend = new DateTime.fromSeconds(parseInt(x));
              let dur = Duration.fromMillis(dtend - dtstart);
              score += 60 - Math.round(dur.as("minutes"))
          };
      });

      //Spiders
      let spiders = 0;
      for(let i = 1; i <= 9; i++){
          let collected = CheckForSyncObject(list, "spider" + i.toString(), "complete");
          if (collected !== null){
              spiders++;
          }
      }
      score += (spiders * 4);

      //Candies
      let candies = 0;
      for(let i = 1; i <= 17; i++){
          let collected = CheckForSyncObject(list, "candy" + i.toString(), "complete");
          if (collected !== null){
              candies++;
          }
      }
      score += (candies * 2);

      if (data["finishingTime"] !== "Did Not Finish"){
          let dtstart = new DateTime.fromMillis(parseInt(startTime));
          let dtend = new DateTime.fromMillis(parseInt(data["finishingTime"]));
          let dur = Duration.fromMillis(dtend - dtstart);
          score += (60 - Math.round(dur.as("minutes")) + 5) * 3;
      }

      return score;
  };


  const CheckForSyncObject = (list, id, state) => {
    if (list.find(i => i.id === id).state.toLowerCase() === state.toLowerCase()) { 
        console.log(list.find(i => i.id === id));
        return list.find(i => i.id === id).timestamp.seconds; 
    }
    else { 
        return null;
    }
}

useEffect(
  () => {
    async function AddUserInformation() {
      const docRef = doc(deleptualAnalyticsDB, "escape-halloween/" + collection);
      try {
        await runTransaction(deleptualAnalyticsDB, async (transaction) => {
          let docData = (await transaction.get(docRef)).data();
          let data = {};
          data["time"] = DateTime.now().toUTC().toString();
          data["userAgent"] = window.navigator.userAgent;
          console.log(docData);
          if (docData === undefined){
            var userData = [];
            userData.push(data);
            transaction.set(docRef, {userData} );
          }
          else{
            docData["userData"].push(data);
            transaction.update(docRef, docData);
          }
        });
      } catch (e) {
        console.log("Failed to Add User Information", e);
      }
    }
    AddUserInformation();
  }, []
);

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => (props.backgroundColor.toString())};
  }
`;

    useEffect(
      () => {
        halloweenEscapeUnityContext.on("ReactGetURLInformation", () => {
          halloweenEscapeUnityContext.send(
            "ReactCommunicator", 
            "ReactListener", 
            ("SetURLParameters" + "*" + collection + "*" + "gameSettings")
          );
        })

        halloweenEscapeUnityContext.on("ReactUpdateSyncable", async (information) => {
          let strings = information.split("*");
          var id = strings[0];
          var state = strings[1];
          var type = strings[2];
          const docRef = doc(halloween2023DB, collection + "/" + document);
          try {
            await runTransaction(halloween2023DB, async (transaction) => {
              let docData = (await transaction.get(docRef)).data();
              let index = docData.syncedObjects.findIndex(i => i.id === id)
              docData.syncedObjects[index].state = state;
              if (docData.syncedObjects[index].timestamp === "" || docData.syncedObjects[index].timestamp === undefined){
                docData.syncedObjects[index].timestamp = new Date();
              }
              transaction.update(docRef, docData);
            });
          } catch (e) {
            console.log("Transaction Failed:", e);
          }
        });

        halloweenEscapeUnityContext.on("ReactRecordPlay", async () => {
          const docRef = doc(deleptualAnalyticsDB, "escape-halloween");
          try {
            await runTransaction(deleptualAnalyticsDB, async (transaction) => {
              let docData = (await transaction.get(docRef)).data();
              let d = { 
                timestamp: new Date(),
                company: "Moniker"
              }
              docData.plays.push(d);
              transaction.update(docRef, docData);
            });
          } catch (e) {
            console.log("Transaction Failed:", e);
          }
        });

        halloweenEscapeUnityContext.on("ReactActivateGame", async () => {
          const docRef = doc(halloween2023DB, collection + "/gameSettings");
          try {
            await runTransaction(halloween2023DB, async (transaction) => {
              let docData = (await transaction.get(docRef)).data();
              if (docData["startTime"] === ""){
                getUTCTime(halloween2023DB, collection, startGame);
                if (docData["name"] !== ""){
                  const docRefAnalytics = doc(deleptualAnalyticsDB, "escape-halloween/" + collection);
                    try {
                      await runTransaction(deleptualAnalyticsDB, async (transaction) => {
                        let docDataAnalytics = (await transaction.get(docRefAnalytics)).data();
                        let gameName = {};
                        gameName["name"] = docData["name"];
                        if (docDataAnalytics === undefined){
                          transaction.set(docRefAnalytics, gameName );
                        }
                        else{
                          transaction.update(docRefAnalytics, gameName);
                        }
                      });
                    } catch (e) {
                      console.log("Failed to Add User Information", e);
                  }
                }
              }
            });
          } catch (e) {
            console.log("Failed to record start time.", e);
          }

        });

        halloweenEscapeUnityContext.on("ReactFinishGame", async (information) => {
          console.log("ReactFinishGame being called");
          var strings = information.split("*");
          var finishedOnTime = strings[0];
          var timeStamp = strings[1];
          let startTime = "";
          const docRef = doc(halloween2023DB, collection + "/" + document);
          const gameRef = doc(halloween2023DB, collection + "/gameSettings");
          try {
            await runTransaction(halloween2023DB, async (transaction) => {
              let docData = (await transaction.get(docRef)).data();
              let gameData = (await transaction.get(gameRef)).data();
              
              if(docData["finishedGame"]){
                console.log("Game has already finished, returning");
                return;}

              if(finishedOnTime === "False"){
                console.log("Did not finish on time.");
                docData["finishingTime"] = "Did Not Finish";
              }
              else{
                console.log("Did Finish on time.");
                let t = "";
                await fetch("https://us-central1-escape-room-1980b.cloudfunctions.net/getUTCTime", {
                  "method": "GET",
                  "headers": {
                  }
                })
                .then(response => response.json())
                .then(response => {
                    t = response.result.toString();
                    console.log("Time is: " + t);
                })
                .catch(err => { console.log(err); 
                });
                //docData["finishingTime"] = timeStamp.toString();
                docData["finishingTime"] = t;
                console.log(t);
              }

              if(docData["score"] === ""){
                docData["score"] = calculateScore(docData, gameData.startTime);
              }

              docData["finishedGame"] = true;
              
              transaction.update(docRef, docData);
            });
          } catch (e) {
            console.log("Failed to update end time:", e);
          }
        });

        halloweenEscapeUnityContext.on("ReactGetServerUTC", () => {
        fetch("https://us-central1-escape-room-1980b.cloudfunctions.net/getUTCTime", {
              "method": "GET",
              "headers": {
              }
            })
            .then(response => response.json())
            .then(response => {
              halloweenEscapeUnityContext.send(
                "Halloween Specific", 
                "ReturnServerUTC", 
                response.result.toString()
              );
            })
            .catch(err => { console.log(err); 
            });
      });

      halloweenEscapeUnityContext.on("ReactGetEnvironment", () => {
        var currentEnvironment = getEnvironment();
        halloweenEscapeUnityContext.send(
          "ReactCommunicator", 
          "ReactListener", 
          ("SetEnvironment" + "*" + currentEnvironment)
        );
      })

      const getEnvironment = () => {
        return "production";
      };

      halloweenEscapeUnityContext.on("ReactScoreScreen", () => {
        window.open("https://moniker-virtual.com/halloween-2023/" + collection + "/scores");
      });

      halloweenEscapeUnityContext.on("ReactSendString", async (information) => {
        var docRef = doc(halloween2023DB, collection + "/" + document);
        var strings = information.split("*");
        var puzzleDetails = strings[0].split("'");
        if(puzzleDetails[0] === "ouija"){
          var letter = puzzleDetails[1];
          var userId = puzzleDetails[2];
          var color =  puzzleDetails[3];
         

          try {
            await runTransaction(halloween2023DB, async (transaction) => {
              let docData = (await transaction.get(docRef)).data();
              docData["ouijaChar"] = letter;
              docData["recentId"] = userId;
              docData["color"] = color;

              transaction.update(docRef, docData);
            });
          } catch (e) {
            console.log("Send string has failed:", e);
          }
        }

        if(puzzleDetails[0] === "clue"){
          try {
            await runTransaction(halloween2023DB, async (transaction) => {
              let docData = (await transaction.get(docRef)).data();
              docData["clues"][puzzleDetails[1]]++;



              transaction.update(docRef, docData);
            });
          } catch (e) {
            console.log("Send string has failed:", e);
          }
        }
      })

      halloweenEscapeUnityContext.on("ReactListenToTeam", () => {
        console.log("REACT FUNCTION: ReactListenToTeam");
        listenToDocument(halloween2023DB, collection, document, SendTeamData, SendError);
      });

      const SendTeamData = (data) => {
        halloweenEscapeUnityContext.send(
          "ReactCommunicator", 
          "ReactListener", 
          ("ReturnTeamData*" + JSON.stringify(data))
        );
      }

      halloweenEscapeUnityContext.on("ReactListenToGame", () => {
        console.log("REACT FUNCTION: ReactListenToGame");
        listenToDocument(halloween2023DB, collection, "gameSettings", SendGameData, SendError);
      });

      const SendGameData =(data) => {
        halloweenEscapeUnityContext.send(
          "ReactCommunicator",  
          "ReactListener", 
          ("ReturnGameData*" + JSON.stringify(data))
        );
      }

      const SendError = (error) => {
        halloweenEscapeUnityContext.send(
          "ReactCommunicator",
          "ThrowError",
          "Could not find game data"
        );
      }

      halloweenEscapeUnityContext.on("progress", progress => {
          if (progress >= 1){
            setLoading(false)
          }
            setProgression(progress);
        });
      },[halloweenEscapeUnityContext],
    );
*/
   return ( 
   <FullHeightDiv>
    {/*
    <GlobalStyle backgroundColor={"black"} />
    {isMobile && 
    <CenterDiv>
      <WhiteP>Please use a desktop browser to play</WhiteP>
    </CenterDiv>}
    {!isMobile && loading && <LoadingDiv style={{backgroundImage: "url(https://monikerspace.nyc3.cdn.digitaloceanspaces.com/halloween-2023/images/background.png)"}}>
      <LoadingP>Loading {(progression * 100).toFixed(0)}%</LoadingP>
      <LoadingWheelDiv style={{backgroundImage: "url(" + LoadingWheel + ")"}}></LoadingWheelDiv>
    </LoadingDiv>}
    {!isMobile && <Unity unityContext={halloweenEscapeUnityContext} style={{width:"100%", height:"100%", position:"absolute", visibility: loading ? "hidden" : "visible"}}/>}
  */}
  </FullHeightDiv>);
}
export default Halloween2023;

const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`;

const WhiteP = styled.h3`
  color: white;
`;

const FullHeightDiv = styled.div`
  height: 99vh;
`;

const LoadingP = styled.p`
  text-align: center;
  font-size: 40px;
  color: white;
  background-image: radial-gradient(hsla(267,10%,18%,0.99),hsla(267,10%,40%,0));
  vertical-align: middle;
`;

const LoadingWheelDiv = styled.div`
  display: inline-block;
  text-align: center;
  height: 16px;
  width: 128px;
  z-index: 1;
  vertical-align: middle;
`;

const LoadingDiv = styled.div`
  background-size: cover;
  height: 100%;
  display: inline-block;
  width: 100%;
  text-align: center;
  position: absolute;
  justify-content: center;
  vertical-align: middle;
  object-fit: fill;
`;