import { Button } from "reactstrap";
import React, { useState } from "react";
import styled from "styled-components";
import { createGame } from "../../utils/firestore-functions";

const FinalVerdictAdminPanel = () => {
    const [input, setInput] = useState('');
    const [message, setMessage] = useState("");

    return(
        <React.Fragment>
            <MessageP>{message}</MessageP>
            <input value={input} onChange={e => setInput(e.target.value)}/>
            <MarginButton color="danger" onClick={() => createGame(input, setMessage)}>Create New Game</MarginButton>
        </React.Fragment>
    )
}

const MessageP = styled.p`
    color: red;
    font-size: 20px;
`;

const MarginButton = styled(Button)`
    margin: 5px;
    margin-left: 15px;
`;

export default FinalVerdictAdminPanel