import { Button, Container, Row, Col, InputGroup, InputGroupAddon, Input } from "reactstrap";
import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deletePlayer, renamePlayer, changePlayerPasscode } from "../../utils/firestore-functions";

export const FinalVerdictFacilitatorPlayer = (props) => {
    const [input, setInput] = useState("");
    const [renameMessage, setRenameMessage] = useState("");

    return(
        <PlayerContainer>
            <Row>
                <Col xs="1">
                    <p>Name:</p>
                </Col>
                <Col xs="1">
                    <p>{props.playerName}</p>
                </Col>
                <Col xs="3">
                    <InputGroup style={{"width":"100%"}}>
                        <Input value={input} placeholder={"new player name"} onChange={e => setInput(e.target.value)}>
                        </Input>
                        <InputGroupAddon addonType="append">
                            <Button color="primary" onClick={() => renamePlayer(props.gameName, props.teamName, props.playerName, props.passcode, input, setRenameMessage)}>
                                <FAIcon icon={["fas", "edit"]}></FAIcon>
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
                <Col xs="3">
                    <ErrorP>{renameMessage}</ErrorP>
                </Col>
            </Row>
            <Row>
                <Col xs="1">
                    <p>Passcode:</p>
                </Col>
                <Col xs="1">
                    <p>{props.passcode}</p>
                </Col>
                <Col xs="3">
                    <Button style={{"width":"100%"}} color="primary" onClick={() => changePlayerPasscode(props.gameName, props.teamName, props.playerName)}>
                        <FAIconButton icon={["fas", "redo"]}></FAIconButton>
                        Change Passcode
                    </Button>
                </Col>
            </Row>
            <Row>
            <Col xs="1">
                    <p>Vote:</p>
                </Col>
                <Col xs="1">
                    <p>{props.vote}</p>
                </Col>
                <Col xs="3">
                    <Button style={{"width":"100%"}} color="danger" onClick={() => deletePlayer("FinalVerdict" + props.gameName, props.teamName, props.playerName)}>
                        <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                        Delete Player
                    </Button>
                </Col>
            </Row>
        </PlayerContainer>
    )
}
const ErrorP = styled.p`
    color: red;
`;

const CenterCol = styled(Col)`
    justify-content: center;
`;

const FAIcon = styled(FontAwesomeIcon)`
    
`;

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
`;

const PlayerContainer = styled(Container)`
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    margin:10px;
    padding:5px;
    box-shadow: 5px 5px #888888;
`;

export default FinalVerdictFacilitatorPlayer