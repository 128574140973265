export const convertIntToScheduleTime = (intToConvert) => {
    switch (intToConvert) {
        case 0:
            return "Not selected.";
        case 1:
            return "1:05:00 Left";
        case 2:
            return "1:00:00 Left";
        case 3:
            return "55:00 Left";
        case 4:
            return "50:00 Left";
        case 5:
            return "45:00 Left";
        case 6:
            return "40:00 Left";
        case 7:
            return "35:00 Left";
        case 8:
            return "30:00 Left";
        case 9:
            return "25:00 Left";
        case 10:
            return "20:00 Left";
        case 11:
            return "15:00 Left";
        case 12:
            return "10:00 Left";
        default:
            return intToConvert + " was not found?";
    }
}

//As passcode is generated dynamically, we don't include it here.
export const newTeam = {
    prosecutorVideos: [false, false, false, false, false],
    defendantVdieos: [false, false, false, false, false],
    foundFingerprint: false,
    openedComputer: false,
    openedFolder: false,
    notes: [],
    googleSheetLink: "",
    playerInformation: {
        1: {
            passcode: "1234",
            vote: ""
        }
    }
}

export const newPlayer = {

}

export const newGameSettings = {
    hasGameStarted: false,
    startTime: "",
    teamNames: ["1"],
    defendantSchedule: {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: "",
        10: "",
        11: "",
        12: "",
    },
    prosecutorSchedule: {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: "",
        10: "",
        11: "",
        12: "",
    }
}