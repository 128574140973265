import { Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { halloween2023DB, generateGUID } from "../../../../utils/database/firebase-halloween-2023";
import { newGameSettings } from "../../../halloween/halloween-2023-config";
import { listenToDocumentPropertyV2 as listenToDocumentProperty } from "../../../../utils/firestore-functions";
import { runTransaction, doc, Timestamp } from '@firebase/firestore';
import AdminGameInstanceButton from '../../admin-game-instance-button';
import { Halloween2023InstanceAdmin } from './halloween-2023-instance-admin';

export const Halloween2023Admin = (props) => {
    const [gameNames, setGameNames] = useState({});
    const [modal, setModal] = useState(false);
    const [instanceText, setInstanceText] = useState("");

    const newGame = async (name) => {
        var x = generateGUID();
        const ref = doc(halloween2023DB, x, "gameSettings");
        const adminRef = doc(halloween2023DB, "adminSettings", "gameNames");
        try {
            await runTransaction(halloween2023DB, async (transaction) => {
                transaction.set(ref, {
                    ...newGameSettings,
                    name: name,
                    created: Timestamp.fromDate(new Date()),
                    updated: Timestamp.fromDate(new Date()),
                });
                transaction.update(adminRef, {
                    ["gameNames" + "." + x]: name
                });
            });
            console.log("Success.");
        } catch (e) {
            console.log("Transaction Failed: ", e);
        }
    }


    
    const select = (instance) => {
        if (instance[0] === props.selectedInstance[0]){
            props.setSelectedInstance({});
        }
        else{
            props.setSelectedInstance(instance);
        }
    }

    const toggle = () => {
        setModal(!modal);
    }
    const modalInput = useRef(null);

    useEffect(() => {modalInput.current && modalInput.current.focus()});

    const modalOpened = () => {
        setTimeout(()=>{
            modalInput.current.focus();
        }, 1);
    }

    function handleKeyDown(event){
        if (event.keyCode === 13){
            newGame(instanceText); 
            toggle(); 
            setInstanceText("");
        }
    }

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(halloween2023DB, "adminSettings" ,"gameNames", "gameNames", setGameNames);
        return unsubscribe;
    }, []);

    const cleanTitle = (title) => {
        if (title === undefined || title === "")
        {
            return "Select A Game From The Left Panel";
        }
        var words = title.split("-");
        for (let i = 0; i < words.length; i++)
        {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        } 
        words = words.join(' ');
        return words;
    };

    const getInstanceComponent = (game, instance) => {
        if (game === "" || Object.keys(instance).length === 0){
            return null;
        }
        else if (game === "halloween-2023"){
            return <Halloween2023InstanceAdmin gameName="Halloween 2023" instance={instance} setSelectedInstance={props.setSelectedInstance} />;
        }
    };

    return(
        <React.Fragment>
            <GameSideBarDiv>
                <ButtonWrapper>
                    <NewInstanceButton onClick={() => {toggle()}} color="primary">New Instance</NewInstanceButton>
                </ButtonWrapper>
                <LineDivider></LineDivider>
                {gameNames !== undefined && Object.entries(gameNames).map((gameObj) => 
                    <Wrapper key={gameObj[0]} onClick={() => {select(gameObj)}}>
                        <AdminGameInstanceButton app={"Halloween 2023"} db={halloween2023DB} instanceObject={gameObj} selected={props.selectedInstance[0]===gameObj[0]}></AdminGameInstanceButton>
                    </Wrapper>
                )}
                <LineDivider></LineDivider>
            </GameSideBarDiv>
            <InstanceDiv>
                <CenterDiv>
                    <h1>{cleanTitle(props.selectedGame)}</h1>
                </CenterDiv>
                {getInstanceComponent(props.selectedGame, props.selectedInstance)}
            </InstanceDiv>
            <ContainerDiv>
                <Modal autoFocus={false} isOpen={modal} toggle={toggle} onOpened={modalOpened}>
                    <ModalHeader autoFocus={false} toggle={toggle}>Create new instance for Halloween 2023</ModalHeader>
                    <ModalBody autoFocus={false}>
                        Instance Name
                        <ModalInput onKeyDown={handleKeyDown} value={instanceText} placeholder={"game name"} onChange={e => setInstanceText(e.target.value)} autoFocus={true} ref={(el) => { modalInput.current = el}}></ModalInput>
                    </ModalBody>
                    <ModalFooter autoFocus={false}>
                        <Button color="warning" onClick={toggle}>Cancel</Button>
                        <Button color="primary" onClick={() => {
                            newGame(instanceText); 
                            toggle(); 
                            setInstanceText("");
                        }}>Create Instance</Button>{' '}
                    </ModalFooter>
                </Modal>
            </ContainerDiv>
        </React.Fragment>
    )
}

export default Halloween2023Admin;

const GameSideBarDiv = styled.div`
    height: 100vh; 
    width: 160px; 
    position: fixed;
    z-index: 1; 
    top: 0; 
    left: 80px;
    background-color: red;
    overflow-x: hidden; 
    padding-top: 20px;
`;

const CenterDiv = styled.div`
    text-align: center;
    color: white;
`;

const InstanceDiv = styled.div`
    width: auto;
    height: 100vh;
    margin-left: 240px;
`;

const Wrapper = styled.div`
`;

const ModalInput = styled(Input)`
    padding: 20px;
    margin: 25px auto;
    width: 80%;
`;

const LineDivider = styled.hr`
    background-color: black;
    margin-left: 15px;
    margin-right: 15px;
`;

const NewInstanceButton = styled(Button)`
    padding: 5px;
    width: 70%;
    margin: auto;
`;

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
`;

const ContainerDiv = styled.div`
    align-items: center;
    text-align: center;
`;
