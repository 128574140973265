import { Container, Col, Row } from 'reactstrap';
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { omegaZombiesDB } from '../../../../utils/database/firebase-omega-protocol-zombies';
import { query, collection as ericCollection, onSnapshot } from '@firebase/firestore';

export const OmegaProtocolZombiesScores = (props) => {
    
    const [gameDetails, setGameDetails] = useState([]);
    const [ctr, setCtr] = useState(0);

    let { collection } = useParams();

    useEffect(
        () => {
            async function boop() {
                let q = query(ericCollection(omegaZombiesDB, collection));
                return onSnapshot(q, (snapshot) => {
                    let gameDeets = [];
                    snapshot.forEach((doc) => {
                        gameDeets.push({id: doc.id, data: doc.data()})
                    });
                    setGameDetails(gameDeets);
                })
            }
            return boop();
        }, []);

    useEffect(
        () => {
            function listener(e) {
                if (e.code === 'Space'){
                    setCtr(ctr + 1);
                }
            }
            window.addEventListener('keydown', listener);
            return function cleanup() {
                window.removeEventListener('keydown', listener)
            };
        }, [ctr]
    );

      const removeGameSettings = (details) => {
          let temp = [];
          details.map((data) => {
              if (data.id !== "gameSettings"){
                  if (!data.data.finishedGame){
                      data.data.finishingTime = "5";
                  }
                  temp.push(data);
              }
          });
          return temp;
      };

      const getGameSettings = (details) => {
        if (details === undefined) return;
        if (details.length < 1) return;
        let x = ""
        details.map((data) => {
            if (data.id === "gameSettings"){
                x = data.data.name;
            }
        });
        return x;
      };

      const getGameData = (details) => {
        if (details === undefined) return;
        if (details.length < 1) return;
        let x = ""
        details.map((data) => {
            if (data.id === "gameSettings"){
                x = data.data;
            }
        });
        return x;
      }

      const formatTime = (time) => {
        let strings = time.split(":");
        return (strings[0] + ":" + strings[1]);
      }

      let num = 0;

    return(
        <React.Fragment>
            <br />
            <Container>
                <Row>
                    <FlexCol>
                        {(gameDetails !== undefined && getGameData(gameDetails) !== undefined && getGameData(gameDetails).imageUrl) && <CompanyImage src={getGameData(gameDetails).imageUrl}></CompanyImage>}
                        <TitleContainer>
                            <StyledH2>{getGameSettings(gameDetails)}</StyledH2>
                        </TitleContainer>
                    </FlexCol>
                    <FlexCol>
                        <TitleImage src={"https://monikerspace.nyc3.cdn.digitaloceanspaces.com/omega-protocol-zombies/images/title.png"} />
                    </FlexCol>
                </Row>
                <br />
                <Row>
                    <Col>
                        {ctr === 0 && <WhiteCenterP>Press Space to Begin</WhiteCenterP>}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Container fluid={true}>
                            {gameDetails !== undefined && gameDetails.length > 0 && getGameData(gameDetails).hasFacilitators && removeGameSettings(gameDetails)
                                .sort((a,b) => (a.data.endTime > b.data.endTime) ? -1 : (a.data.endTime < b.data.endTime) ? 1 : 0)
                                .map((data) => {
                                    num++;
                                    return (
                                        (ctr >= num) && <FlexRow key={data.data.name}>
                                            <StyledCol>{(gameDetails.length) - num}</StyledCol>
                                            <StyledCol><b><u>{data.data.name}</u></b></StyledCol>
                                            {data.data.endTime !== "" &&  <StyledCol>{(data.data.endTime === "Did not finish" ? "Did not finish" : "Time: " + formatTime(data.data.endTime))}</StyledCol>}
                                            {data.data.endTime === "" && <StyledCol>Playing</StyledCol>}
                                            {(!getGameData(gameDetails).hasFacilitators && data.data.endTime !== "") && <StyledCol>Score: {data.data.score ? data.data.score : "0"}%</StyledCol>}
                                        </FlexRow>)
                            })}

                            {gameDetails !== undefined && gameDetails.length > 0 && !getGameData(gameDetails).hasFacilitators && removeGameSettings(gameDetails)
                                .sort((a,b) => (a.data.score < b.data.score) ? -1 : (a.data.score > b.data.score) ? 1 : 0)
                                .map((data) => {
                                    num++;
                                    return (
                                        (ctr >= num) && <FlexRow key={data.data.name}>
                                            <StyledCol>{(gameDetails.length) - num}</StyledCol>
                                            <StyledCol><b><u>{data.data.name}</u></b></StyledCol>
                                            {data.data.endTime !== "" &&  <StyledCol>{(data.data.endTime === "Did not finish" ? "Did not finish" : "Time: " + formatTime(data.data.endTime))}</StyledCol>}
                                            {data.data.endTime === "" && <StyledCol>Playing</StyledCol>}
                                            {(!getGameData(gameDetails).hasFacilitators && data.data.endTime !== "") && <StyledCol>Score: {data.data.score ? data.data.score : "0"}%</StyledCol>}
                                        </FlexRow>)
                            })}
                        </Container>
                    </Col>
                </Row>
            </Container>
            <Background></Background>
            <BackgroundCover></BackgroundCover>
        </React.Fragment>
    )
}

export default OmegaProtocolZombiesScores;

const SlidingAnimation = keyframes`
    from {
        transform: translate(-1000px, 0px);
    }
    to {
        transform: translate(0px, 0px);
    }
`;

const FlashAnimation = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const WhiteCenterP = styled.p`
    color: white;
    margin: 0px;
    font-weight: bold;
    text-align: center;
    animation: ${FlashAnimation} 2s linear infinite alternate;
`;

const FlexRow = styled(Row)`
    padding: 10px;
    display: flex;
    animation: ${SlidingAnimation} 0.5s linear;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    background-color: rgba(0,0,0,0.8);
    justify-content: center;
    margin-bottom: 20px;
`;

const TitleContainer = styled.div`
    border-color: white;
    border-style: solid;
    border-radius: 10px;
    border-width: 1px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
`;

const StyledH2 = styled.h2`
    color: white;
`;

const FlexCol = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CompanyImage = styled.img`
    height: 200px;
    width: 200px;
    border-radius: 25px;
    margin-right: 50px;
`;

const TitleImage = styled.img`
    height: auto;
    width: 50%;
    margin-right: 50px;
    object-fit: cover;
`;

const BackgroundCover = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0px;
    left: 0px;
    z-index: -1;
`;

const Background = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-image: url("https://monikerspace.nyc3.cdn.digitaloceanspaces.com/omega-protocol-zombies/images/background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: white;
    top: 0px;
    left: 0px;
    z-index: -2;
    color: white;
`;
const StyledCol = styled(Col)`
    color: white;
    font-weight: bold;
    font-size: xx-large;
    margin: 0px;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
`;
