import React, { useState, useEffect } from "react";
import FinalVerdictFacilitatorGame from "./final-verdict-facilitator-game";
import { getGameNames } from "../../utils/firestore-functions";

const FinalVerdictAdminGames = () => {
    const [gameNames, setGameNames] = useState([]);

    useEffect(() => {
        var unsubscribe = getGameNames(setGameNames);
        return unsubscribe;
    }, []);

    return(
        <React.Fragment>
            {gameNames && gameNames.map(gameName => (
                <FinalVerdictFacilitatorGame key={gameName} gameName={gameName}></FinalVerdictFacilitatorGame>
            ))}
        </React.Fragment>
    )
}

export default FinalVerdictAdminGames