import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import { v4 as uuidv4 } from 'uuid';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCCpg6nzuC3TUPc1Z980cLx8r6Rlx_IXgA",
    authDomain: "lost-at-sea-production.firebaseapp.com",
    projectId: "lost-at-sea-production",
    storageBucket: "lost-at-sea-production.appspot.com",
    messagingSenderId: "687130770569",
    appId: "1:687130770569:web:cce08415d67501e01eda58",
    measurementId: "G-K7D21E7J0D"
}, "lost-at-sea");

const lostAtSeaDB = firebaseApp.firestore();

export const generateGUID = uuidv4;
export const analytics = firebase.analytics();
export const auth = firebase.auth();
export { lostAtSeaDB };