import { Button, Input, Container, Col, Row, Collapse } from 'reactstrap';
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { listenToDocument } from "../../../../utils/firestore-functions";
import { artemisDB } from "../../../../utils/database/firebase-artemis";
import { resetTeamSettings } from "../../../../utils/artemis-specific";
import firebase from 'firebase/compat/app';

export const ArtemisInstanceTeamAdmin = (props) => {
    
    const [teamDetails, setTeamDetails] = useState({})
    const [playerNameInput, setPlayerNameInput] = useState("");
    const [isCollapsed, setIsCollapsed] = useState(false);

    useEffect(() => {
        var unsubscribe = listenToDocument(artemisDB, props.instance[0], props.teamName[0], setTeamDetails);
        return unsubscribe;
    }, [props.teamName]);

    const getEnvironment = () => {
        var loc = window.location.href;
        if (loc.includes("localhost")){
          return "test.deleptual.ca";
        }
        else if (loc.includes("test.deleptual")){
          return "test.deleptual.ca";
        }
        else if (loc.includes("deleptual")){
          return "deleptual.ca";
        }
        else if (loc.includes("moniker-virtual")){
          return "moniker-virtual.com";
        }
    };

    const copyURL = (text) => {
        var textArea = document.createElement("textarea");
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = 0;
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          console.log('Copying text command was ' + msg);
        } catch (err) {
          console.log('Oops, unable to copy');
        }
        document.body.removeChild(textArea);
    }

    const resetTeam = (gameName, teamName) => {
        return artemisDB.runTransaction((transaction) => {
            transaction.update((artemisDB.collection(gameName).doc(teamName)), {
                ...resetTeamSettings
            });
            return Promise.resolve();
        }).then(() => {
    
        }).catch((error) => {
            console.log("Transaction Failed: ", error);
        })
    }

    const deleteTeam = (gameName, teamName) => {
        return artemisDB.runTransaction((transaction) => {
            return transaction.get(artemisDB.collection(gameName).doc("gameSettings")).then(snapshot => {
                var obj = snapshot.data();
                console.log("TEST: " + obj.teamNames[teamName]);
                delete obj.teamNames[teamName];
                console.log(obj);
                transaction.delete(artemisDB.collection(gameName).doc(teamName));
                transaction.set(artemisDB.collection(gameName).doc("gameSettings"), {...obj});
            })
        }).then(() => {
    
        }).catch((error) => {
            console.log("Transaction Failed: ", error);
        })
    }

    const addPlayer = (gameName, teamName, playerName) => {
        if (playerName === ""){
            return;
        }
        return artemisDB.runTransaction((transaction) => {
            transaction.update((artemisDB.collection(gameName).doc(teamName)), {
                "playerNames": firebase.firestore.FieldValue.arrayUnion(playerName)
            });
            setPlayerNameInput("");
            return Promise.resolve();
        }).then(() => {
    
        }).catch((error) => {
            console.log("Transaction Failed: ", error);
        })
    };

    const removePlayer = (gameName, teamName, playerName) => {
        return artemisDB.runTransaction((transaction) => {
            transaction.update((artemisDB.collection(gameName).doc(teamName)), {
                "playerNames": firebase.firestore.FieldValue.arrayRemove(playerName)
            });
            return Promise.resolve();
        }).then(() => {
    
        }).catch((error) => {
            console.log("Transaction Failed: ", error);
        })
    }

    function handleKeyDown(event){
        if (event.keyCode === 13){
            addPlayer(props.instance[0], props.teamName[0], playerNameInput);
        }
    }

    return(
        <React.Fragment>
            <GameContainer>
                <Row style={{paddingRight:"10px", paddingLeft:"10px"}}>
                    <Col xs="3" style={{alignItems:"center"}}>
                        <h3 onClick={() => {setIsCollapsed(!isCollapsed)}}>{props.teamName[1]}
                            {isCollapsed && <FAIconButton icon={["fas", "chevron-down"]}></FAIconButton>}
                            {!isCollapsed && <FAIconButton icon={["fas", "chevron-up"]}></FAIconButton>}
                        </h3>
                    </Col>
                    <Col style={{alignItems:"center", display:"flex", justifyContent:"flex-end"}}>
                        {/*{props.instance[0]} / {props.teamName[0]}*/}
                        <Button style={{marginLeft:"8px"}} color="primary" onClick={() => {copyURL("https://" + getEnvironment() + "/artemis/" + props.instance[0] + "/" + props.teamName[0]);}}>
                            Copy URL
                            <FAIconButton icon={["fas", "copy"]}></FAIconButton>
                        </Button>
                        <Button style={{marginLeft:"10px"}} color="warning" onClick={() => {resetTeam(props.instance[0], props.teamName[0]);}}>
                            Reset Team
                            <FAIconButton icon={["fas", "redo"]}></FAIconButton>
                        </Button>
                        <Button style={{marginLeft:"10px"}} color="danger" onClick={() => {deleteTeam(props.instance[0], props.teamName[0]);}}>
                            Delete Team
                            <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                        </Button>
                    </Col>
                </Row>
                <Collapse isOpen={!isCollapsed}>
                    <Row style={{paddingRight:"10px", paddingLeft:"10px"}}>
                        <Col>
                            {(teamDetails !== undefined) && (Object.entries(teamDetails).length > 0) && <div>
                                <p>Brought Items: {teamDetails.broughtItems.toString()}</p>
                                <p>Curveballs Finished: {teamDetails.curveballsFinished.toString()}</p>
                                <p>Inventory Items: {teamDetails.inventoryItems.toString()}</p>
                                <p>Priority Items: {teamDetails.priorityItems.toString()}</p>
                            </div>
                            }
                        </Col>
                    </Row>
                    <Row style={{paddingRight:"10px", paddingLeft:"10px"}}>
                        <Col style={{display:"flex"}}>
                            <Button color="primary" onClick={() => {addPlayer(props.instance[0], props.teamName[0], playerNameInput);}}>
                                Add Player
                                <FAIconButton icon={["fas", "user-plus"]}></FAIconButton>
                            </Button>
                            <StyledInput onKeyDown={handleKeyDown} value={playerNameInput} placeholder={"new player name"} onChange={e => setPlayerNameInput(e.target.value)}> </StyledInput>
                        </Col>
                    </Row>
                    <Row style={{paddingRight:"10px", paddingLeft:"10px"}}>
                        <Col>
                            {(teamDetails !== undefined) && (Object.entries(teamDetails).length > 0) &&
                                Object.entries(teamDetails.playerNames).map(playerName => {
                                    return <div key={playerName[0]}>
                                                <DeletePlayerButton color="danger" onClick={() => {removePlayer(props.instance[0], props.teamName[0], playerName[1]);}}>
                                                    <DeletePlayerIcon icon={["fas", "minus-square"]}></DeletePlayerIcon>
                                                </DeletePlayerButton>
                                                {playerName[1]}
                                            </div> 
                                })
                            }
                        </Col>
                    </Row>
                </Collapse>
            </GameContainer>
        </React.Fragment>
    )
}

export default ArtemisInstanceTeamAdmin;

const DeletePlayerButton = styled(Button)`
    padding: 0px;
    margin-right: 5px;
`;

const DeletePlayerIcon = styled(FontAwesomeIcon)`
    margin-left: 5px;
    margin-right: 5px;
`;

const GameContainer = styled(Container)`
    border-color: black;
    border-style: solid;
    border-radius: 10px;
    color: white;
    background-color: rgba(0,0,0,0.7);
    padding: 5px;
    margin-bottom: 10px;
`;

const StyledInput = styled(Input)`
    width: auto;
    margin-left: 8px;
`;

const GameInfoWrapper = styled.div`
    width: 600px;
    border-radius: 10px;
    margin-left: 20px;
`;

const BreadcrumbWrapper = styled.div`
    width: 600px;
    border-radius: 10px;
    margin-left: 20px;
`;

const ContainerWrapper = styled.div`
    width: 600px;
    background-color: yellow;
    border-radius: 10px;
    margin-left: 20px;
`;

const CenteredCol = styled(Col)`
    text-align: center;
`;

const FAIcon = styled(FontAwesomeIcon)`
    
`;

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;
