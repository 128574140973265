import React from "react";
import styled, { createGlobalStyle } from 'styled-components'
import { db } from "../../firebase";
import ArtemisGame from './artemis-game';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => (props.backgroundColor.toString())};
  }
`;

const makeGame = (gameName) => {
  db.collection(gameName).doc("gameSettings").set({
    hasGameStarted: false,
    startTime: "",
    scoresOpen: false,
    passcode: "5555",
    teamScores: {},
  }).then(() => {
        console.log("Document successfully written!");
    })
    .catch(error => {
        console.error("Error writing document: ", error);
    });
  var pass = "";
  for (var i = 0; i < 4; i++){
      pass += (Math.floor(Math.random() * 9) + 1).toString();
  }
  db.collection(gameName)
  .doc("1").set({
      items: {
          Compass: "1",
          DehydratedFood: "2",
          FiftyFootNylonRope: "3",
          FirstAidKit: "4",
          InflatableLifeRaft: "5",
          LunarMap: "6",
          MechanicalRepairKit: "7",
          OxygenTanks: "8",
          SelfOxidizingSignalFlare: "9",
          SolarPoweredLights: "10",
          SolarPoweredRadio: "11",
          SpaceBlanket: "12",
          SpaceSuitRepairKit: "13",
          ThirtyLitresOfH20: "14",
          UtilityKnife: "15"
      },
      hasSubmitted: false,
      hasFinished: false,
      strategy: "1",
      passcode: pass,
  })
  .then(() => {
      console.log("Team successfully added!");
  })
  .catch(error => {
      console.error("Error adding team: ", error);
  });
};

export const ArtemisAdmin = () => {
  return(
      <div>
          <GlobalStyle backgroundColor={"black"} />
          <ArtemisGameDiv>
              <ArtemisGame gameName="artemisMars"></ArtemisGame>
          </ArtemisGameDiv>
          <ArtemisGameDiv>
              <ArtemisGame gameName="artemisVenus"></ArtemisGame>
          </ArtemisGameDiv>
          <ArtemisGameDiv>
              <ArtemisGame gameName="artemisMercury"></ArtemisGame>
          </ArtemisGameDiv>
          <ArtemisGameDiv>
              <ArtemisGame gameName="artemisJupiter"></ArtemisGame>
          </ArtemisGameDiv>
          <ArtemisGameDiv>
              <ArtemisGame gameName="artemisSaturn"></ArtemisGame>
          </ArtemisGameDiv>
          <ArtemisGameDiv>
              <ArtemisGame gameName="artemisPluto"></ArtemisGame>
          </ArtemisGameDiv>
      </div>
  )
}

export default ArtemisAdmin

const ArtemisGameDiv = styled.div`
    text-align: center;
    background-color: white;
`;