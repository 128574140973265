import { Container, Row, Col } from "reactstrap";
import React from "react";
import FinalVerdictFacilitatorGames from "./final-verdict-facilitator-games";

const FinalVerdictFacilitators = () => {

    return(
        <Container>
            <Row>
                <Col>
                    <FinalVerdictFacilitatorGames></FinalVerdictFacilitatorGames>
                </Col>
            </Row>
        </Container>
    )
}

export default FinalVerdictFacilitators
