import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import { v4 as uuidv4 } from 'uuid';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyAI1UiRj2alddEgS02OJr25_ifJk9EpDuQ",
    authDomain: "deleptual-debug-database.firebaseapp.com",
    projectId: "deleptual-debug-database",
    storageBucket: "deleptual-debug-database.appspot.com",
    messagingSenderId: "963674463363",
    appId: "1:963674463363:web:9fae8ed3cc6a38d2dd8fb6",
    measurementId: "G-PLKG6CZ18S"
}, "deleptual-debug");

const deleptualDebugDB = firebaseApp.firestore();

export const generateGUID = uuidv4;
export const analytics = firebase.analytics();
export const auth = firebase.auth();
export { deleptualDebugDB };