import React, { useEffect, useState } from "react";
import { Col, Button, Row } from "reactstrap";
import { Colors, 
    OutputRow, 
    BoldLabel, 
    LeftLabel, 
    HighlightedCost, 
    TableTitle,
    WhiteTableContainer,
    UnderlineButton,} from "../../../../utils/budget-tool-specific";

import AccommodationRow from "./exp-accommodation-row";
import FlightRow from "./exp-flight-row";
import ActivityRow from "./exp-activity-row";
import TransportationRow from "./exp-transportation-row";
import MealsRow from "./exp-meals-row";
import MiscellaneousRow from "./exp-miscellaneous-row";
import FinalCostRow from './final-cost-row';
import ContingencyRow from "./exp-contingency-row";
import { displayValue } from "../../helper";
import { calculateSubCostPerAttendee, 
    calculateTotalCostPerAttendee, 
    calculateCostTrip,
    calculateSubCostNoFlightsPerAttendee,
    calculateCostPerAttendee, } from "./cost-helper";
import MeetingsRow from "./exp-meetings.row";
import styled from "styled-components";
import NotesRow from "./exp-notes-row";

const CostOutput = ({guideData, expData}) => {
    return (
        <React.Fragment>
            <LeftLabel>Per Attendee Cost: </LeftLabel> <HighlightedCost>{guideData.currency.value} {displayValue(calculateCostTrip(guideData,expData) / guideData.attendees.value)}</HighlightedCost> <br/>
            <LeftLabel>Total Program Cost: </LeftLabel> <HighlightedCost>{guideData.currency.value} {displayValue(calculateCostTrip(guideData, expData))}</HighlightedCost> <br/> <br/>

            <TableTitle>
                Trip Breakdown
            </TableTitle>
            <WhiteTableContainer id="output-form-container"> 
                <OutputRow>
                    <Col lg="1">
                    </Col>
                    <Col>
                        <BoldLabel>
                            Category
                        </BoldLabel>
                    </Col>
                    <Col lg="2">
                        <BoldLabel>
                            Per Attendee ({guideData.currency.value})
                        </BoldLabel>
                    </Col> 
                    <Col lg="1"></Col>
                    <Col lg="2">
                        <BoldLabel>
                            Subtotal ({guideData.currency.value})
                        </BoldLabel>
                    </Col> 
                </OutputRow>
                
                <AccommodationRow guideData={guideData} expData={expData}/>
                <FlightRow guideData={guideData} expData={expData}/>
                <ActivityRow guideData={guideData} expData={expData}/>
                <TransportationRow guideData={guideData} expData={expData}/> 
                <MealsRow guideData={guideData} expData={expData}/>
                <MeetingsRow guideData={guideData} expData={expData}/>
                <MiscellaneousRow guideData={guideData} expData={expData}/>
                {expData.notes !== undefined && expData.notes !== "" && <NotesRow guideData={guideData} expData={expData}/>}
                <ContingencyRow guideData={guideData} expData={expData}/>
                <FinalCostRow guideData={guideData} expData={expData}/>
        
                </WhiteTableContainer>
            </React.Fragment>
    )
}

export default CostOutput;