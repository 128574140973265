import { Button, Input, Container, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteGameV3 as deleteGame, listenToDocumentV2 as listenToDocument, getUTCTimeV2 as getUTCTime, startGameV3 as startGame } from "../../../../utils/firestore-functions";
import { pirateshipDB, generateGUID, storage, storageRef, uploadBytesResumable, getDownloadURL, deleteObject } from "../../../../utils/database/firebase-pirate-ship";
import { resetGameSettings, newTeamSettings } from "../../../../utils/pirate-ship-specific";
import Timer from "../../../../utils/timer-v2"
import firebase from "firebase/compat/app";
import { runTransaction, doc } from '@firebase/firestore';
import { PirateShipInstanceTeamAdmin } from './pirate-ship-instance-team-admin';
import { Link } from 'react-router-dom';
import { UploadImageToStorage } from '../../../../utils/firestore-functions';
import ImageUploadAndCrop from '../../../form-components/imageUploadAndCrop';
import { DateTime } from "luxon";

export const PirateShipInstanceAdmin = (props) => {
    
    const [gameDetails, setGameDetails] = useState({});
    const [teamNameInput, setTeamNameInput] = useState("");
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [gameImageUrl, setGameImageUrl] = useState("");
    const [gameImageFile, setGameImageFile] = useState();
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState(DateTime.now().toISO());
    const [seats, setSeats] = useState(0);
    
    const toggle = () => setModal(!modal);

    useEffect(() => {
        var unsubscribe = listenToDocument(pirateshipDB, props.instance[0], "gameSettings", SetDetails);
        return unsubscribe;
    }, [props.instance]);

    const SetDetails = (details) => {
        setGameDetails(details);
        if(details.imageUrl){
            setGameImageUrl(details.imageUrl);
        }
        else{
            setGameImageUrl("");
        }
    }

    const resetGame = async (game) => {
        const settingsRef = doc(pirateshipDB, game, "gameSettings")
        try {
            await runTransaction(pirateshipDB, async (transaction) => {
                transaction.update(settingsRef, {
                    ...resetGameSettings,
                    updated: firebase.firestore.Timestamp.fromDate(new Date()),
                });
            })
            console.log("Success.");
        } catch (e) {
            console.log("Transaction Failed.", e);
        };
    };

    const addTeam = async (game, teamName) => {
        var x = generateGUID();
        const ref = doc(pirateshipDB, game, x);
        const gameSettingsRef = doc(pirateshipDB, game, "gameSettings");
        try {
            await runTransaction(pirateshipDB, async (transaction) => {
                transaction.set(ref, {
                    ...newTeamSettings,
                    name: teamName,
                    created: firebase.firestore.Timestamp.fromDate(new Date()),
                    updated: firebase.firestore.Timestamp.fromDate(new Date()),
                });
                transaction.update(gameSettingsRef, {
                    ["teamNames" + "." + x]: teamName
                });
            })
            console.log("Success!");
        } catch (e) {
            console.log("Transaction Failed: ", e);
        }
    };
    
    const getImage = (file, imageUrl) => {
        setGameImageFile(file);
        setGameImageUrl(imageUrl);
    }
  
    const doSubmit =() => {
        console.log(gameDetails);
        console.log(props.instance);
        UploadImageToStorage(gameImageFile, 
            "gameImages/", 
            props.instance[0], 
            SuccessUpload, 
            storage, 
            storageRef, 
            uploadBytesResumable,
            getDownloadURL);
    }

    const SuccessUpload = async (url) => {
        const docRef = doc(pirateshipDB, props.instance[0], "gameSettings");
        
    try {
        await runTransaction(pirateshipDB, async (transaction) => {
        const docData = await transaction.get(docRef);
        if (!docData.exists()) {
            throw "Document does not exist!";
        }
        transaction.update(docRef, { imageUrl: url });
        });
        console.log("Transaction successfully committed!");
    } catch (e) {
        console.log("Transaction failed: ", e);
    }
        setGameImageFile("");
        setModalTitle("Image Uploaded");
        setModalMessage("Game Image Uploaded Successfully");
        toggle();
    }

    const RemoveImage = () => {
        setGameImageUrl("");
        setGameImageFile("");
    }

    const delGame = (gameName, database) => {
        if(gameDetails.imageUrl){
            //remove image from storage
            const imageRef = storageRef(storage, "gameImages/" + props.instance[0]);
            deleteObject(imageRef).then(() => {
                console.log("Deleted Image");
            }).catch((error) => {
                console.log("Unable to delete image from storage");
                console.log(error);
            })
        }
        deleteGame(gameName, database);
    }

    const toggleFacilitators = async (game) => {
        const gameSettingsRef = doc(pirateshipDB, game, "gameSettings");
        try {
            await runTransaction(pirateshipDB, async (transaction) => {
                transaction.update(gameSettingsRef, {
                    ["hasFacilitators"]: !gameDetails.hasFacilitators
                });
            })
            console.log("Success!");
        } catch (e) {
            console.log("Transaction Failed: ", e);
        }
    }

    const updateSeats = async (game) => {
        let gameSettingsRef = doc(pirateshipDB, game, "gameSettings");
        try {
            await runTransaction(pirateshipDB, async (transaction) => {
                transaction.update(gameSettingsRef, {
                    ["seatsMax"]: seats
                });
            })
            console.log("Success!");
        } catch (e) {
            console.log("Transaction Failed: ", e);
        }
    }

    const resetSeats = async (game) => {
        let gameSettingsRef = doc(pirateshipDB, game, "gameSettings");
        try {
            await runTransaction(pirateshipDB, async (transaction) => {
                transaction.update(gameSettingsRef, {
                    ["seatsCurrent"]: 0
                });
            })
            console.log("Success!");
        } catch (e) {
            console.log("Transaction Failed: ", e);
        }
    }

    const updateDate = async (game, d) => {
        let dt = DateTime.fromISO(d);
        setDate(dt.toISO());
      
        const gameSettingsRef = doc(pirateshipDB, game, "gameSettings");
        
        try {
            await runTransaction(pirateshipDB, async (transaction) => {
                transaction.update(gameSettingsRef, {
                    ["scheduleTime"]: dt.toISO(),
                });
            })
            console.log("Success!");
        } catch (e) {
            console.log("Transaction Failed: ", e);
        }        
    }

    const calendarValue = () => {
        let y = DateTime.fromISO(gameDetails.scheduleTime).toISO();
        if (y === null){
            return "";
        }
        let x = DateTime.fromISO(gameDetails.scheduleTime).toISO().substring(0,16);
        return x;
    }

    return(
        <React.Fragment>
            <BreadcrumbContainer>
                <Row align-items-center="true">
                    <Col  xs="4" style={{color:"white", alignItems:"center", display:"flex"}}>
                        <StyleH4  onClick={() => {setOpen(!open)}}>
                            {props.gameName} {"/"} {props.instance[1]}
                            {!open && <FAIconButton icon={["fas", "chevron-down"]}></FAIconButton>}
                            {open && <FAIconButton icon={["fas", "chevron-up"]}></FAIconButton>}
                            </StyleH4>
                    </Col>
                    <Col style={{textAlign:"right", justifyContent:"flex-end"}}>
                        <Link to={"/pirate-ship/" + props.instance[0] + "/scores"}>
                            <Button color="primary">Score Screen</Button>
                        </Link>
                        {Object.entries(gameDetails).length > 0 && !gameDetails.hasGameStarted && <Button color="primary" style={{marginLeft:"10px"}} onClick={() => getUTCTime(pirateshipDB, props.instance[0], startGame)}>
                            Start Game
                        </Button>}
                        <Button style={{marginLeft:"10px"}} color="warning" onClick={() => {
                            resetGame(props.instance[0], pirateshipDB);
                        }}>
                            Reset Game
                            <FAIconButton icon={["fas", "redo"]}></FAIconButton>
                        </Button>
                        <Button style={{marginLeft:"10px"}} color="danger" onClick={() => {
                            delGame(props.instance[0], pirateshipDB);
                            props.setSelectedInstance({});
                        }}>
                            Delete Game
                            <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                        </Button> 
                    </Col>
                </Row>
                <Row align-items-center="true" style={{marginTop:"10px"}}>
                    {gameDetails.hasFacilitators !== undefined && <Col style={{textAlign:"right", justifyContent:"flex-end", display:"flex", alignItems:"center"}}>
                        <StyledP style={{marginRight:"10px"}}>Has Facilitators:</StyledP>
                        <Button color="warning" style={{display:"flex"}} onClick={() => {
                            toggleFacilitators(props.instance[0]);
                        }}>
                            {gameDetails.hasFacilitators.toString()}
                        </Button>
                    </Col>}
                </Row>
                {gameDetails !== undefined && gameDetails.hasFacilitators !== undefined && !gameDetails.hasFacilitators && <Row align-items-center="true" style={{marginTop:"10px"}}>
                    <Col style={{textAlign:"right", justifyContent:"flex-end", display:"flex", alignItems:"center"}}>
                        <StyledP style={{marginRight:"10px"}}>(Can't use Safari to select this) Scheduled Start: </StyledP>
                        <CalendarInput value={gameDetails.scheduleTime === "" ? "" : calendarValue()} onChange={e => {updateDate(props.instance[0], DateTime.fromISO(e.target.value))}} type="datetime-local"></CalendarInput>
                    </Col>
                </Row>}
                {gameDetails !== undefined && gameDetails.hasFacilitators !== undefined && !gameDetails.hasFacilitators && <Row align-items-center="true" style={{marginTop:"10px"}}>
                    <Col style={{textAlign:"right", justifyContent:"flex-end", display:"flex", alignItems:"center"}}>
                        <Button style={{marginRight:"10px"}} color="warning">Reset<FAIconButton icon={["fas", "redo"]} onClick={() => {resetSeats(props.instance[0])}}></FAIconButton></Button>
                        <StyledP style={{marginRight:"10px"}}>Seats: {gameDetails.seatsCurrent} / {gameDetails.seatsMax}</StyledP>
                        <Input value={seats} onChange={((e) => {setSeats(e.target.value)})} type="number" min="0" style={{width:"80px", marginRight:"10px", marginLeft:"10px"}}></Input>
                        <Button color="primary" style={{marginRight:"10px"}} onClick={() => {updateSeats(props.instance[0])}}>Update</Button>
                    </Col>
                </Row>}
                <br />
                <Collapse isOpen={open}>
                <Row>
                    {!gameImageUrl &&<ImageUploadAndCrop crop={{unit: '%', aspect: 1/1}} descriptionText="Choose Company Image" submitFunction={getImage} />}
                    {gameImageUrl && <Container fluid={true}>
                        <Row>
                            <Col>
                                <StyledProfileImage src={gameImageUrl} alt="" />
                                <StyledButton onClick={() => RemoveImage()} color="danger">
                                    <FAIconButton icon={["fas", "times"]}></FAIconButton>
                                </StyledButton>
                                {(gameImageUrl && gameImageFile) && <StyledButton color="primary" onClick={() => {doSubmit()}}>Save Picture</StyledButton>} 
                            </Col>
                        </Row>
                        <br />
                        <Row>

                            <StyledModal isOpen={modal}>
                                <ModalHeader>{modalTitle}</ModalHeader>
                                    <ModalBody>
                                        {modalMessage}
                                    </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" onClick={toggle}>Done</Button>
                                </ModalFooter>
                            </StyledModal>
                        </Row>
                    </Container>}
                </Row>
                </Collapse>
            </BreadcrumbContainer>
            <GameContainer>
                <Row>
                    <Col xs="6" style={{color:"white", display:"flex"}}>
                        {(Object.entries(gameDetails).length > 0) && <div>
                            Has Started: {gameDetails.hasGameStarted.toString()}
                            <br></br>
                            {gameDetails.hasGameStarted && <Timer startTime={gameDetails.startTime} gameLength={3300000}></Timer>}
                            Start Time: {gameDetails.hasGameStarted && (new Date(parseInt(gameDetails.startTime))).toString()}
                        </div>}
                    </Col>
                    {Object.entries(gameDetails).length > 0 && !gameDetails.hasGameStarted && <Col style={{alignItems:"center", display:"flex", justifyContent:"flex-end"}}>
                        <Button color="primary" onClick={() => {addTeam(props.instance[0], teamNameInput);}}>
                            Add Team
                            <FAIconButton icon={["fas", "user-plus"]}></FAIconButton>
                        </Button>
                        <StyledInput value={teamNameInput} placeholder={"new team name"} onChange={e => setTeamNameInput(e.target.value)}/>
                    </Col>}
                </Row>
                <Row>
                </Row>
            </GameContainer>
            {(Object.entries(gameDetails).length > 0) &&
                Object.entries(gameDetails.teamNames).sort((a,b) => {
                    if (a[1] < b[1]){
                        return -1;
                    }
                    if (a[1] > b[1]){
                        return 1;
                    }
                    return 0;}
                    ).map(teamName => {
                    return <PirateShipInstanceTeamAdmin key={teamName} instance={props.instance} teamName={teamName}></PirateShipInstanceTeamAdmin>
                })
            } 
        </React.Fragment>
    )
}

export default PirateShipInstanceAdmin;

const StyledP = styled.p`
    margin: 0px;
    display: flex;
`;

const StyleH4 = styled.h4`
    margin-top: auto;
    margin-bottom: auto;
`;

const StyledInput = styled(Input)`
    width: auto;
    margin-left: 8px;
`;

const BreadcrumbContainer = styled(Container)`
    border-color: black;
    border-style: solid;
    border-radius: 10px;
    color: white;
    background-color: rgba(0,0,0,0.7);
    padding: 5px;
    margin-bottom: 10px;
    align-content: center;
`;

const GameContainer = styled(Container)`
    border-color: black;
    border-style: solid;
    border-radius: 10px;
    color: white;
    background-color: rgba(0,0,0,0.7);
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 10px;
`;

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;

const StyledModal = styled(Modal)`
    position: absolute;
    left: 40%;
    top: 40%;
`;

const StyledProfileImage = styled.img`
    padding: 5px;
    margin-right: 30px;
    box-shadow: 0 0.5rem 1rem #9780af;
    max-width: 250px;
`;

const StyledButton = styled(Button)`
    margin-right: 15px;
`;

const CalendarInput = styled(Input)`
    max-width: 260px;
    padding: 5px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: -webkit-center;
    text-align: -moz-center;
    text-align: -o-center; 
    text-align: -ms-center;
    text-align: center;
`;