import React, { useState } from "react";
import { Unity, useUnityContext  } from "react-unity-webgl";
import styled, { createGlobalStyle } from 'styled-components'
import Background from '../../assets/images/background-02.jpg'
import LoadingWheel from '../../assets/images/LoadingWheel.svg';
import { db } from "../../firebase";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => (props.backgroundColor.toString())};
  }
`;

export const Artemis = () => {

    const [progression, setProgression] = useState(0);
    const [loading, setLoading] = useState(true); 
    const [gameName, setGameName] = useState("");
    const [teamName, setTeamName] = useState("");

    const { unityProvider } = useUnityContext ({
      loaderUrl: "https://cdn.moniker-virtual.com/artemis/Default%20WebGL.loader.js",
      dataUrl: "https://cdn.moniker-virtual.com/artemis/Default%20WebGL.data",
      frameworkUrl: "https://cdn.moniker-virtual.com/artemis/Default%20WebGL.framework.js",
      codeUrl: "https://monikerspace.nyc3.digitaloceanspaces.com/artemis/Default%20WebGL.wasm",
    });

    unityProvider.on("CheckTeamPasscode", teamInformation => {
      var strings = teamInformation.split(",");
      var teamNumber = strings[0];
      var gameName = strings[2];
      db.collection(gameName).doc(teamNumber)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.data() !== undefined && querySnapshot.data().toString() !== ""){
          var obj = querySnapshot.data();
          unityProvider.send(
            "React Communicator", 
            "ReturnTeamData", 
            JSON.stringify(obj)
          );
        }
        else{
          unityProvider.send(
            "React Communicator", 
            "TeamNotFound"
          );
        }
      });
    });
    
    unityProvider.on("CheckAdminPasscode", gameInformation => {
      var strings = gameInformation.split(",");
      var gameName = strings[1];
      db.collection(gameName).doc("gameSettings")
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.data() !== undefined && querySnapshot.data().toString() !== ""){
          var obj = querySnapshot.data();
          unityProvider.send(
            "React Communicator", 
            "ReturnAdminData", 
            JSON.stringify(obj)
          );
        }
        else{
          unityProvider.send(
            "React Communicator", 
            "AdminNotFound"
          );
        }
      });
    });
    
    unityProvider.on("ReactGetServerUTC", () => {
      fetch("https://us-central1-escape-room-1980b.cloudfunctions.net/getUTCTime", {
        "method": "GET",
        "headers": {
        }
      })
      .then(response => response.json())
      .then(response => {
        unityProvider.send(
          "React Communicator", 
          "ReturnServerUTC", 
          response.result.toString()
        );
      })
      .catch(err => { console.log(err); 
      });
    })

    unityProvider.on("progress", progress => {
      if (progress >= 1){
        setLoading(false);
      }
      setProgression(progress)
    });

    unityProvider.on("ReactUpdateItems", itemsInformation => {
      var strings = itemsInformation.split("*");
      var passcode = strings[1]
      var jsonItems = strings[2];
      var _strategy = strings[3];
      var obj = JSON.parse(jsonItems);
      db.runTransaction(function(transaction) {
        return transaction.get(db.collection(gameName).doc(teamName)).then(function(doc) {
          if (doc.exists){
            var d = doc.data();
            if (d.passcode === passcode){
              transaction.update(db.collection(gameName).doc(teamName),{
                strategy: _strategy,
                items: obj
              });
            }
            else{
              console.log("Passcodes don't match.");
            }
          }
          else{
            console.log("In React Update Items, could not find document for teamNumber: " + teamName + " and gameName: " + gameName + " trying to pass items: " + jsonItems);
          }
        });
      });
    });

    unityProvider.on("ReactUpdateScore", scoreInformation => {
      var strings = scoreInformation.split(",");
      var score = strings[1];
      var strategy = strings[2];
      db.runTransaction(function(transaction) {
        return transaction.get(db.collection(gameName).doc("gameSettings")).then(function(doc) {
          if (doc.exists){
            var d = doc.data();
            var obj = d.teamScores;
            if (obj[teamName] === undefined || obj[teamName].toString() === ""){
              obj[teamName] = { score, strategy };
              transaction.update(db.collection(gameName).doc("gameSettings"), {
                teamScores: obj
              });
              transaction.update(db.collection(gameName).doc(teamName), {
                hasFinished: true
              });
            }
          }
          else{
            console.log("Failed when trying to update score for, score passed in: " + score);
          }
        });
      });
    })

    unityProvider.on("ListenToGame", _gameName => {
      setGameName(_gameName);
      db.collection(_gameName).doc("gameSettings").onSnapshot(function(doc) 
      {
        if (doc.data !== null){
          unityProvider.send(
            "React Communicator",  
            "UpdateGameData", 
            JSON.stringify(doc.data())
          );
        }
        else{
          unityProvider.send(
            "React Communicator",
            "GameNotFound"
          );
        }
      });
    });

    unityProvider.on("ListenToTeam", teamInformation => {
      var strings = teamInformation.split(",");
      var teamNumber = strings[0];
      var _gameName = strings[1];
      setTeamName(teamNumber);
      setGameName(_gameName)
      db.collection(_gameName).doc(teamNumber).onSnapshot(function(doc) 
      {
        if (doc.data != null){
          unityProvider.send(
            "React Communicator", 
            "UpdateTeamData", 
            JSON.stringify(doc.data())
          );
        }
        else{
          unityProvider.send(
            "React Communicator",
            "TeamNotFound"
          );
        }
      });
    });

    return (
      <FullHeightDiv>
        <GlobalStyle backgroundColor={"black"} />
        {loading && <LoadingDiv style={{backgroundImage: "url(" + Background + ")"}}>
          <LoadingP>Loading</LoadingP>
          <LoadingWheelDiv style={{backgroundImage: "url(" + LoadingWheel + ")"}}></LoadingWheelDiv>
        </LoadingDiv>}
        <Unity unityProvider={unityProvider} style={{width:"100%", height:"100%", position:"absolute"}}/>
      </FullHeightDiv>
    )
}

export default Artemis

const FullHeightDiv = styled.div`
  height: 99vh;
`;

const LoadingP = styled.p`
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    color: white;
`;

const LoadingWheelDiv = styled.div`
    text-align: center;
    position: absolute;
    top: 50%;
    left: 55%;
    height: 16px;
    width: 128px;
    z-index: 1;
`;

const LoadingDiv = styled.div`
    background-size: cover;
    height: 938px;
    display: inline-block;
    width: 100%;
    text-align: center;
`;