import React from 'react';
import { Col } from 'reactstrap';
import styled from 'styled-components';
import { Colors, OutputRow, BoldLabel } from "../../../../utils/budget-tool-specific";
import SummaryExperienceRow from "./summary-experience-row";

const SummaryTabForm = ({guideData}) => {

    return ( 
        <React.Fragment>
            
            <StyledContainer>
                <OutputRow style={{display: "flex", alignItems: "flex-end"}}>
                    <Col lg="1">
                    </Col>
                    <Col>
                        <BoldLabel>
                            Experience
                        </BoldLabel>
                    </Col>
                    <Col lg="2">
                        <BoldLabel>
                            Cost Per Attendee
                        </BoldLabel>
                    </Col>
                    <Col lg="2">
                        <BoldLabel>
                            Total Est. Cost of Trip
                        </BoldLabel>
                    </Col>
                </OutputRow>
                {Object.values(guideData.experiences.value).sort((a,b) => {
                    return parseInt(a.index) - parseInt(b.index);
                }).map((experience, i) => {
                    let expData = {   title: {value: experience.title, invalid: false, required: true},
                                    destCurrency: {value: experience.destCurrency, invalid: false, required: true},
                                    base: {value: experience.base, invalid: false, required: true},
                                    convert: {value: experience.convert, invalid: false, required: true},
                                    location: {value: experience.location, invalid: false, required: true},
                                    accommodationCost: {value: experience.accommodationCost, invalid: false, required: true},
                                    flightCost: {value: experience.flightCost, invalid: false, required: true},
                                    costPerTransportation: {value: experience.costPerTransportation, invalid: false, required: true}, 
                                    transportation: {value: experience.transportation, invalid: false, required: true},
                                    costPerActivity: {value: experience.costPerActivity, invalid: false, required: true}, 
                                    numActivities: {value: experience.numActivities, invalid: false, required: true},
                                    costPerBreakfast: {value: experience.costPerBreakfast, invalid: false, required: true}, 
                                    numBreakfast: {value: experience.numBreakfast, invalid: false, required: true},
                                    costPerLunch: {value: experience.costPerLunch, invalid: false, required: true}, 
                                    numLunch: {value: experience.numLunch, invalid: false, required: true}, 
                                    costPerDinner: {value: experience.costPerDinner, invalid: false, required: true}, 
                                    numDinner: {value: experience.numDinner, invalid: false, required: true},
                                    costPerAlcohol: {value: experience.costPerAlcohol, invalid: false, required: true}, 
                                    numAlcohol: {value: experience.numAlcohol, invalid: false, required: true}, 
                                    roomRental: {value: experience.roomRental, invalid: false, required: true},
                                    av: {value: experience.av, invalid: false, required: true},  
                                    setupFee: {value: experience.setupFee, invalid: false, required: true}, 
                                    costPerCoffee: {value: experience.costPerCoffee, invalid: false, required: true}, 
                                    numCoffee: {value: experience.numCoffee, invalid: false, required: true}, 
                                    numMeetings: {value: experience.numMeetings, invalid: false, required: true}, 
                                    miscellaneousCosts: {value: experience.miscellaneousCosts, invalid: false, required: true},
                                    index: experience.index ? experience.index : 0,
                    }
                    return (
                        <SummaryExperienceRow key={i}
                            guideData={guideData}
                            expData={expData}/>
                    )
                })}
            </StyledContainer>
        </React.Fragment>
    )
}

export default SummaryTabForm;

const StyledContainer = styled.div`
    margin-left: 1px;
    margin-right: 1px;
    padding: 15px;
    border-radius: 0px 0px 10px 10px;
    border: 2px solid ${Colors.primaryAccent}
` 