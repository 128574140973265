import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Unity, useUnityContext } from "react-unity-webgl";
import styled, { createGlobalStyle } from 'styled-components'
import { omegaZombiesDB } from "../../utils/database/firebase-omega-protocol-zombies";
import { getUTCTimeV2 as getUTCTime, startGameV3 as startGame } from "../../utils/firestore-functions";
import { doc, getDoc, runTransaction, onSnapshot } from "@firebase/firestore";
import { useCookies } from 'react-cookie';
import { DateTime } from "luxon";
import firebase from "firebase/compat/app";
import { isMobile } from 'react-device-detect';

const OmegaProtocolZombiesFacilitatorless = (props) => {
  const [unityIsReady, setUnityIsReady] = useState(false);
  const [cookies, setCookie] = useCookies();
  const [progression, setProgression] = useState(0);
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState({});
  /*
  const [unityProvider]= useState(new UnityContext({
    loaderUrl: "https://" + props.environment + "/omega-protocol-zombies/defaultWebGL.loader.js",
    dataUrl: "https://" + props.environment + "/omega-protocol-zombies/defaultWebGL.data",
    frameworkUrl: "https://" + props.environment + "/omega-protocol-zombies/defaultWebGL.framework.js",
    codeUrl: "https://" + props.environment + "/omega-protocol-zombies/defaultWebGL.wasm",
    streamingAssetsUrl: "https://" + props.environment + "/omega-protocol-zombies/StreamingAssets",
    }))
*/
const { unityProvider, sendMessage, addEventListener, isLoaded, loadingProgression } = useUnityContext({
    loaderUrl: "https://monikerspace.nyc3.cdn.digitaloceanspaces.com/omega-protocol-zombies-v2/Default%20WebGL.loader.js",
    dataUrl: "https://monikerspace.nyc3.cdn.digitaloceanspaces.com/omega-protocol-zombies-v2/Default%20WebGL.data",
    frameworkUrl: "https://monikerspace.nyc3.cdn.digitaloceanspaces.com/omega-protocol-zombies-v2/Default%20WebGL.framework.js",
    codeUrl: "https://monikerspace.nyc3.cdn.digitaloceanspaces.com/omega-protocol-zombies-v2/Default%20WebGL.wasm",
    streamingAssetsUrl: "https://monikerspace.nyc3.cdn.digitaloceanspaces.com/omega-protocol-zombies-v2/StreamingAssets",
    });
    
    useEffect(() => {
      if (isLoaded){
        setUnityIsReady(true);
      }
    }, [isLoaded]);

  let { collection } = useParams();
  let { document } = useParams();

  const gameRef = doc(omegaZombiesDB, collection, "gameSettings");

  var finishTime = "";

  const GlobalStyle = createGlobalStyle`
    body {
      background-color: ${props => (props.backgroundColor.toString())};
    }
  `;

  const updateTeamFinishingTime = async (db, col, time) => 
  {
    const teamRef = doc(omegaZombiesDB, collection, document);

    try
    {
      await runTransaction(omegaZombiesDB, async(transaction) => {
        let docData = (await transaction.get(gameRef)).data();
        let teamData = (await transaction.get(teamRef)).data();

        if(teamData.endTime !== "")
        {
          return;
        }
        var score = 100;
        var finishedTime;

        if(time === "Did not finish")
        {
          finishedTime = time;
        }
        else
        {
          finishedTime = millisToMinutesAndSeconds(time - docData.startTime);
        }

        if(!docData.hasFacilitators)
        {
         score = CalculateScore(teamData, finishedTime);
        }
        transaction.update(teamRef, {endTime: finishedTime, score: score})
      })
    }
    catch (e)
    {
        console.log("Failed to set time");
    }
  }

  const CalculateScore = (teamData, finishedTime) => {
    var score = 0;
    const locks = teamData.locks;
    const clues = teamData.clues;
    
    if(locks.houseDoor){
        score += 5;
    }

    if(locks.librarySafe){
      score += ReturnClueValue(clues.librarySafe, 5);
    }

    if(locks.bathroomMeds){
      score += ReturnClueValue(clues.bathroomMeds, 5);
    }

    if(locks.masterBedroomDrawer){
      score += ReturnClueValue(clues.masterBedroomDrawer, 5);
    }

    if(locks.basementDoor){
      score += ReturnClueValue(clues.basementDoor, 20);
    }

    if(locks.labFridge){
      score += ReturnClueValue(clues.labFridge, 5);
    }

    if(locks.cabinDoor){
        if(!clues.cabinDoor[clues.cabinDoor.length - 1]){
            score += ReturnClueValue(clues.cabinDoor, 10);

            score += ReturnClueValue(clues.dictaphone, 2);
            score += ReturnClueValue(clues.email, 2);
            score += ReturnClueValue(clues.morseCode, 2);
            score += ReturnClueValue(clues.periodicTable, 2);
            score += ReturnClueValue(clues.pigpenCipher, 2);
        }
    }

    if(locks.outsideCabin){
        score += ReturnClueValue(clues.outsideCabin, 5);
    }

    if(locks.cabinConsole){
        score += ReturnClueValue(clues.cabinConsole, 5);
    }

    if(locks.cabinFridge){
        score += ReturnClueValue(clues.cabinFridge, 5);
    }

    if(finishTime !== "DNF"){
        score += ReturnClueValue(clues.beaker, 10);

        var parsedFinishTime = finishedTime.split(":");
        var minutes = parsedFinishTime[0];
        var seconds = parsedFinishTime[1];
        var finishedMilliseconds = (minutes * 60000) + (seconds * 1000);

        if(finishedMilliseconds > 3000000){
          var difference = finishedMilliseconds - 3000000
          var percentage = difference / 1500000
          score += ((percentage - 1) * -10);
        }
        else{
          score += 10;
        }
    }

    return score;
  }

  const ReturnClueValue = (clueArray, totalPoints) => {
    var totalClues = clueArray.length;
    var cluesUsed = totalClues;

    for(let i = 0; i < totalClues; i++){
      if(!clueArray[i]){
        cluesUsed = i;
        break;
      }
    }

    return ((cluesUsed / totalClues) - 1) * -totalPoints;
  }


  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }

  const startCompareTime = async () => {
    getUTCTime(null, null, compareTime, null);
  }

  const compareTime = async (database, game, serverTime, minutesOffset) => {
    let docRef = doc(omegaZombiesDB, collection, "gameSettings");
    let timeToPlay = false;
    try {
      await runTransaction(omegaZombiesDB, async (transaction) => {
        let docData = (await transaction.get(docRef)).data();
        let t = docData["scheduleTime"];
        let scheduledTime = DateTime.fromISO(t);
        if (serverTime > scheduledTime){
          timeToPlay = true;
        }
      });
    } catch (e) {
      console.log("Failed to compare time:", e);
    }
    if (timeToPlay){
      sendMessage(
        "ReactCommunicator", 
        "ReactListener", 
        ("GameCanBeStarted")
      );
    }
    else{
      return false;
    }
  };



  useEffect(
    () => {
      async function Prepare () {
        const ref = doc(omegaZombiesDB, collection, document);
        const data = await getDoc(ref);
        setPlayers(data.data().playerNames);
      }
      Prepare();
    }, [unityProvider, unityIsReady]);

    useEffect(() => {
      if (unityIsReady){

        console.log("Unity is loaded");
        sendMessage("ReactCommunicator", "ReactListener", "ReactLoaded");

        addEventListener("ReactGetURLInformation", () => {
          sendMessage(
            "ReactCommunicator", 
            "ReactListener", 
            ("SetURLParameters" + "*" + collection + "*" + document)
          );
        })

        addEventListener("ReactCheckTime", async () => {
          startCompareTime();
        });

        addEventListener("ReactStartGameTimer", async (information) => {
          if(information === "zombie")
          {
            getUTCTime(omegaZombiesDB, collection, startGame)
          }
        });

        addEventListener("ReactJoinRoom", async () => {
          if (cookies['moniker-omegaZombies'] !== undefined && cookies['moniker-omegaZombies'] === "playing"){
            sendMessage(
              "ReactCommunicator", 
              "ReactListener", 
              ("JoinRoomSuccess")
            );
            return;
          }
          let docRef = doc(omegaZombiesDB, collection, "gameSettings");
          try {
            await runTransaction(omegaZombiesDB, async (transaction) => {
              let docData = (await transaction.get(docRef)).data();
              docData["seatsCurrent"]++;
              setCookie('moniker-omegaZombies', 'playing', { path: '/'});
              transaction.update(docRef, docData);
              sendMessage(
                "ReactCommunicator", 
                "ReactListener", 
                ("JoinRoomSuccess")
              );
            });
          } catch (e) {
            console.log("Failed function call ReactJoinRoom:", e);
          }
        });

        addEventListener("ListenToTeam", () => {
          const docRef = doc(omegaZombiesDB, collection, document);
          onSnapshot(docRef, (d) => {
            if (d.data != null && d.data !== undefined){
              sendMessage(
                "ReactCommunicator", 
                "ReactListener", 
                ("ReturnTeamData*" + JSON.stringify(d.data()))
              );
            }
            else{
              sendMessage(
                "ReactCommunicator",
                "ThrowError",
                "Could not find team data"
              );
            }
          })
        });

        addEventListener("ListenToGame", () => {
          const docRef = doc(omegaZombiesDB, collection, "gameSettings");
          onSnapshot(docRef, (d) => {
            if (d.data != null && d.data != undefined){
              sendMessage(
                "ReactCommunicator", 
                "ReactListener", 
                ("ReturnGameData*" + JSON.stringify(d.data()))
              );
            }
            else{
              sendMessage(
                "ReactCommunicator",
                "ThrowError",
                "Could not find game data"
              );
            }
          })
        });

        addEventListener("ReactAskForClue", async (information) => {
          const docRef = doc(omegaZombiesDB, collection, document);
          try{
            await runTransaction(omegaZombiesDB, async (transaction) =>{
              let docData = (await transaction.get(docRef)).data();
              for(let i = 0; i < docData.clues[information].length; i++)
              {
                if(!docData.clues[information][i])
                {
                    docData.clues[information][i] = true;
                    break;
                }
              }
              transaction.update(docRef, docData);
            })
          } catch (e) {
            console.log("Failed to ask for clue");
          }
        })

        addEventListener("WonGame", (teamInformation) => {
          const docRef = doc(omegaZombiesDB, collection, document);
          try{
            runTransaction(omegaZombiesDB, async (transaction) =>{
              
              transaction.update(docRef, { gameWon: true });
            })
          } catch (e) {
            console.log("Failed to trigger won game");
          }
        });

        addEventListener("ReactOpenScoreScreen", () => {
          window.open("/omega-protocol-zombies-facilitatorless/" + collection + "/scores")
        })

        addEventListener("EndTimer", (timeString) => {
          var strings = timeString.split(",");
          finishTime = strings[2];

          if(finishTime === "finished")
          {
              getUTCTime(null, null, updateTeamFinishingTime);
          }
          else
          {
              updateTeamFinishingTime(omegaZombiesDB, collection, "Did not finish");
          }
        });

        addEventListener("SendTeamProgressUpdate", async (message) => {
          console.log(message);
        });

        addEventListener("UpdateLocks", async (property) => {
          const docRef = doc(omegaZombiesDB, collection, document);
          
          try {
            await runTransaction(omegaZombiesDB, async (transaction) => {
              let docData = (await transaction.get(docRef)).data();
              docData.locks[property] = true;
              transaction.update(docRef, docData);
            });
          } catch (e) {
            console.log("Transaction Failed:", e);
          }
        });

        addEventListener("UpdateChemicals", async (property) => {
          const docRef = doc(omegaZombiesDB, collection, document);
          try {
            await runTransaction(omegaZombiesDB, async (transaction) => {
              let docData = (await transaction.get(docRef)).data();

              docData.chemicals[property] = true;
              transaction.update(docRef, docData);
            });
          } catch (e) {
            console.log("Transaction Failed:", e);
          }
        });
    }
  },[unityProvider, unityIsReady]);

    return (
      <FullHeightDiv>
        <GlobalStyle backgroundColor={"black"} />
        {isMobile && 
        <CenterDiv>
          <WhiteP>Please use a desktop browser to play</WhiteP>
        </CenterDiv>}
        {!isMobile && !isLoaded && <LoadingDiv style={{backgroundImage: "url(https://cdn.moniker-virtual.com/omega-protocol-zombies/images/background.png)"}}>
          <LoadingP>Loading {(loadingProgression * 100).toFixed(0)}%</LoadingP>
        </LoadingDiv>}
        {!isMobile && <Unity unityProvider={unityProvider} style={{height:"100%", width:"100%", position:"absolute", visibility: isLoaded ? "visible" : "hidden"}}/>}
      </FullHeightDiv>
    )
  }

export default OmegaProtocolZombiesFacilitatorless

const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`;

const WhiteP = styled.h3`
  color: white;
`;

const FullHeightDiv = styled.div`
  height: 99vh;
`;

const LoadingP = styled.p`
  text-align: center;
  font-size: 40px;
  color: white;
  background-image: radial-gradient(hsla(267,10%,18%,0.99),hsla(267,10%,40%,0));
  vertical-align: middle;
`;

const LoadingDiv = styled.div`
  background-size: cover;
  height: 100%;
  display: inline-block;
  width: 100%;
  text-align: center;
  position: absolute;
  justify-content: center;
  vertical-align: middle;
  object-fit: fill;
`;