import { initializeApp } from "firebase/app"
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from 'uuid';

const firebaseApp = initializeApp({
    apiKey: "AIzaSyDeNgldjxzmq1WxOrnN4gqDeKf5dGR8hPU",
    authDomain: "moniker-halloween-2023.firebaseapp.com",
    projectId: "moniker-halloween-2023",
    storageBucket: "moniker-halloween-2023.appspot.com",
    messagingSenderId: "346295687396",
    appId: "1:346295687396:web:282ddbea7ba4fdf602f4f1",
    measurementId: "G-B13PEYFGPN"
}, "halloween-2023");

//firebase.firestore().settings({ experimentalForceLongPolling: true });

const storage = getStorage(firebaseApp);
const storageRef = ref;

export const generateGUID = uuidv4;
export const halloween2023DB = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);

export { storage, storageRef, uploadBytesResumable, getDownloadURL, deleteObject };