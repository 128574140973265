import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Unity, useUnityContext  } from "react-unity-webgl";
import styled, { createGlobalStyle } from 'styled-components'
import LoadingWheel from '../../assets/images/LoadingWheel.svg';
import { isMobile } from 'react-device-detect';
import { holidayHouseDB } from "../../utils/database/firebase-holiday-house";
import { deleptualAnalyticsDB } from "../../utils/database/firebase-deleptual-analytics";
import { doc, getDoc, runTransaction, onSnapshot } from "@firebase/firestore";
import { getUTCTimeV2 as getUTCTime, startGameV3 as startGame, listenToDocumentV3 as listenToDocument } from '../../utils/firestore-functions';
import { DateTime, Duration } from "luxon";
import { calculateScore } from "../holiday-house/holiday-house-config";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => (props.backgroundColor.toString())};
  }
`;

const HolidayHouse = (props) => {
    const [unityIsReady, setUnityIsReady] = useState(false);
    const [devicePixelRatio, setDevicePixelRatio] = useState(
      window.devicePixelRatio
    );
    /*
    const { unityProvider, sendMessage, addEventListener, isLoaded, loadingProgression } = useUnityContext ({
      loaderUrl: "https://deleptual-holiday-escape.s3.us-east-2.amazonaws.com/webgl.loader.js",
      dataUrl: "https://deleptual-holiday-escape.s3.us-east-2.amazonaws.com/webgl.data",
      frameworkUrl: "https://deleptual-holiday-escape.s3.us-east-2.amazonaws.com/webgl.framework.js",
      codeUrl: "https://deleptual-holiday-escape.s3.us-east-2.amazonaws.com/webgl.wasm",
    });
    */
    const { unityProvider, sendMessage, addEventListener, isLoaded, loadingProgression } = useUnityContext ({
      loaderUrl: "https://cdn.moniker-virtual.com/holiday-house/webgl.loader.js",
      dataUrl: "https://cdn.moniker-virtual.com/holiday-house/webgl.data",
      frameworkUrl: "https://cdn.moniker-virtual.com/holiday-house/webgl.framework.js",
      codeUrl: "https://cdn.moniker-virtual.com/holiday-house/webgl.wasm",
      streamingAssetsUrl: "https://cdn.moniker-virtual.com/holiday-house/StreamingAssets",
    });

    let { collection } = useParams();
    let { document } = useParams();

    /*
    const [unityProvider]= useState(new unityProvider({
      loaderUrl: "https://cdn.moniker-virtual.com/holiday-house/webgl.loader.js",
      dataUrl: "https://cdn.moniker-virtual.com/holiday-house/webgl.data",
      frameworkUrl: "https://cdn.moniker-virtual.com/holiday-house/webgl.framework.js",
      codeUrl: "https://cdn.moniker-virtual.com/holiday-house/webgl.wasm",
      streamingAssetsUrl: "https://cdn.moniker-virtual.com/holiday-house/StreamingAssets",
    }))
    */

    useEffect(() => {
      if (isLoaded){
        setUnityIsReady(true);
      }
    }, [isLoaded]);


    useEffect(
      function () {
        const updateDevicePixelRatio = function () {
          setDevicePixelRatio(window.devicePixelRatio);
        };
        const mediaMatcher = window.matchMedia(
          `screen and (resolution: ${devicePixelRatio}dppx)`
        );
        mediaMatcher.addEventListener("change", updateDevicePixelRatio);
        return function () {
          mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
        };
      },
      [devicePixelRatio]
    );

  useEffect(
    () => {
      async function AddUserInformation() {
        const docRef = doc(deleptualAnalyticsDB, "holiday-house/" + collection);
        try {
          await runTransaction(deleptualAnalyticsDB, async (transaction) => {
            let docData = (await transaction.get(docRef)).data();
            let data = {};
            data["time"] = DateTime.now().toUTC().toString();
            data["userAgent"] = window.navigator.userAgent;
            console.log(docData);
            if (docData === undefined){
              let userData = [];
              userData.push(data);
              transaction.set(docRef, {userData} );
            }
            else if (docData["userData"] === undefined){
              let userData = [];
              userData.push(data);
              transaction.update(docRef, {userData} );
            }
            else{
              docData["userData"].push(data);
              transaction.update(docRef, docData);
            }
          });
        } catch (e) {
          console.log("Failed to Add User Information", e);
        }
      }
      AddUserInformation();
    }, []
  );



    useEffect(
      () => {
        if (unityIsReady)
        {
          console.log("Unity is loaded");
          sendMessage("React Communicator", "ReactListener", "ReactLoaded");
        
          addEventListener("ReactGetURLInformation", () => {
          console.log("React Get Url Information being called");
          sendMessage(
            "React Communicator", 
            "ReactListener", 
            ("SetURLParameters" + "*" + collection + "*" + "gameSettings")
          );
        })

        addEventListener("ReactUpdateSyncable", async (information) => {
          let strings = information.split("*");
          var id = strings[0];
          var state = strings[1];
          var type = strings[2];
          const docRef = doc(holidayHouseDB, collection + "/" + document);
          try {
            await runTransaction(holidayHouseDB, async (transaction) => {
              let docData = (await transaction.get(docRef)).data();
              let index = docData.syncedObjects.findIndex(i => i.id === id)
              docData.syncedObjects[index].state = state;
              if (docData.syncedObjects[index].timestamp === "" || docData.syncedObjects[index].timestamp === undefined){
                docData.syncedObjects[index].timestamp = new Date();
              }
              transaction.update(docRef, docData);
            });
          } catch (e) {
            console.log("Transaction Failed:", e);
          }
        });

        addEventListener("ReactRecordPlay", async () => {
          const docRef = doc(deleptualAnalyticsDB, "holiday-house");
          try {
            await runTransaction(deleptualAnalyticsDB, async (transaction) => {
              let docData = (await transaction.get(docRef)).data();
              let d = { 
                timestamp: new Date(),
                company: "Moniker"
              }
              docData.plays.push(d);
              transaction.update(docRef, docData);
            });
          } catch (e) {
            console.log("Transaction Failed:", e);
          }
        });

        addEventListener("ReactActivateGame", async () => {
          const docRef = doc(holidayHouseDB, collection + "/gameSettings");
          try {
            await runTransaction(holidayHouseDB, async (transaction) => {
              let docData = (await transaction.get(docRef)).data();
              if (docData["startTime"] === ""){
                getUTCTime(holidayHouseDB, collection, startGame);
                if (docData["name"] !== ""){
                  const docRefAnalytics = doc(deleptualAnalyticsDB, "holiday-house/" + collection);
                    try {
                      await runTransaction(deleptualAnalyticsDB, async (transaction) => {
                        let docDataAnalytics = (await transaction.get(docRefAnalytics)).data();
                        let gameName = {};
                        gameName["name"] = docData["name"];
                        if (docDataAnalytics === undefined){
                          transaction.set(docRefAnalytics, gameName );
                        }
                        else{
                          transaction.update(docRefAnalytics, gameName);
                        }
                      });
                    } catch (e) {
                      console.log("Failed to Add User Information", e);
                  }
                }
              }
            });
          } catch (e) {
            console.log("Failed to record start time.", e);
          }

        });

        addEventListener("ReactFinishGame", async (information) => {
          console.log("ReactFinishGame being called");
          var strings = information.split("*");
          var finishedOnTime = strings[0];
          var timeStamp = strings[1];
          let startTime = "";
          const docRef = doc(holidayHouseDB, collection + "/" + document);
          const gameRef = doc(holidayHouseDB, collection + "/gameSettings");
          try {
            await runTransaction(holidayHouseDB, async (transaction) => {
              let docData = (await transaction.get(docRef)).data();
              let gameData = (await transaction.get(gameRef)).data();
              
              if(docData["finishedGame"]){
                console.log("Game has already finished, returning");
                return;}

              if(finishedOnTime === "False"){
                console.log("Did not finish on time.");
                docData["finishingTime"] = "Did Not Finish";
              }
              else{
                console.log("Did Finish on time.");
                let t = "";
                await fetch("https://us-central1-escape-room-1980b.cloudfunctions.net/getUTCTime", {
                  "method": "GET",
                  "headers": {
                  }
                })
                .then(response => response.json())
                .then(response => {
                    t = response.result.toString();
                    console.log("Time is: " + t);
                })
                .catch(err => { console.log(err); 
                });
                //docData["finishingTime"] = timeStamp.toString();
                docData["finishingTime"] = t;
                console.log(t);
              }

              if(docData["score"] === ""){
                docData["score"] = calculateScore(docData, gameData.startTime);
              }

              docData["finishedGame"] = true;
              
              transaction.update(docRef, docData);
            });
          } catch (e) {
            console.log("Failed to update end time:", e);
          }
        });

        addEventListener("ReactGetServerUTC", () => {
          console.log("React Get Server UTC being called");
        fetch("https://us-central1-escape-room-1980b.cloudfunctions.net/getUTCTime", {
              "method": "GET",
              "headers": {
              }
            })
            .then(response => response.json())
            .then(response => {
              sendMessage(
                "Holiday House Specific", 
                "ReturnServerUTC", 
                response.result.toString()
              );
            })
            .catch(err => { console.log(err); 
            });
      });

      addEventListener("ReactGetEnvironment", () => {
        console.log("React Get Url Environment being called");
        var currentEnvironment = getEnvironment();
        sendMessage(
          "React Communicator", 
          "ReactListener", 
          ("SetEnvironment" + "*" + currentEnvironment)
        );
      })

      const getEnvironment = () => {
        return "production";
      };

      addEventListener("ReactScoreScreen", () => {
        window.open("https://moniker-virtual.com/halloween-2023/" + collection + "/scores");
      });

      addEventListener("ReactSendString", async (information) => {
        var docRef = doc(holidayHouseDB, collection + "/" + document);
        var strings = information.split("*");
        var puzzleDetails = strings[0].split("'");
        if(puzzleDetails[0] === "ouija"){
          var letter = puzzleDetails[1];
          var userId = puzzleDetails[2];
          var color =  puzzleDetails[3];
         

          try {
            await runTransaction(holidayHouseDB, async (transaction) => {
              let docData = (await transaction.get(docRef)).data();
              docData["ouijaChar"] = letter;
              docData["recentId"] = userId;
              docData["color"] = color;

              transaction.update(docRef, docData);
            });
          } catch (e) {
            console.log("Send string has failed:", e);
          }
        }

        if(puzzleDetails[0] === "clue"){
          try {
            await runTransaction(holidayHouseDB, async (transaction) => {
              let docData = (await transaction.get(docRef)).data();
              docData["clues"][puzzleDetails[1]]++;



              transaction.update(docRef, docData);
            });
          } catch (e) {
            console.log("Send string has failed:", e);
          }
        }
      })

      addEventListener("ReactListenToTeam", () => {
        console.log("REACT FUNCTION: ReactListenToTeam");
        listenToDocument(holidayHouseDB, collection, document, SendTeamData, SendError);
      });

      const SendTeamData = (data) => {
        console.log("React Send Team Data being called");
        sendMessage(
          "React Communicator", 
          "ReactListener", 
          ("ReturnTeamData*" + JSON.stringify(data))
        );
      }

      addEventListener("ReactListenToGame", () => {
        console.log("REACT FUNCTION: ReactListenToGame");
        listenToDocument(holidayHouseDB, collection, "gameSettings", SendGameData, SendError);
      });

      const SendGameData =(data) => {
        console.log("React Send Game Data being called");
        sendMessage(
          "React Communicator",  
          "ReactListener", 
          ("ReturnGameData*" + JSON.stringify(data))
        );
      }

      const SendError = (error) => {
        console.log("React End Error being called");
        sendMessage(
          "React Communicator",
          "ThrowError",
          "Could not find game data"
        );
      }
      /*
      addEventListener("progress", progress => {
          if (progress >= 1){
            setLoading(false)
          }
            setProgression(progress);
      });
      */
    }
      },[unityProvider, unityIsReady],
    );

   return ( 
   <FullHeightDiv>
    <GlobalStyle backgroundColor={"black"} />
    {isMobile && 
    <CenterDiv>
      <WhiteP>Please use a desktop browser to play</WhiteP>
    </CenterDiv>}
    {!isMobile && !isLoaded && <LoadingDiv style={{backgroundImage: "url(https://monikerspace.nyc3.cdn.digitaloceanspaces.com/holiday-house/images/background.png)"}}>
      <LoadingP>Loading {(loadingProgression * 100).toFixed(0)}%</LoadingP>
      <LoadingWheelDiv style={{backgroundImage: "url(" + LoadingWheel + ")"}}></LoadingWheelDiv>
    </LoadingDiv>}
    {!isMobile && <Unity devicePixelRatio={devicePixelRatio} unityProvider={unityProvider} style={{width:"100%", height:"100%", position:"absolute", visibility: isLoaded ? "visible" : "hidden"}}/>}
  </FullHeightDiv>);
}
export default HolidayHouse;

const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`;

const WhiteP = styled.h3`
  color: white;
`;

const FullHeightDiv = styled.div`
  height: 99vh;
`;

const LoadingP = styled.p`
  text-align: center;
  font-size: 40px;
  color: white;
  background-image: radial-gradient(hsla(267,10%,18%,0.99),hsla(267,10%,40%,0));
  vertical-align: middle;
`;

const LoadingWheelDiv = styled.div`
  display: inline-block;
  text-align: center;
  height: 16px;
  width: 128px;
  z-index: 1;
  vertical-align: middle;
`;

const LoadingDiv = styled.div`
  background-size: cover;
  height: 100%;
  display: inline-block;
  width: 100%;
  text-align: center;
  position: absolute;
  justify-content: center;
  vertical-align: middle;
  object-fit: fill;
`;