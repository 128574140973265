import React from "react";
import styled from 'styled-components'
import { Button } from 'reactstrap';
import { db } from "../firebase";

class OmegaProtocolTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        teamID: this.props.id,
        gameName: this.props.gameName
    };
  };

  deleteTeam(){
    db.collection(this.state.gameName).doc(this.state.teamID).delete().then(function() {
        console.log("Document successfully deleted!");
    }).catch(function(error) {
        console.error("Error removing document: ", error);
    });
  };

  resetTeam(){
    console.log("Resetting Team" + this.state.teamID);
    db.collection(this.state.gameName)
    .doc(this.state.teamID).update({
        locks: { 
            basementDoor : false, 
            cabinConsole : false, 
            cabinDoor : false, 
            cabinFridge : false, 
            houseDoor : false,
            labFridge : false, 
            librarySafe : false, 
            masterBedroomDrawer : false,
            bathroomMeds : false,
            outsideCabin : false,
        },
        chemicals: {
            chemical1 : false, 
            chemical2 : false, 
            chemical3 : false, 
            chemical4 : false, 
            chemical5 : false
        },
        hasStarted: false,
        startTime: "",
        endTime: "",
        gameWon: false
    })
    .then(() => {
        console.log("Team successfully reset!");
    })
    .catch(error => {
        console.error("Error resetting team: ", error);
    });
  };

  resetEndTime(){
    console.log("Resetting EndTime for: " + this.state.teamID);
    db.collection(this.state.gameName)
    .doc(this.state.teamID).update({
        endTime: "",
    })
    .then(() => {
        console.log("End time successfully reset!");
    })
    .catch(error => {
        console.error("Error resetting end time: ", error);
    });
  };

  newPassword(){
    var pass = "";
    for (var i = 0; i < 4; i++){
        pass += (Math.floor(Math.random() * 9) + 1).toString();
    }
    db.collection(this.state.gameName)
    .doc(this.state.teamID).update({
        password: pass
    })
  };

  convertToMinutes(){
    Math.floor((Date.parse(this.props.team.endTime.toString()) - Date.parse(this.props.team.startTime.toString()))/60000)
  }

  convertToElapsedTime(){
    var totalSeconds = Math.floor((Date.parse(this.props.team.endTime.toString()) - Date.parse(this.props.team.startTime.toString()))/1000);
    var minutes = Math.floor(totalSeconds / 60);
    var seconds = totalSeconds - (minutes * 60);
    var s = "";
    if (minutes < 10){
        s += "0";
    }
    s += minutes.toString();
    s += ":";
    if (seconds < 10){
        s += "0";
    }
    s += seconds.toString();
    return s;
  }

  render() {
    var elapsed = <td></td>;
    if (this.props.team.endTime !== "" && this.props.team.endTime !== "DNF"){
        elapsed = <td>Elapsed Time: {this.convertToElapsedTime()}</td>
    }
    return(
        <OmegaProtocolTeamDiv>
            <table>
                <tbody>
                <tr>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Team Number: {this.props.id}</td>
                                </tr>
                                <tr>
                                    <td>Password: {this.props.team.password}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td>Chemicals
                        <table>
                            <tbody>
                                <tr>
                                    <td>Chemical1: {this.props.team.chemicals.chemical1.toString()}</td>
                                </tr>
                                <tr>
                                    <td>Chemical2: {this.props.team.chemicals.chemical2.toString()}</td>
                                </tr>
                                <tr>
                                    <td>Chemical3: {this.props.team.chemicals.chemical3.toString()}</td>
                                </tr>
                                <tr>
                                    <td>Chemical4: {this.props.team.chemicals.chemical4.toString()}</td>
                                </tr>
                                <tr>
                                    <td>Chemical5: {this.props.team.chemicals.chemical5.toString()}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td>Locks
                        <table>
                            <tbody>
                                <tr>
                                    <td>Basement Door: {this.props.team.locks.basementDoor.toString()}</td>
                                </tr>
                                <tr>
                                    <td>Cabin Console: {this.props.team.locks.cabinConsole.toString()}</td>
                                </tr>
                                <tr>
                                    <td>Cabin Door: {this.props.team.locks.cabinDoor.toString()}</td>
                                </tr>
                                <tr>
                                    <td>Cabin Fridge: {this.props.team.locks.cabinFridge.toString()}</td>
                                </tr>
                                <tr>
                                    <td>House Door: {this.props.team.locks.houseDoor.toString()}</td>
                                </tr>
                                <tr>
                                    <td>Lab Fridge: {this.props.team.locks.labFridge.toString()}</td>
                                </tr>
                                <tr>
                                    <td>Library Safe: {this.props.team.locks.librarySafe.toString()}</td>
                                </tr>
                                <tr>
                                    <td>Master Bedroom Drawer: {this.props.team.locks.masterBedroomDrawer.toString()}</td>
                                </tr>
                                <tr>
                                    <td>Bathroom Meds: {this.props.team.locks.bathroomMeds.toString()}</td>
                                </tr>
                                <tr>
                                    <td>Outside Cabin: {this.props.team.locks.outsideCabin.toString()}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td>Info
                        <table>
                            <tbody>
                                <tr>
                                    <td>Has the timer started: {this.props.team.hasStarted.toString()}</td>
                                </tr>
                                <tr>
                                    <td>Start Time (UTC): {this.props.team.startTime.toString()}</td>
                                </tr>
                                <tr>
                                    <td>End Time (UTC): {this.props.team.endTime.toString()}</td>
                                </tr>
                                <tr>
                                    {elapsed}
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <Button onClick={this.resetTeam.bind(this)}>Reset Team</Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Button onClick={this.resetEndTime.bind(this)}>Reset Endtime</Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <td>-----------------------------------------------------------------
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <Button onClick={this.newPassword.bind(this)}>New Password</Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
        </OmegaProtocolTeamDiv>
    )
  }
};

export default OmegaProtocolTeam

const OmegaProtocolTeamDiv = styled.div`
    border: 0.5px groove black;
    border-radius: 5px;
`;