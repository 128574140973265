import { Button, Input, Container, Col, Row } from 'reactstrap';
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteGameV2, listenToDocument, getUTCTimeV2, startGameV2 } from "../../../../utils/firestore-functions";
import { artemisDB, generateGUID } from "../../../../utils/database/firebase-artemis";
import { resetGameSettings, newTeamSettings } from "../../../../utils/artemis-specific";
import Timer from "../../../../utils/timer-v2"
import firebase from 'firebase/compat/app';
import { ArtemisInstanceTeamAdmin } from "../artemis/artemis-instance-team-admin";

export const ArtemisInstanceAdmin = (props) => {
    
    const [gameDetails, setGameDetails] = useState({});

    useEffect(() => {
        var unsubscribe = listenToDocument(artemisDB, "artemis" + props.instance[1], "gameSettings", setGameDetails);
        return unsubscribe;
    }, [props.instance]);

    return(
        <React.Fragment>
            <Container>
                <BreadcrumbRow align-items-center>
                    <Col style={{color:"white", alignItems:"center", display:"flex"}}>
                        <StyleH4>{props.gameName} {"/"} {props.instance[1]}</StyleH4>
                    </Col>
                    <Col style={{textAlign:"right", justifyContent:"flex-end"}}>
                        <Link to={"/artemis/" + props.instance[1] + ""}>
                            <Button color="primary">Score Screen</Button>
                        </Link>
                    </Col>
                </BreadcrumbRow>
            </Container>
            <GameContainer>
                <Row>
                    <Col xs="6" style={{color:"white", display:"flex"}}>
                        {(Object.entries(gameDetails).length > 0) && <div>
                            Has Started: {gameDetails.hasGameStarted.toString()}
                            <br></br>
                            {gameDetails.hasGameStarted && <Timer startTime={gameDetails.startTime} gameLength={1800000}></Timer>}
                            Start Time: {gameDetails.hasGameStarted && (new Date(parseInt(gameDetails.startTime))).toString()}
                            {Object.entries(gameDetails.teamScores).length > 0 && Object.entries(gameDetails.teamScores).map(score => {
                                return (
                                    <div key={score[0]}>
                                        Team: {score[0]} Score: {score[1].score} {" "} Strategy: {score[1].strategy}
                                </div>)
                            })}
                        </div>}
                    </Col>
                </Row>
                <Row>
                </Row>
            </GameContainer>
        </React.Fragment>
    )
}

export default ArtemisInstanceAdmin;

const StyleH4 = styled.h4`
    margin-top: auto;
    margin-bottom: auto;
`;

const StyledInput = styled(Input)`
    width: auto;
    margin-left: 8px;
`;

const BreadcrumbRow = styled(Row)`
    border-color: black;
    border-style: solid;
    border-radius: 10px;
    color: white;
    background-color: rgba(0,0,0,0.7);
    padding: 5px;
    margin-bottom: 10px;
    align-content: center;
`;

const GameContainer = styled(Container)`
    border-color: black;
    border-style: solid;
    border-radius: 10px;
    color: white;
    background-color: rgba(0,0,0,0.7);
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 10px;
`;

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;
