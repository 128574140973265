import { Button, Container, Row, Col, InputGroup, InputGroupAddon, Input } from "reactstrap";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FinalVerdictFacilitatorPlayer from "../final-verdict/final-verdict-facilitator-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addPlayer, getTeam } from "../../utils/firestore-functions";

const inputTrigger = (gameName, teamName, playerName, callback, inputCallback) => {
    inputCallback("");
    addPlayer(gameName, teamName, playerName, callback);
}

const FinalVerdictFacilitatorTeam = (props) => {
    const [team, setTeam] = useState({});
    const [players, setPlayers] = useState({});
    const [playerNameInput, setPlayerNameInput] = useState("");
    const [playersToggle, setPlayersToggle] = useState(false);
    const [addPlayerMessage, setAddPlayerMessage] = useState("");
    
    useEffect(() => {
        var unsubscribe = getTeam(props.gameName, props.teamName, setTeam, setPlayers);
        return unsubscribe;
    }, []);
    
    const togglePlayers = () => setPlayersToggle(!playersToggle);
    
    function handleKeyDown(event){
        if (event.keyCode === 13){
            inputTrigger(props.gameName, props.teamName, playerNameInput, setAddPlayerMessage, setPlayerNameInput);
        }
    }

    return(
        <TeamPanel>
            <Container>
                <Row>
                    <Col xs="3">
                        <p>Team Name: {props.teamName}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs="3">
                        <InputGroup style={{"width":"100%"}}>
                            <Input onKeyDown={handleKeyDown} value={playerNameInput} placeholder={"new player name"} onChange={e => setPlayerNameInput(e.target.value)}>
                            </Input>
                            <InputGroupAddon addonType="append">
                                <Button color="primary" onClick={() => inputTrigger(props.gameName, props.teamName, playerNameInput, setAddPlayerMessage, setPlayerNameInput)}>
                                    <FAIcon icon={["fas", "user-plus"]}></FAIcon>
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                    <Col>
                        <p>{addPlayerMessage}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button outline color="primary" onClick={() => togglePlayers()}>
                            {playersToggle ? <FAIconButton icon={["fas", "chevron-up"]}></FAIconButton> : <FAIconButton icon={["fas", "chevron-down"]}></FAIconButton>}
                            PLAYERS - {Object.keys(players).length}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {playersToggle && Object.keys(players).length > 0 && <PlayerPanel>
                            {Object.entries(players).sort().map((p) => (
                                <FinalVerdictFacilitatorPlayer key={p[0]} gameName={props.gameName} teamName={props.teamName} playerName={p[0]} passcode={p[1].passcode} vote={p[1].vote}></FinalVerdictFacilitatorPlayer>
                            ))}
                        </PlayerPanel>}
                    </Col>
                </Row>
            </Container>
        </TeamPanel>
    )
}

const FAIcon = styled(FontAwesomeIcon)`
    
`;

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
`;

const TeamPanel = styled.div`
    margin:10px;
`;

const PlayerPanel = styled.div`
    
`;

export default FinalVerdictFacilitatorTeam