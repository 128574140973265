import React, { useState, useEffect } from "react";
import FinalVerdictAdminGame from "../final-verdict/final-verdict-admin-game";
import { getGameNames } from "../../utils/firestore-functions";

const FinalVerdictFacilitatorGames = () => {
    const [gameNames, setGameNames] = useState([]);

    useEffect(() => {
        var unsubscribe = getGameNames(setGameNames);
        return unsubscribe;
    }, []);

    return(
        <React.Fragment>
            {gameNames && gameNames.map(gameName => (
                <FinalVerdictAdminGame key={gameName} gameName={gameName}></FinalVerdictAdminGame>
            ))}
        </React.Fragment>
    )
}

export default FinalVerdictFacilitatorGames