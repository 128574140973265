import React from "react";
import styled from "styled-components";

export const AdminGameInstanceButton = (props) => {

    if (props.selected === true) {
        return(
            <SelectedGameDiv>
                {props.instanceObject[1]}
            </SelectedGameDiv>
        );
    }
    else{
        return(
            <GameDiv>
                {props.instanceObject[1]}
            </GameDiv>
        );
    }
}

export default AdminGameInstanceButton;

const GameDiv = styled.div`
    height: 50px;
    width: 90%;
    background-color: lightCoral;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {   
        transform: translateX(8px);
        font-weight: bold;
        border-radius: 8px 0px 0px 8px;
    }
    margin:auto;
    margin-bottom: 5px;
    border-radius: 8px;
`;

const SelectedGameDiv = styled.div`
    height: 50px;
    width: 90%;
    background-color: rgba(0,180,0,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    font-weight: bold;
    border-radius: 8px 0px 0px 8px;
    margin-bottom: 5px;
`;
