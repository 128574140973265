import { Button, Container, Row, Col, InputGroup, InputGroupAddon, Input } from "reactstrap";
import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { renamePlayer } from "../../utils/firestore-functions";

const inputTrigger = (gameName, teamName, playerName, passcode, input, callback, inputCallback) => {
    inputCallback("");
    renamePlayer(gameName, teamName, playerName, passcode, input, callback);
}

export const FinalVerdictAdminPlayer = (props) => {
    const [input, setInput] = useState("");
    const [renameMessage, setRenameMessage] = useState("");

    function handleKeyDown(event) {
        if (event.keyCode === 13){
            inputTrigger(props.gameName, props.teamName, props.playerName, props.passcode, input, setRenameMessage, setInput);
        }
    }

    return(
        <PlayerContainer>
            <Row>
                <Col xs="1">
                    <p>Name:</p>
                </Col>
                <Col xs="1">
                    <p>{props.playerName}</p>
                </Col>
                <Col xs="3">
                    <InputGroup style={{"width":"100%"}}>
                        <Input onKeyDown={handleKeyDown} value={input} placeholder={"edit player name"} onChange={e => setInput(e.target.value)}>
                        </Input>
                        <InputGroupAddon addonType="append">
                            <Button color="primary" onClick={() => inputTrigger(props.gameName, props.teamName, props.playerName, props.passcode, input, setRenameMessage, setInput)}>
                                <FAIcon icon={["fas", "edit"]}></FAIcon>
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
                <Col xs="3">
                    <ErrorP>{renameMessage}</ErrorP>
                </Col>
            </Row>
        </PlayerContainer>
    )
}
const ErrorP = styled.p`
    color: red;
`;

const FAIcon = styled(FontAwesomeIcon)`
    
`;

const PlayerContainer = styled(Container)`
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    margin:10px;
    padding:5px;
    box-shadow: 5px 5px #888888;
`;

export default FinalVerdictAdminPlayer