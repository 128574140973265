import { initializeApp } from "firebase/app"
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from 'uuid';

const firebaseApp = initializeApp({
    apiKey: "AIzaSyCXLsZoT1XGE4jQGeA_BrkFfG9zWQfQH80",
    authDomain: "moniker-pirate-ship.firebaseapp.com",
    projectId: "moniker-pirate-ship",
    storageBucket: "moniker-pirate-ship.appspot.com",
    messagingSenderId: "353662682147",
    appId: "1:353662682147:web:e660004351b25042815d96",
    measurementId: "G-X436D5029D"
}, "pirate-ship");

//firebase.firestore().settings({ experimentalForceLongPolling: true });

const storage = getStorage(firebaseApp);
const storageRef = ref;

export const generateGUID = uuidv4;
export const pirateshipDB = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);

export { storage, storageRef, uploadBytesResumable, getDownloadURL, deleteObject };