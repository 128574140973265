import React from "react";
import styled from "styled-components";

export const TeamDetailItem = (props) => {

    const displayItems = () => {
        return props.items.map((item, index) => {
            return (
                <StyledP key={index}>{item.id} - {item.state} {item.timestamp !== undefined ? item.timestamp.toDate().toLocaleTimeString('it-IT') : ""}</StyledP>
            );}
        );
    }

    return (
        <FlexDiv>
            {displayItems()}
        </FlexDiv>
    )
}

export default TeamDetailItem;

const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const StyledP = styled.p`
    margin: 0px;
`;
