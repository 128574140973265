import React, { useState, useEffect } from "react";
import { Row, Col, Container, Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import styled from "styled-components";
import { Colors, 
        BoldLabel, 
        Spacer, 
        PrimaryButton,
        UnderlineButton,
        transportationOptions,  
        currencyOptions,
        DangerButton} from "../../../../utils/budget-tool-specific";
import CostSummary from "./cost-summary-output";
import { validateExperienceForm } from "../../../budget/common/helper";
import { listenToDocumentProperty, updateDocumentProperty, deleteNestedDocumentProperty, deleteNestedDocumentProperty3 } from "../../../../utils/firestore-functions";
import { GetDB, generateGUID } from "../../../../utils/database/firebase-budget-tool";
import MiscellaneousInputs from "../../../budget-app/experiences/experience-form/miscellaneous-inputs";
import FormModals from "./form-modals";
import FormInputExperience from "../../budget-tool/input-components/form-input-experience";
import FormInputExperienceMulti from "../../budget-tool/input-components/form-input-exp-multi";
import FormInputExchange from "../../budget-tool/input-components/form-input-exp-exchange";
import FormInputPP from "../../budget-tool/input-components/form-input-exp-pp";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Item } from "./draggable";
import FormInput from "../../budget-tool/input-components/form-input";
import firebase from "firebase/compat/app";

const ExperienceInputFormTab = (props) => {
    const [costInputOpen, setCostInputOpen] = useState(true);

    const budgetDB = GetDB();

    const [expData, setExpData] = useState(
        {   
        title: {value: props.experience[1].title, invalid: false, required: true},
        index: props.experience[1].index ? props.experience[1].index : 0,
        notes: props.experience[1].notes ? props.experience[1].notes : "",
        destCurrency: {value: props.experience[1].destCurrency, invalid: false, required: true},
        base: {value: props.experience[1].base, invalid: false, required: true},
        convert: {value: props.experience[1].convert, invalid: false, required: true},
            location: {value: props.experience[1].location, invalid: false, required: true},
            accommodationCost: {value: props.experience[1].accommodationCost, invalid: false, required: true},
            flightCost: {value: props.experience[1].flightCost, invalid: false, required: true},
            costPerTransportation: {value: props.experience[1].costPerTransportation, invalid: false, required: true}, 
            transportation: {value: props.experience[1].transportation, invalid: false, required: true},
            costPerActivity: {value: props.experience[1].costPerActivity, invalid: false, required: true}, 
            numActivities: {value: props.experience[1].numActivities, invalid: false, required: true},
            costPerBreakfast: {value: props.experience[1].costPerBreakfast, invalid: false, required: true}, 
            numBreakfast: {value: props.experience[1].numBreakfast, invalid: false, required: true},
            costPerLunch: {value: props.experience[1].costPerLunch, invalid: false, required: true}, 
            numLunch: {value: props.experience[1].numLunch, invalid: false, required: true}, 
            costPerDinner: {value: props.experience[1].costPerDinner, invalid: false, required: true}, 
            numDinner: {value: props.experience[1].numDinner, invalid: false, required: true},
            costPerAlcohol: {value: props.experience[1].costPerAlcohol, invalid: false, required: true}, 
            numAlcohol: {value: props.experience[1].numAlcohol, invalid: false, required: true}, 
            roomRental: {value: props.experience[1].roomRental, invalid: false, required: true},
            av: {value: props.experience[1].av, invalid: false, required: true},  
            setupFee: {value: props.experience[1].setupFee, invalid: false, required: true}, 
            numMeetings: {value: props.experience[1].numMeetings, invalid: false, required: true}, 
            costPerCoffee: {value: props.experience[1].costPerCoffee, invalid: false, required: true}, 
            numCoffee: {value: props.experience[1].numCoffee, invalid: false, required: true}, 
            miscellaneousCosts: {value: [], invalid: false, required: true},           
        })

    const dragEnd = (result) => {
        if (result.destination === undefined || result.destination === null){
            return;
        }
        const { destination, source, draggableId } = result;
        if (destination.droppableId === source.droppableId && destination.index === source.index){
            return;
        }
        let newData = {...expData};
        let newArray = Array.from(newData.miscellaneousCosts.value);
        let obj = newArray[source.index];
        newArray.splice(source.index, 1);
        newArray.splice(destination.index, 0, obj);
        newData.miscellaneousCosts.value = newArray;
        setExpData(newData);
    }

    useEffect(() => {
        var unsubscribe = listenToDocumentProperty(budgetDB, "experienceGuides", props.guideid, "experiences", setMiscCost);
        return unsubscribe;
    },[]);

    const setMiscCost = (property) => {
        let newData = {...expData};

        if (property[props.experience[1].id]) {
            newData.miscellaneousCosts.value = property[props.experience[1].id]["miscellaneousCosts"];
            newData.notes = property[props.experience[1].id]["notes"];
            setExpData(newData);
        }
    }

    let data = {
        title: expData.title.value,
        location: expData.location.value,
        destCurrency: expData.destCurrency.value,
        base: expData.base.value,
        convert: expData.convert.value,
        timeCreated: props.experience[1].timeCreated,
        id: props.experience[1].id,
        accommodationCost: expData.accommodationCost.value,
        flightCost: expData.flightCost.value,
        costPerTransportation: expData.costPerTransportation.value,
        transportation: expData.transportation.value,
        costPerActivity: expData.costPerActivity.value,
        numActivities: expData.numActivities.value,
        costPerBreakfast: expData.costPerBreakfast.value,
        numBreakfast: expData.numBreakfast.value,
        costPerLunch: expData.costPerLunch.value,
        numLunch: expData.numLunch.value,
        costPerDinner: expData.costPerDinner.value,
        numDinner: expData.numDinner.value,
        costPerCoffee: expData.costPerCoffee.value,
        numCoffee: expData.numCoffee.value,
        costPerAlcohol: expData.costPerAlcohol.value,
        numAlcohol: expData.numAlcohol.value,
        setupFee: expData.setupFee.value,
        roomRental: expData.roomRental.value,
        av: expData.av.value,
        numMeetings: expData.numMeetings.value,
        miscellaneousCosts: expData.miscellaneousCosts.value,
        index: expData.index ? expData.index : 0,
        notes: expData.notes ? expData.notes : "",
    }

    const [displaySummary, setDisplaySummary] = useState(props.client);
    const [savedModal, setSavedModal] = useState(false);
    const [failedModal, setFailedModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteID, setDeleteID] = useState("");

    const [refresh, setRefresh] = useState(true);

    const toggleSavedModal = ( ) => { console.log("success"); setSavedModal(!savedModal) };
    const toggleFailedModal = ( ) => { setFailedModal(!failedModal) };

    const saveRetreatCosts = () => {

        if (validateExperienceForm( )) {
            //update document property
            updateDocumentProperty( budgetDB, "experienceGuides", props.guideid, "experiences."+ props.experience[1].id, data, toggleSavedModal, toggleFailedModal);
            props.updateGuideDetails(props.guideid);
        } else {
            toggleFailedModal();
        }
    }

    const addMiscellaneousCost = () => {
        let newData = {
            title: "",
            cost: 0,
            pp: "per person", 
            id: generateGUID(),
        }

        let newMiscCost = [...expData.miscellaneousCosts.value];
        newMiscCost.push(newData);

        let newExpData = {...expData};
        newExpData.miscellaneousCosts.value = newMiscCost;
        setExpData(newExpData);
    
    }

    const deleteMiscCost = (miscID) => {
        let newExpData = {...expData};
        newExpData.miscellaneousCosts.value = newExpData.miscellaneousCosts.value.filter((key) => key.id !== miscID);
        setExpData(newExpData);
    };

    const handleMiscellaneousChange = (value, id, type) => {

        let miscCost = [...expData.miscellaneousCosts.value];
        let index = miscCost.findIndex(p => p.id == id);
        if (type === "title") {
            miscCost[index].title = value;
        } else if (type === "cost") {
            miscCost[index].cost = value;
        } else if (type === "pp") {
            if (value === "per person") {
                miscCost[index].pp = "per trip";
            } else {
                miscCost[index].pp = "per person";
            }
        }

        let newExpData = {...expData};
        newExpData.miscellaneousCosts.value = miscCost;
        setExpData(newExpData);
    }

    const deleteExperience = (id) => {      
        deleteNestedDocumentProperty(budgetDB, "experienceGuides", props.guideid,  "experiences", props.experience[1].id);
        setDeleteModal(false);
        props.setTabKey(0);
    }; 

    const cloneVacation = () => {
        let newData = {...props.experience[1]};
        newData.title = newData.title + " (copy)";
        newData.index = newData.index + 1;
        createNewExperience(newData);
    }

    const createNewExperience = (experienceData) => {
        const timeStamp = firebase.firestore.Timestamp.now()
        const newID = generateGUID();
        experienceData.timeCreated = timeStamp;
        experienceData.id = newID;
        updateDocumentProperty(budgetDB, "experienceGuides", props.guideid, "experiences." + newID, experienceData);
    }

    const confirmDelete = (id) => {
        setDeleteModal(true);
        setDeleteID(id);
    }


    return (
        <React.Fragment>
             <Modal isOpen={savedModal} toggle={() => {setSavedModal(!savedModal)}}>
                <ModalHeader toggle={() => {setSavedModal(!savedModal)}}>Saved</ModalHeader>
                <ModalBody>
                Successfully saved to the experience guide database.
                </ModalBody>
                <ModalFooter>
                <PrimaryButton onClick={() => {setSavedModal(!savedModal)}}>
                    Okay
                </PrimaryButton>
                </ModalFooter>
            </Modal>
            <FormModals savedModal={savedModal}
                        setSavedModal={setSavedModal}
                        failedModal={failedModal}
                        setFailedModal={setFailedModal}
                        deleteModal={deleteModal}
                        setDeleteModal={setDeleteModal}
                        deleteExperience={deleteExperience}
                        deleteID={deleteID}/> 
            <StyledContainer id="cost-output-container">
            {!props.client && 
            <React.Fragment>
                <Collapse isOpen={costInputOpen}>
                <Row>
                    <Col>
                        <FormInputExperience title="Vacation Title" 
                                            id="title" data={expData} 
                                            setData={(x) => setExpData(x)} 
                                            type="text"/>
                    </Col>
                    <Col lg="4" style={{display:"flex"}}>
                        <FormInput title="Index" 
                            id="index"
                            type="number"
                            data={expData}
                            value={expData.index ? expData.index : 0}
                            setData={(x) => setExpData(x)} 
                            style={{width:"20%"}}/>
                        <PrimaryButton style={{marginTop: "25px", height:"48%"}} onClick={() => cloneVacation(props.experience[1].id)}>Clone</PrimaryButton>
                        <DangerButton style={{marginTop: "25px", height:"48%"}} onClick={() => confirmDelete(props.experience[1].id)}>Delete</DangerButton>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <FormInputExperience title="Location" 
                                            id="location" data={expData} 
                                            setData={(x) => setExpData(x)} 
                                            type="text"/>
                    </Col>
                    <Col>
                    <FormInputExperience title="Destination Currency" 
                                            id="destCurrency" data={expData} 
                                            setData={(x) => setExpData(x)} 
                                            type="select"
                                            options={currencyOptions()}/>
                    </Col>
                    <Col>
                    {expData.destCurrency.value !== props.guideData.currency.value && 
                    <FormInputExchange title="Exchange Rate" 
                                            baseid="base"
                                            convertid="convert"
                                            data={expData} 
                                            setData={(x) => setExpData(x)} 
                                            type="number"
                                            base={expData.base}
                                            convert={expData.convert}
                                            destCurrency={expData.destCurrency}
                                            clientCurrency={props.guideData.currency}
                                            options={currencyOptions()}/>
                    }
                    
                    </Col>
                </Row>
                <Row>
                    <Col><BoldLabel style={{color: Colors.primary}}>General Costs Per Attendee</BoldLabel></Col>
                </Row>
                <Row id="first-section">
                    <Col>
                        <FormInputExperience title="Accommodation Cost" 
                                                id="accommodationCost" data={expData} 
                                                setData={(x) => setExpData(x)} 
                                                currency={expData.destCurrency.value ? expData.destCurrency.value.slice(3): ""}
                                                type="number"/>
                    </Col>
                    <Col>  
                        <FormInputExperience title="Flight Cost"    
                                                id="flightCost" data={expData} 
                                                setData={(x) => setExpData(x)} 
                                                currency={expData.destCurrency.value ? expData.destCurrency.value.slice(3): ""}
                                                type="number"/>
                    </Col>
                </Row>

        
                <Row id="second-section">
                    <Col>
                        <FormInputExperience title="Cost Per Activity"    
                                                id="costPerActivity" data={expData} 
                                                setData={(x) => setExpData(x)} 
                                                currency={expData.destCurrency.value ? expData.destCurrency.value.slice(3): ""}
                                                type="number"/>
                    </Col>
                    <Col>
                        <FormInputExperience title="Number of Activities"    
                                                id="numActivities" data={expData} 
                                                setData={(x) => setExpData(x)} 
                                                type="number"/>
                    </Col>  
                    <Col>
                    <FormInputExperience title="Cost Per Transportation"    
                                                id="costPerTransportation" data={expData} 
                                                setData={(x) => setExpData(x)} 
                                                currency={expData.destCurrency.value ? expData.destCurrency.value.slice(3): ""}
                                                type="number"/>
                    </Col>
                    <Col>
                        <FormInputExperienceMulti title="Transportation Provided" 
                                            id="transportation"
                                            data={expData}
                                            setData={(x) => setExpData(x)}
                                            options={transportationOptions}
                                            transportation={expData.transportation}/>
                    
                    </Col>
            
                </Row>
                <Row>
                    <Col><BoldLabel style={{color: Colors.primary}}>Meal Costs Per Attendee</BoldLabel></Col>
                </Row>
                <Row id="meals-section">
                    <Col>
                        <Row>
                            <Col>
                            <FormInputExperience title="Cost Per Breakfast"    
                                                id="costPerBreakfast" data={expData} 
                                                setData={(x) => setExpData(x)} 
                                                currency={expData.destCurrency.value ? expData.destCurrency.value.slice(3): ""}
                                                type="number"/>
                            </Col>
                            <Col>
                            <FormInputExperience title="Number of Breakfasts"    
                                                id="numBreakfast" data={expData} 
                                                setData={(x) => setExpData(x)} 
                                                type="number"/>
                            </Col>
                            <Col>
                            <FormInputExperience title="Cost Per Alcohol"    
                                                id="costPerAlcohol" data={expData} 
                                                setData={(x) => setExpData(x)} 
                                                currency={expData.destCurrency.value ? expData.destCurrency.value.slice(3): ""}
                                                type="number"/>
                            </Col>
                            <Col>
                            <FormInputExperience title="Number of Alcohol"    
                                                id="numAlcohol" data={expData} 
                                                setData={(x) => setExpData(x)} 
                                                type="number"/>
                            </Col>
                            
                        </Row>
                        <Row>
                            <Col>
                            <FormInputExperience title="Cost Per Lunch"    
                                                id="costPerLunch" data={expData} 
                                                setData={(x) => setExpData(x)} 
                                                currency={expData.destCurrency.value ? expData.destCurrency.value.slice(3): ""}
                                                type="number"/>
                            </Col>
                            <Col>
                            <FormInputExperience title="Number of Lunches"    
                                                id="numLunch" data={expData} 
                                                setData={(x) => setExpData(x)} 
                                                type="number"/>
                            </Col>
                            <Col/>
                            <Col/>
                        </Row>
                        <Row>
                            <Col>
                            <FormInputExperience title="Cost Per Dinner"    
                                                id="costPerDinner" data={expData} 
                                                setData={(x) => setExpData(x)} 
                                                currency={expData.destCurrency.value ? expData.destCurrency.value.slice(3): ""}
                                                type="number"/>
                            </Col>
                            <Col>
                            <FormInputExperience title="Number of Dinners"    
                                                id="numDinner" data={expData} 
                                                setData={(x) => setExpData(x)} 
                                                type="number"/>
                            </Col>
                            <Col/>
                            <Col/>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col><BoldLabel style={{color: Colors.primary}}>Meetings Costs</BoldLabel></Col>
                </Row>
                <Row id="meals-section">
                    <Col>
                        <Row>
                            <Col>
                                <FormInputPP title="Room Rental"    
                                                    id="roomRental" data={expData} 
                                                    setData={(x) => setExpData(x)} 
                                                    currency={expData.destCurrency.value ? expData.destCurrency.value.slice(3): ""}
                                                    type="number"/>
                            </Col>
                            <Col>
                                <FormInputPP title="Setup Fees"    
                                                    id="setupFee" data={expData} 
                                                    setData={(x) => setExpData(x)} 
                                                    currency={expData.destCurrency.value ? expData.destCurrency.value.slice(3): ""}
                                                    type="number"/>
                            </Col>
                            
                        </Row>
                        <Row>
                            <Col lg="3">
                                <FormInputExperience title="Number of Meetings"    
                                                    id="numMeetings" data={expData} 
                                                    setData={(x) => setExpData(x)} 
                                                    type="number"/>
                                
                            </Col>
                            <Col lg="3">
                                <FormInputPP title="AV"    
                                                    id="av" data={expData} 
                                                    setData={(x) => setExpData(x)} 
                                                    currency={expData.destCurrency.value ? expData.destCurrency.value.slice(3): ""}
                                                    type="number"/>
                                
                            </Col>
                            <Col>
                                <FormInputExperience title="Cost Per Coffee"    
                                                    id="costPerCoffee" data={expData} 
                                                    setData={(x) => setExpData(x)} 
                                                    currency={expData.destCurrency.value ? expData.destCurrency.value.slice(3): ""}
                                                    type="number"/>
                            </Col>
                            <Col>
                                <FormInputExperience title="Number of Coffees"    
                                                    id="numCoffee" data={expData} 
                                                    setData={(x) => setExpData(x)} 
                                                    type="number"/>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col><BoldLabel style={{color: Colors.primary}}>Miscellaneous Costs</BoldLabel></Col>
                </Row>

                {expData.miscellaneousCosts.value !== undefined && refresh && <DragDropContext onDragEnd={(result) => dragEnd(result)}>
                    <Droppable droppableId="1">
                        {(provided) => (<div ref={provided.innerRef} {...provided.droppableProps}>
                                {Object.entries(expData.miscellaneousCosts.value).map((obj, index) => (
                                    <Item index={index} id={obj[1].id} key={obj[1].id} miscCost={obj} currency={expData.destCurrency.value ? expData.destCurrency.value.slice(3): ""} deleteMiscCost={(x) => deleteMiscCost(x)} handleMiscellaneousChange={handleMiscellaneousChange} i={index}></Item>
                                ))}
                            {provided.placeholder}
                        </div>)}
                    </Droppable>
                </DragDropContext>}

                {false && expData.miscellaneousCosts.value !== undefined && refresh && Object.entries(expData.miscellaneousCosts.value).map((miscCost, i) => {
        
                    if (miscCost !== undefined) {
                        return(
                            <MiscellaneousInputs  key={i} miscCost={miscCost} currency={expData.destCurrency.value ? expData.destCurrency.value.slice(3): ""} deleteMiscCost={(x) => deleteMiscCost(x)} handleMiscellaneousChange={handleMiscellaneousChange} i={i}/>
                        )
                    }
                })} 

                <Row>
                    <Col>
                        <StyledButton onClick={(x) => addMiscellaneousCost(x)}>Add miscellaneous cost</StyledButton>
                    </Col>
                </Row> 
                <br />
                <Row>
                    <Col>
                        <FormInput 
                            key="notes"
                            title="Notes"
                            id="notes" data={expData}
                            setData={(x) => setExpData(x)}
                            type="text"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PrimaryButton style={{margin: "20px 0px"}} onClick={(x) => saveRetreatCosts(x)}>Save Retreat Costs</PrimaryButton>
                        {displaySummary && <PrimaryButton style={{margin: "20px 0px 20px 15px"}} onClick={(x) => setDisplaySummary(!displaySummary)}>Hide Cost Summary</PrimaryButton> }
                        {!displaySummary && <PrimaryButton style={{margin: "20px 0px 20px 15px"}} onClick={(x) => setDisplaySummary(!displaySummary)}>Show Cost Summary</PrimaryButton> }
                        
                        {costInputOpen && <UnderlineButton style={{margin: "20px 0px"}} onClick={(x) => setCostInputOpen(!costInputOpen)}>Hide retreat cost inputs</UnderlineButton>}
                    </Col>
                </Row> 
                
                <Spacer/>

                </Collapse>
            
                </React.Fragment>}
                {!costInputOpen && <UnderlineButton style={{margin: "20px 0px"}} onClick={() => setCostInputOpen(!costInputOpen)}>Show retreat cost inputs</UnderlineButton>}
                <Collapse isOpen={displaySummary}> 
                    <CostSummary guideData={props.guideData}
                                expData={expData}/>
                </Collapse>
                
            
        </StyledContainer>   
        </React.Fragment>
    )
}

export default ExperienceInputFormTab;

const StyledContainer = styled.div`
    margin-left: 1px;
    margin-right: 1px;
    padding: 15px;
    border-radius: 0px 0px 10px 10px;
    border: 2px solid ${Colors.primaryAccent}
` 

const StyledButton = styled(Button)`
    background-color: ${Colors.primaryAccent};
    border: 0px;
    color: ${Colors. primaryDark};
`
