import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from 'styled-components'
import { getGameNames } from "../../utils/firestore-functions";
import { FinalVerdictEndGameGame } from '../final-verdict/final-verdict-endgame-game';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => (props.backgroundColor.toString())};
  }
`;

export const FinalVerdictEndGame = () => {
    const [gameNames, setGameNames] = useState([]);

    useEffect(() => {
        var unsubscribe = getGameNames(setGameNames);
        return unsubscribe;
    }, []);

    return(
        <FullHeightDiv>
            <GlobalStyle backgroundColor={"black"} />
            <LoadingDiv style={{backgroundImage: "url(https://cdn.moniker-virtual.com/final-verdict/Images/final-verdict-background.png)"}}>
            <TitleDiv src="https://cdn.moniker-virtual.com/final-verdict/Images/final-verdict-title.png"></TitleDiv>
            <SpacerDiv></SpacerDiv>
                {gameNames && gameNames.map(gameName => (
                    <FinalVerdictEndGameGame key={gameName} gameName={gameName}></FinalVerdictEndGameGame>
                ))}
            </LoadingDiv>
        </FullHeightDiv>
    )
}

export default FinalVerdictEndGame;

const TitleDiv = styled.img`
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
`;

const SpacerDiv = styled.div`
  height: 50px;
`;

const LoadingDiv = styled.div`
  background-size: cover;
  height: 100%;
  display: inline-block;
  width: 100%;
  text-align: center;
  position: absolute;
  justify-content: center;
  vertical-align: middle;
  object-fit: fill;
`;

const FullHeightDiv = styled.div`
  height: 99vh;
`;