import { DateTime, Duration } from "luxon";

export const newTeamSettings = {
    finishedGame: false,
    finishingTime: "",
    score: "",

    syncedObjects: [
        { id : "testobj", state : "Default", type : "Puzzle" },
    ],

    clues: {
        mainDoor: 0,
    }
}

export const resetTeamSettings = {
    finishedGame: false,
    finishingTime: "",
    score: "",
    
    syncedObjects: [
        { id : "testobj", state : "Default", type : "Puzzle" },
    ],

    clues: {
        mainDoor: 0,
    }
}

export const newGameSettings = {
    hasGameStarted: false,
    gameLength: 60,
    startTime: "",
    scoresOpen: false,
    teamNames: [],
    canGameBeStarted: false,
    users: [],
    archived: false,
}

export const resetGameSettings = {
    hasGameStarted: false,
    startTime: "",
    scoresOpen: false,
    canGameBeStarted: false,
    seatsCurrent: 0,
    users: [],
    archived: false,
}

//Get Game Start Time
//Get Game End Time
//Each puzzle awards 60 - completion time in minutes in points
//Clues are -3 <-- not implemented
export const calculateScore = (data, startTime) => {
    var puzzles = ["bedroomdoor", "kitchenDoor", "bookshelf", "lightPuzzle", "frontDoor"];
    var score = 0;
    const list = data["syncedObjects"];
    puzzles.forEach(puzzle => {
        let x = (CheckForSyncObject(list, puzzle, "Complete"));
        if (x !== null){
            console.log("Puzzle: " + puzzle + " Time: " + x);
            let dtstart = new DateTime.fromMillis(parseInt(startTime));
            let dtend = new DateTime.fromSeconds(parseInt(x));
            let dur = Duration.fromMillis(dtend - dtstart);
            score += 60 - Math.round(dur.as("minutes"))
        };
    });

    if (data["finishingTime"] !== "Did Not Finish"){
        let dtstart = new DateTime.fromMillis(parseInt(startTime));
        let dtend = new DateTime.fromMillis(parseInt(data["finishingTime"]));
        let dur = Duration.fromMillis(dtend - dtstart);
        score += (60 - Math.round(dur.as("minutes")) + 5) * 3;
    }

    return score;
};
  
  
const CheckForSyncObject = (list, id, state) => {
    if (list.find(i => i.id === id).state.toLowerCase() === state.toLowerCase()) { 
        console.log(list.find(i => i.id === id));
        return list.find(i => i.id === id).timestamp.seconds; 
    }
    else { 
        return null;
    }
}