import React, { useState, useProps, useEffect } from "react";
//import Unity, { UnityContext } from "react-unity-webgl";
import styled, { createGlobalStyle } from 'styled-components'
import LoadingWheel from '../../assets/images/LoadingWheel.svg';
import { configTestDB } from '../config-test/config-test-db';
import { isMobile } from 'react-device-detect';
import { deleptualDebugDB, generateGUID } from '../../utils/database/firebase-deleptual-debug';
import firebase from "firebase/compat/app";


export const ConfigTest = (props) => {
  
  const [progression, setProgression] = useState(0);
  const [loading, setLoading] = useState(true);
  /*
  const [unityConfigUnityContext, setunityConfigUnityContext]= useState(new UnityContext({
    loaderUrl: "https://" + props.environment + "/unity-config/Default WebGL.loader.js",
    dataUrl: "https://" + props.environment + "/unity-config/Default WebGL.data",
    frameworkUrl: "https://" + props.environment + "/unity-config/Default WebGL.framework.js",
    codeUrl: "https://" + props.environment + "/unity-config/Default WebGL.wasm",
    streamingAssetsUrl: "https://" + props.environment + "/unity-config/StreamingAssets",
  }))
*/
  const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => (props.backgroundColor.toString())};
  }`;

  /*
const getEnvironment = () => {
  switch (props.environment) {
    case "cdn.test.deleptual.ca/dev":
      return "dev";
    case "cdn.test.deleptual.ca/staging":
      return "staging";
    case "cdn.moniker-virtual.com":
      return "production";
    default:
      console.log("Could not find an environment, we were passed in: " + props.environment + " and are returning: dev");
      return "dev";
  }
};

useEffect(() => {
  unityConfigUnityContext.on("ReactGetEnvironment", () => {
    console.log("Calling React Get Environment.");
    var s = getEnvironment();
    unityConfigUnityContext.send(
      "React Communicator", 
      "ReactListener", 
      "SetEnvironment*" + s
    );
  })
  
  unityConfigUnityContext.on("ReactGetConfigSettings", () => {
    configTestDB.collection("adminSettings").doc("configSettings").get().then(function(doc) 
      {
        if (doc.data != null){
          unityConfigUnityContext.send(
            "React Communicator", 
            "ReactListener", 
            "ReturnConfigSettings*" + JSON.stringify(doc.data())
          );
        }
        else{
          console.log("React Get Config Settings data is returning null.");
        }
      })
  })

  unityConfigUnityContext.on("ReactTestConnection", () => {
    configTestDB.collection("adminSettings").doc("configSettings").get().then(function(doc) 
      {
        if (doc.data != null){
          unityConfigUnityContext.send(
            "React Communicator", 
            "ReactListener", 
            "CheckConnection*" + JSON.stringify(doc.data())
          );
        }
        else{
          console.log("React Get Config Settings data is returning null.");
        }
      })
  })

  unityConfigUnityContext.on("ReactRecordData", (data) => {
    var strings = data.split("*");
    var time = strings[0];
    var fps = strings[1];
    var lowestfps = strings[2];
    var id = generateGUID();
    deleptualDebugDB.collection("unity-config").doc("user-logs").set({
      [id]: {
        date: firebase.firestore.Timestamp.now(),
        platform: window.navigator.platform,
        version: window.navigator.appVersion,
        userAgent: window.navigator.userAgent,
        timeTaken: time,
        framerate: fps,
        lowestFramerate: lowestfps,
      }
    }, { merge: true })
  })
  
  unityConfigUnityContext.on("progress", progress => {
      if (progress >= 1){
        setLoading(false)
      }
        setProgression(progress);
    });
},[unityConfigUnityContext])
*/

  return (
      <FullHeightDiv>
        <GlobalStyle backgroundColor={"black"} />
        {/*
        {isMobile && 
        <CenterDiv>
          <WhiteP>Please use a desktop browser to play</WhiteP>
        </CenterDiv>}
        {!isMobile && loading && <LoadingDiv>
          <LoadingP>Loading {(progression * 100).toFixed(0)}%</LoadingP>
          <LoadingWheelDiv style={{backgroundImage: "url(" + LoadingWheel + ")"}}></LoadingWheelDiv>
        </LoadingDiv>}
        {!isMobile && <Unity unityContext={unityConfigUnityContext} style={{width:"100%", height:"100%", position:"absolute", visibility: loading ? "hidden" : "visible"}}/>}
        */}
      </FullHeightDiv>
  );
}

export default ConfigTest;

const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`;

const WhiteP = styled.h3`
  color: white;
`;

const FullHeightDiv = styled.div`
  height: 99vh;
`;

const LoadingP = styled.p`
  text-align: center;
  font-size: 40px;
  color: white;
  vertical-align: middle;
`;

const LoadingWheelDiv = styled.div`
  display: inline-block;
  text-align: center;
  height: 16px;
  width: 128px;
  z-index: 1;
  vertical-align: middle;
`;

const LoadingDiv = styled.div`
  background-size: cover;
  height: 100%;
  display: inline-block;
  width: 100%;
  text-align: center;
  position: absolute;
  justify-content: center;
  vertical-align: middle;
  object-fit: fill;
  background-image: linear-gradient(135deg, #4b6bf5, #ff4dae);
`;