import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route,} from "react-router-dom";
import Escaperoom from './components/escaperoom';
import OmegaProtocolZombies from './components/omega-protocol-zombies/omega-protocol-zombies';
import MonikerAdmin from './components/monikerAdmin';
import Artemis from './components/artemis/artemis';
import ArtemisAdmin from './components/artemis/artemis-admin';
import FinalVerdict from './components/final-verdict/final-verdict';
import FinalVerdictAdmin from './components/final-verdict/final-verdict-admin';
import FinalVerdictActors from './components/final-verdict/final-verdict-actors';
import FinalVerdictEndGame from './components/final-verdict/final-verdict-endgame';
import ConfigTest from './components/config-test/config-test';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee, faEdit, faRedo, faTrash, faChevronDown, faChevronUp, faUserPlus, faExclamationCircle, faCopy, faAnchor, faSpaceShuttle,
  faBiohazard, faSkull, faBalanceScale, faMinusSquare, faToggleOn, faToggleOff, faSkullCrossbones, faTimes, faMoneyBill, faGlobeAmericas, faBed, faPlane, 
  faCar, faUtensils, faPlus, faStickyNote, faPen, faClipboard, faShieldAlt, faTable, faGripVertical, faCogs, faSync, faGhost, faTree, faClipboardList } from '@fortawesome/free-solid-svg-icons'
import FinalVerdictFacilitators from './components/final-verdict/final-verdict-facilitators';
import Admin from './components/admin/admin';
import LostAtSea from './components/lost-at-sea/lost-at-sea';
import ArtemisScores from './components/admin/games/artemis/artemis-score-screen';
import PirateShip from './components/pirate-ship/pirate-ship';
import PirateShipScores from './components/admin/games/pirate-ship/pirate-ship-score-screen';
import OmegaProtocolZombiesFacilitatorless from './components/zombies-facilitatorless/omega-protocol-zombies.js';
import OmegaProtocolZombiesScores from './components/admin/games/omega-protocol-zombies-facilitatorless/omega-protocol-zombies-score-screen';
import BudgetApp from "./components/budget-app/budget-app";
import BudgetViewer from './components/budget-app/budget-viewer/budget-viewer';
import Halloween2023Scores from './components/admin/games/halloween/halloween-2023-score-screen';
import Halloween2023 from './components/halloween/halloween-2023';
import HolidayHouseScores from './components/admin/games/holiday-house/holiday-house-score-screen';
import HolidayHouse from './components/holiday-house/holiday-house';

library.add(fab, faCheckSquare, faCoffee, faEdit, faRedo, faTrash, faChevronDown, faChevronUp, faUserPlus, faExclamationCircle, faCopy, faAnchor, faSpaceShuttle,
  faBiohazard, faSkull, faBalanceScale, faMinusSquare, faToggleOn, faToggleOff, faSkullCrossbones, faTimes, faMoneyBill, faGlobeAmericas, faBed, faPlane, faCar, 
  faUtensils, faPlus, faStickyNote, faPen, faClipboard, faShieldAlt, faTable, faGripVertical, faCogs, faSync, faGhost, faTree, faClipboardList);

class App extends Component {
  constructor() {
    super();
    this.state = {
      name: 'React'
    };
  }

  getEnvironment(){
    var loc = window.location.href;
    if (loc.includes("localhost")){
      return "cdn.test.deleptual.ca/dev";
    }
    else if (loc.includes("test.deleptual")){
      return "cdn.test.deleptual.ca/dev";
    }
    else if (loc.includes("deleptual")){
      return "cdn.test.deleptual.ca/staging";
    }
    else if (loc.includes("moniker-virtual")){
      return "cdn.moniker-virtual.com";
    }
  };

  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route path="/omega-protocol-admin">
              <MonikerAdmin />
            </Route>
            <Route path="/omega-protocol">
              <Escaperoom />
            </Route>
            <Route path="/omega-protocol-zombies">
              <OmegaProtocolZombies />
            </Route>
            <Route path="/omega-protocol-zombies-facilitatorless/:collection/scores">
              <OmegaProtocolZombiesScores environment={this.getEnvironment()} />  
            </Route>
            <Route path="/omega-protocol-zombies-facilitatorless/:collection/:document">
              <OmegaProtocolZombiesFacilitatorless />
            </Route>
            <Route path="/artemis-admin">
              <ArtemisAdmin />
            </Route>
            <Route path="/artemis/:gameName">
              <ArtemisScores />
            </Route>
            <Route path="/artemis">
              <Artemis />
            </Route>
            <Route path="/final-verdict">
              <FinalVerdict environment={this.getEnvironment()} />
            </Route>
            <Route path="/final-verdict-admin">
              <FinalVerdictAdmin environment={this.getEnvironment()} />
            </Route>
            <Route path="/final-verdict-facilitators">
              <FinalVerdictFacilitators environment={this.getEnvironment()} />
            </Route>
            <Route path="/final-verdict-actors">
              <FinalVerdictActors environment={this.getEnvironment()} />
            </Route>
            <Route path="/final-verdict-endgame">
              <FinalVerdictEndGame environment={this.getEnvironment()} />
            </Route>
            <Route path="/tech-check">
              <ConfigTest environment={this.getEnvironment()}/>
            </Route>
            <Route path="/admin">
              <Admin />
            </Route>
            <Route path="/lost-at-sea/:collection/:document">
              <LostAtSea environment={this.getEnvironment()}  />
            </Route>
            <Route path="/pirate-ship/:collection/scores">
              <PirateShipScores environment={this.getEnvironment()} />  
            </Route>
            <Route path="/pirate-ship/:collection/:document">
              <PirateShip environment={this.getEnvironment()}  />
            </Route>
            <Route path="/halloween-2023/:collection/scores">
              <Halloween2023Scores environment={this.getEnvironment()} />  
            </Route>
            <Route path="/halloween-2023/:collection/:document">
              <Halloween2023 environment={this.getEnvironment()}  />
            </Route>
            <Route path="/holiday-house/:collection/scores">
              <HolidayHouseScores environment={this.getEnvironment()} />  
            </Route>
            <Route path="/holiday-house/:collection/:document">
              <HolidayHouse environment={this.getEnvironment()}  />
            </Route>
            <Route title="Budget" path="/budget-app" children={<BudgetApp environment={this.getEnvironment()}/>} />
            <Route title="Budget" path="/budget-view/:attendees/:length/:experience/:flight/:goal/:alcohol/:ground/:adds/:occupancy/:inclusive" children={<BudgetViewer environment={this.getEnvironment()}/>} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
