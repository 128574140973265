export const newTeamSettings = {
    endTime: "",
    gameWon: false,
    hasStarted: false,
    password: "",
    startTime: 0,
    score: 0,

    locks: {
        basementDoor: false,
        bathroomMeds: false,
        cabinConsole: false,
        cabinDoor: false,
        cabinFridge: false,
        houseDoor: false,
        labFridge: false,
        librarySafe: false,
        masterBedroomDrawer: false,
        outsideCabin: false,
    },

    chemicals: {
        chemical1: false,
        chemical2: false,
        chemical3: false,
        chemical4: false,
        chemical5: false,
    },

    clues: {
        basementDoor: [false, false, false, false],
        bathroomMeds: [false, false, false, false],
        cabinConsole: [false, false, false, false],
        cabinDoor: [false, false, false, false, false],
        cabinFridge: [false, false, false],
        houseDoor: [false, false],
        labFridge: [false, false, false, false],
        librarySafe: [false, false, false, false],
        masterBedroomDrawer: [false, false],
        outsideCabin: [false, false],
        dictaphone: [false, false, false],
        morseCode: [false, false, false],
        email: [false, false, false, false],
        periodicTable: [false, false],
        pigpenCipher: [false, false, false],
        beaker: [false, false, false]
    },
}

export const resetTeamSettings = {
    endTime: "",
    gameWon: false,
    hasStarted: false,
    password: "",
    startTime: 0,
    score: 0,

    locks: {
        basementDoor: false,
        bathroomMeds: false,
        cabinConsole: false,
        cabinDoor: false,
        cabinFridge: false,
        houseDoor: false,
        labFridge: false,
        librarySafe: false,
        masterBedroomDrawer: false,
        outsideCabin: false,
    },

    chemicals: {
        chemical1: false,
        chemical2: false,
        chemical3: false,
        chemical4: false,
        chemical5: false,
    },

    clues: {
        basementDoor: [false, false, false, false],
        bathroomMeds: [false, false, false, false],
        cabinConsole: [false, false, false, false],
        cabinDoor: [false, false, false, false, false],
        cabinFridge: [false, false, false],
        houseDoor: [false, false],
        labFridge: [false, false, false, false],
        librarySafe: [false, false, false, false],
        masterBedroomDrawer: [false, false],
        outsideCabin: [false, false],
        dictaphone: [false, false, false],
        morseCode: [false, false, false],
        email: [false, false, false, false],
        periodicTable: [false, false],
        pigpenCipher: [false, false, false],
        beaker: [false, false, false]
    },
}

export const newGameSettings = {
        length: 75,
        startTime: 0,
        hasFacilitators: true,
        canGameBeStarted: false,
        hasGameStarted: false,
        seatsMax: 0,
        seatsCurrent: 0,
        teamNames: [],
        scheduleTime: new Date().toISOString().slice(0, 10),
}

export const resetGameSettings = {
    length: 75,
    startTime: 0,
    canGameBeStarted: false,
    seatsCurrent: 0,
    hasGameStarted: false,
}