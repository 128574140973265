import { Button, Input, Container, Col, Row, Collapse } from 'reactstrap';
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { listenToDocumentV2 } from "../../../../utils/firestore-functions";
import { omegaZombiesDB } from '../../../../utils/database/firebase-omega-protocol-zombies';
import { resetTeamSettings } from '../../../../utils/omega-protocol-zombies-specific';
import { runTransaction, doc } from '@firebase/firestore';

export const OmegaZombiesInstanceTeamAdmin = (props) => {
    const [teamDetails, setTeamDetails] = useState({})
    const [notesUrl, setNotesUrl] = useState("");
    const [isCollapsed, setIsCollapsed] = useState(false);

    useEffect(() => {
        var unsubscribe = listenToDocumentV2(omegaZombiesDB, props.instance[0], props.teamName[0], SetDetails);
        return unsubscribe;
    }, [props.teamName]);

    const SetDetails = (details) => {
        if(!details) return;
        setTeamDetails(details);

        if(details.notesUrl){
            setNotesUrl(details.notesUrl);
        }
    }

    const getEnvironment = () => {
        var loc = window.location.href;
        if (loc.includes("localhost")){
          return "localhost:3000";
        }
        else if (loc.includes("test.deleptual")){
          return "https://test.deleptual.ca";
        }
        else if (loc.includes("deleptual")){
          return "https://deleptual.ca";
        }
        else if (loc.includes("moniker-virtual")){
          return "https://moniker-virtual.com";
        }
    };

    const copyURL = (text) => {
        var textArea = document.createElement("textarea");
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = 0;
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
        } catch (err) {
          console.log('Oops, unable to copy');
        }
        document.body.removeChild(textArea);
    }

    const resetTeam = async (gameName, teamName) => {
        const ref = doc(omegaZombiesDB, gameName, teamName);
        try {
            await runTransaction(omegaZombiesDB, async (transaction) => {
                transaction.update(ref, {
                    ...resetTeamSettings
                });
            });
        } catch (e) {
            console.log("Transaction Failed: ", e);
        };
    }

    const deleteTeam = async (gameName, teamName) => {
        const ref = doc(omegaZombiesDB, gameName, teamName);
        const gameSettingsRef = doc(omegaZombiesDB, gameName, "gameSettings");
        try {
            await runTransaction(omegaZombiesDB, async (transaction) => {
                const settings = await transaction.get(gameSettingsRef);
                var obj = settings.data();
                delete obj.teamNames[teamName];
                transaction.delete(ref);
                transaction.set(gameSettingsRef, {...obj});
            });
        } catch (e) {
            console.log("Transaction Failed: ", e);
        };
    }

    const UpdateNotesUrl = async (url) => {
        const docRef = doc(omegaZombiesDB, props.instance[0], props.teamName[0]);
        
        try {
            await runTransaction(omegaZombiesDB, async (transaction) => {
            const docData = await transaction.get(docRef);
            if (!docData.exists()) {
                throw "Document does not exist!";
            }
            transaction.update(docRef, { notesUrl: url });
            });
        } catch (e) {
            console.log("Transaction failed: ", e);
        }
    }

    const CompletePuzzle = async (puzzle) => {
        const docRef = doc(omegaZombiesDB, props.instance[0], props.teamName[0]);        
        try {
            await runTransaction(omegaZombiesDB, async (transaction) => {
            const docData = await transaction.get(docRef);
            if (!docData.exists()) {
                throw "Document does not exist!";
            }
            transaction.update(docRef, { [puzzle]: true });
            });
        } catch (e) {
            console.log("Transaction failed: ", e);
        }
    }

    const formatTime = (time) => {
        let strings = time.split(":");
        if(strings[2]){
            return (strings[1] + ":" + strings[2].substring(0,2));
        }
        else{
            return "";
        }
      }

      const ReturnListOfProperties = (object) => {
          var array = [];
          for(const property in object){
              array.push(property);
          }

          array.sort();

          return array;
      }

    return(
        <React.Fragment>
            <GameContainer>
                <Row style={{paddingRight:"10px", paddingLeft:"10px"}}>
                    <Col xs="3" style={{alignItems:"center"}}>
                        <h3 onClick={() => {setIsCollapsed(!isCollapsed)}}>{props.teamName[1]}
                            {isCollapsed && <FAIconButton icon={["fas", "chevron-down"]}></FAIconButton>}
                            {!isCollapsed && <FAIconButton icon={["fas", "chevron-up"]}></FAIconButton>}
                        </h3>
                    </Col>
                    <Col>
                        <Container>
                            <StyledRow>
                                <Col style={{alignItems:"center", display:"flex", justifyContent:"flex-end"}}>
                                    <Button style={{marginLeft:"8px"}} color="primary" onClick={() => {copyURL(getEnvironment() + "/omega-protocol-zombies-facilitatorless/" + props.instance[0] + "/" + props.teamName[0]);}}>
                                        Copy URL
                                        <FAIconButton icon={["fas", "copy"]}></FAIconButton>
                                    </Button>
                                    <Button style={{marginLeft:"10px"}} color="warning" onClick={() => {resetTeam(props.instance[0], props.teamName[0]);}}>
                                        Reset Team
                                        <FAIconButton icon={["fas", "redo"]}></FAIconButton>
                                    </Button>
                                    <Button style={{marginLeft:"10px"}} color="danger" onClick={() => {deleteTeam(props.instance[0], props.teamName[0]);}}>
                                        Delete Team
                                        <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                                    </Button>
                                </Col>
                            </StyledRow>
                            <Row>
                                <Col style={{alignItems:"center", display:"flex", justifyContent:"flex-end"}}>
                                    <Button color="primary" onClick={() => {UpdateNotesUrl(notesUrl);}}>
                                        Update Notes URL
                                        <FAIconButton icon={["fas", "user-plus"]}></FAIconButton>
                                    </Button>
                                    <StyledInput value={notesUrl} placeholder={"Notes URL"} onChange={e => setNotesUrl(e.target.value)} />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Collapse isOpen={!isCollapsed}>
                    <Row style={{paddingRight:"10px", paddingLeft:"10px"}}>
                        <Col>
                        <p>Completed: {teamDetails.endTime}</p>
                        <p>Score: {teamDetails.score}</p>
                            {(teamDetails !== undefined) && (Object.entries(teamDetails).length > 0) && <div>
                                <StyledP>Locks:</StyledP>                   
                                <ol>
                                    {ReturnListOfProperties(teamDetails.locks).map((property, i) => {
                                        return <li key = {i}>{property.toString() + ": " + teamDetails.locks[property].toString()}</li>
                                    })}
                                </ol>

                                <StyledP>Chemicals:</StyledP>                                
                                <ol>
                                    {ReturnListOfProperties(teamDetails.chemicals).map((property, i) => {
                                        return <li key = {i}>{property.toString() + ": " + teamDetails.chemicals[property].toString()}</li>
                                    })}
                                </ol>
                            </div>
                            }
                        </Col>
                    </Row>
                </Collapse>
            </GameContainer>
        </React.Fragment>
    )
}

export default OmegaZombiesInstanceTeamAdmin;

const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
`;

const StyledP = styled.p`
    margin: 0px;
`;

const StyledRow = styled(Row)`
    margin-bottom: 10px;
`;

const GameContainer = styled(Container)`
    border-color: black;
    border-style: solid;
    border-radius: 10px;
    color: white;
    background-color: rgba(0,0,0,0.7);
    padding: 5px;
    margin-bottom: 10px;
`;

const StyledInput = styled(Input)`
    width: auto;
    margin-left: 8px;
`;

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;

const StyledButton = styled(Button)`
    display: flex;
    margin: 0px;
    margin-left: 10px;
`;