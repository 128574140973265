import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from 'uuid';

const firebaseApp = initializeApp({
    apiKey: "AIzaSyDiEF6eS8khaEbCwmBEP1kjWpki3i5RMJ0",
    authDomain: "moniker-omega-zombies.firebaseapp.com",
    projectId: "moniker-omega-zombies",
    storageBucket: "moniker-omega-zombies.appspot.com",
    messagingSenderId: "27406608254",
    appId: "1:27406608254:web:2008acb66c721caed5352f"
  }, "omega-protocol-zombies");

  const storage = getStorage(firebaseApp);
  const storageRef = ref;
  
  export const generateGUID = uuidv4;
  export const omegaZombiesDB = getFirestore(firebaseApp);
  export const auth = getAuth(firebaseApp);
  
  export { storage, storageRef, uploadBytesResumable, getDownloadURL, deleteObject };