export const newTeamSettings = {
    playerNames: [],
    notesUrl: "",

    shacklesUnlocked: false,
    cellOpened: false,
    
    keysObtained: false,
    treasureChestOpened: false,
    holdDoorOpened: false,
    
    hatchGrateOpened: false,
    rumBarrelsOpened: false,
    captainsQuartersOpened: false,

    captainsDrawerOpened: false,
    navigationSytemResolved: false,

    finishedGame: false,

    finishingTime: "",

    score: "",

    mapFragments: [false, false, false, false, false],
    clues: {
        shacklesUnlocked: [false, false, false, false],
        cellOpened: [false, false, false, false, false],
        treasureChestOpened: [false, false, false, false],
        holdDoorOpened:[false, false, false, false],
        hatchGrateOpened:[false, false, false, false],
        rumBarrelsOpened: [false, false],
        captainsQuartersOpened:[false, false, false, false, false],
        captainsDrawerOpened:[false, false, false, false],
        navigationSystemResolved:[false, false, false, false]
    }
}

export const resetTeamSettings = {
    shacklesUnlocked: false,
    cellOpened: false,
    
    keysObtained: false,
    treasureChestOpened: false,
    holdDoorOpened: false,
    
    hatchGrateOpened: false,
    rumBarrelsOpened: false,
    captainsQuartersOpened: false,

    captainsDrawerOpened: false,
    navigationSytemResolved: false,

    finishedGame: false,

    finishingTime: "",

    score: "",

    mapFragments: [false, false, false, false, false],
    clues: {
        shacklesUnlocked: [false, false, false, false],
        cellOpened: [false, false, false, false, false],
        keysObtained: [false, false, false, false],
        treasureChestOpened: [false, false, false, false],
        holdDoorOpened:[false, false, false, false],
        hatchGrateOpened:[false, false, false, false],
        rumBarrelsOpened: [false, false],
        captainsQuartersOpened:[false, false, false, false, false],
        captainsDrawerOpened:[false, false, false, false],
        navigationSystemResolved:[false, false, false, false]
    }
}

export const newGameSettings = {
    hasGameStarted: false,
    startTime: "",
    scoresOpen: false,
    teamNames: [],
    scheduleTime: "",
    hasFacilitators: true,
    canGameBeStarted: false,
    seatsMax: 0,
    seatsCurrent: 0,
}

export const resetGameSettings = {
    hasGameStarted: false,
    startTime: "",
    scoresOpen: false,
    canGameBeStarted: false,
    seatsCurrent: 0,
}