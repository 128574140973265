import { Collapse } from "reactstrap";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FinalVerdictActorGame from "../final-verdict/final-verdict-actor-game"
import { getGameNames } from "../../utils/firestore-functions";

const FinalVerdictActors = () => {
    const [gameNames, setGameNames] = useState([]);

    useEffect(() => {
        var unsubscribe = getGameNames(setGameNames);
        return unsubscribe;
    }, []);

    return(
        <React.Fragment>
            {gameNames && gameNames.map(gameName => (
                <FinalVerdictActorGame key={gameName} gameName={gameName}></FinalVerdictActorGame>
            ))}
        </React.Fragment>
    )
}

export default FinalVerdictActors

const AdminPanel = styled(Collapse)`
    background-color: white;
    text-align: center;
`;