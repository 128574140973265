import React from 'react'
import { useState, useEffect } from 'react';

const calculateTime = (currentTime, endTime) => {
    if (endTime > currentTime){
        return endTime - currentTime;
    }
    else{
        return "Finished";
    }
};

const formatTime = (t) => {
    if (t === "Finished"){
        return t;
    }
    let s = "";
    let hours = parseInt((t/(1000*60*60))%24);
    if (hours >= 1){
        s += hours.toFixed(0);
        s += ":";
    }
    let minutes = parseInt((t/(1000*60))%60);
    if (minutes < 10){
        s += "0";
    }
    s += minutes.toFixed(0);
    s += ":";
    let seconds = parseInt((t/(1000))%60);
    if (seconds < 10){
        s +="0";
    }
    s += seconds.toFixed(0);
    return s;
};

const Timer = (props) => {

    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(()=>{
        let myInterval = setInterval(() => {
            let td = calculateTime(new Date(), new Date(parseInt(props.startTime) + (props.gameLength)));
            if (td >= 0){
                forceUpdate();
            }
            else{
                clearInterval(myInterval);
            }
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
          };
    }, []);

    return (
        <React.Fragment>
            {formatTime(calculateTime(new Date(), new Date(parseInt(props.startTime) + (props.gameLength))))}
            <br></br>
        </React.Fragment>
    )
}

export default Timer;