import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import { v4 as uuidv4 } from 'uuid';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyAiJ7Gs1jG14L6lYs-uWR8ZbUxyt02D3do",
    authDomain: "config-test-5e8c6.firebaseapp.com",
    projectId: "config-test-5e8c6",
    storageBucket: "config-test-5e8c6.appspot.com",
    messagingSenderId: "267891442257",
    appId: "1:267891442257:web:ccf3615a231cc2e9436e70",
    measurementId: "G-5S2ESHZLR8"
}, "config-test");

firebase.firestore().settings({ experimentalForceLongPolling: true });

const configTestDB = firebaseApp.firestore();



export const generateGUID = uuidv4;
export const analytics = firebase.analytics();
export { configTestDB };