import { Button, Input, Container, Col, Row, Collapse, Modal } from 'reactstrap';
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { listenToDocumentV2 as listenToDocument } from "../../../../utils/firestore-functions";
import { halloween2023DB } from '../../../../utils/database/firebase-halloween-2023';
import { resetTeamSettings } from '../../../halloween/halloween-2023-config';
import { runTransaction, doc } from '@firebase/firestore';
import { TeamDetailItem } from './team-detailed-items/team-detail-item';
import { DateTime, Duration } from 'luxon';

export const Halloween2023InstanceTeamAdmin = (props) => {
    
    const [teamDetails, setTeamDetails] = useState({})
    const [notesUrl, setNotesUrl] = useState("");
    const [isCollapsed, setIsCollapsed] = useState(false);

    useEffect(() => {
        var unsubscribe = listenToDocument(halloween2023DB, props.instance[0], props.teamName[0], SetDetails);
        return unsubscribe;
    }, [props.teamName]);

    const SetDetails = (details) => {
        if(!details) return;
        setTeamDetails(details);

        if(details.notesUrl){
            setNotesUrl(details.notesUrl);
        }
    }

    const getEnvironment = () => {
        var loc = window.location.href;
        if (loc.includes("localhost")){
          return "localhost:3000";
        }
        else if (loc.includes("test.deleptual")){
          return "https://test.deleptual.ca";
        }
        else if (loc.includes("deleptual")){
          return "https://deleptual.ca";
        }
        else if (loc.includes("moniker-virtual")){
          return "https://moniker-virtual.com";
        }
    };

    const copyURL = (text) => {
        var textArea = document.createElement("textarea");
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = 0;
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          console.log('Copying text command was ' + msg);
        } catch (err) {
          console.log('Oops, unable to copy');
        }
        document.body.removeChild(textArea);
    }

    const resetTeam = async (gameName, teamName) => {
        const ref = doc(halloween2023DB, gameName, teamName);
        try {
            await runTransaction(halloween2023DB, async (transaction) => {
                transaction.update(ref, {
                    ...resetTeamSettings
                });
            });
            console.log("Success.");
        } catch (e) {
            console.log("Transaction Failed: ", e);
        };
    }

    const deleteTeam = async (gameName, teamName) => {
        const ref = doc(halloween2023DB, gameName, teamName);
        const gameSettingsRef = doc(halloween2023DB, gameName, "gameSettings");
        try {
            await runTransaction(halloween2023DB, async (transaction) => {
                const settings = await transaction.get(gameSettingsRef);
                var obj = settings.data();
                delete obj.teamNames[teamName];
                transaction.delete(ref);
                transaction.set(gameSettingsRef, {...obj});
            });
            console.log("Success.");
        } catch (e) {
            console.log("Transaction Failed: ", e);
        };
    }

    const displayTeamDetails = () => {
        let candy = [];
        let spider = [];
        let puzzle = [];
        let inventory = [];
        teamDetails.syncedObjects.map((obj, i) =>{
            if (obj.type === "Candy"){
                candy.push(obj);
            }
            else if (obj.type === "Spider"){
                spider.push(obj);
            }
            else if (obj.type === "Puzzle"){
                puzzle.push(obj);
            }
            else if (obj.type === "Inventory"){
                inventory.push(obj);
            }
        });
        return (
            <Row>
                <Col>
                    <TeamDetailItem items={puzzle} />
                </Col>
                <Col>
                    <TeamDetailItem items={inventory} />
                </Col>
                <Col>
                    <TeamDetailItem items={spider} />
                </Col>
                <Col>
                    <TeamDetailItem items={candy} />
                </Col>
            </Row>
        )
    }

    const UpdateNotesUrl = async (url) => {
        const docRef = doc(halloween2023DB, props.instance[0], props.teamName[0]);
        
        try {
            await runTransaction(halloween2023DB, async (transaction) => {
            const docData = await transaction.get(docRef);
            if (!docData.exists()) {
                throw "Document does not exist!";
            }
            transaction.update(docRef, { notesUrl: url });
            });
            console.log("Transaction successfully committed!");
        } catch (e) {
            console.log("Transaction failed: ", e);
        }
    }

    const CompletePuzzle = async (puzzle) => {
        const docRef = doc(halloween2023DB, props.instance[0], props.teamName[0]);        
        try {
            await runTransaction(halloween2023DB, async (transaction) => {
            const docData = await transaction.get(docRef);
            if (!docData.exists()) {
                throw "Document does not exist!";
            }
            transaction.update(docRef, { [puzzle]: true });
            });
            console.log("Transaction successfully committed!");
        } catch (e) {
            console.log("Transaction failed: ", e);
        }
    }

    const formatTime = (time) => {
        if (time === ""){
            return "DNF";
        }
        return new DateTime.fromMillis(parseInt(time)).toLocaleString(DateTime.DATETIME_SHORT);
      }

    const CheckForSyncObject = (list, id, state) => {
        if (list.find(i => i.id === id).state.toLowerCase() === state.toLowerCase()) { 
            return list.find(i => i.id === id).timestamp.seconds; 
        }
        else { 
            return null;
        }
    }

    //Get Game Start Time
    //Get Game End Time
    //Each puzzle awards 60 - completion time in minutes in points
    //Spiders are worth 4
    //Candies are worth 2
    //Clues are -3 <-- not implemented
    const calculateScore = () => {
        var puzzles = ["bedroomdoor", "kitchenDoor", "bookshelf", "lightPuzzle", "frontDoor"];
        var score = 0;
        const list = teamDetails["syncedObjects"];
        puzzles.forEach(puzzle => {
            let x = (CheckForSyncObject(list, puzzle, "Complete"));
            if (x !== null){
                let dtstart = new DateTime.fromMillis(parseInt(props.startTime));
                let dtend = new DateTime.fromSeconds(parseInt(x));
                let dur = Duration.fromMillis(dtend - dtstart);
                score += 60 - Math.round(dur.as("minutes"))
            };
        });

        //Spiders
        let spiders = 0;
        for(let i = 1; i <= 9; i++){
            let collected = CheckForSyncObject(list, "spider" + i.toString(), "complete");
            if (collected !== null){
                spiders++;
            }
        }
        score += (spiders * 4);

        //Candies
        let candies = 0;
        for(let i = 1; i <= 17; i++){
            let collected = CheckForSyncObject(list, "candy" + i.toString(), "complete");
            if (collected !== null){
                candies++;
            }
        }
        score += (candies * 2);

        if (teamDetails["finishingTime"] !== "Did Not Finish"){
            let dtstart = new DateTime.fromMillis(parseInt(props.startTime));
            let dtend = new DateTime.fromMillis(parseInt(teamDetails["finishingTime"]));
            let dur = Duration.fromMillis(dtend - dtstart);
            score += (60 - Math.round(dur.as("minutes")) + 5) * 3;
        }
        console.log(score);
        updateScore(score);
        return score;
    };

    const updateScore = async (score) => {
        const docRef = doc(halloween2023DB, props.instance[0], props.teamName[0]);
        try {
            await runTransaction(halloween2023DB, async (transaction) => {
            const docData = await transaction.get(docRef);
            if (!docData.exists()) {
                throw "Document does not exist!";
            }
            transaction.update(docRef, { score: score });
            });
            console.log("Transaction successfully committed! Score has been updated.");
        } catch (e) {
            console.log("Transaction failed, score failed to update: ", e);
        }
    };

    return(
        <React.Fragment>
            <GameContainer>
                <Row style={{paddingRight:"10px", paddingLeft:"10px"}}>
                    <Col xs="3" style={{alignItems:"center"}}>
                        <h3 onClick={() => {setIsCollapsed(!isCollapsed)}}>{props.teamName[1]}
                            {isCollapsed && <FAIconButton icon={["fas", "chevron-down"]}></FAIconButton>}
                            {!isCollapsed && <FAIconButton icon={["fas", "chevron-up"]}></FAIconButton>}
                        </h3>
                        {teamDetails !== undefined && teamDetails.finishingTime !== undefined && teamDetails.finishingTime !== "" && teamDetails.finishingTime !== "Did Not Finish" && <StyledP>Finished Time: {formatTime(teamDetails.finishingTime)}</StyledP>}
                        {teamDetails !== undefined && teamDetails.finishingTime !== undefined && teamDetails.finishingTime === "Did Not Finish"  && <StyledP>Did Not Finish</StyledP>}
                    </Col>
                    <Col>
                        <Container>
                            <StyledRow>
                                <Col style={{alignItems:"center", display:"flex", justifyContent:"flex-end"}}>
                                    <Button style={{marginRight:"10px"}} outline color="primary" onClick={() => calculateScore()}>Calculate Score</Button>
                                    <Button style={{marginLeft:"8px"}} color="primary" onClick={() => {copyURL(getEnvironment() + "/halloween-2023/" + props.instance[0] + "/" + props.teamName[0]);}}>
                                        Copy URL
                                        <FAIconButton icon={["fas", "copy"]}></FAIconButton>
                                    </Button>
                                    <Button style={{marginLeft:"10px"}} color="warning" onClick={() => {resetTeam(props.instance[0], props.teamName[0]);}}>
                                        Reset Team
                                        <FAIconButton icon={["fas", "redo"]}></FAIconButton>
                                    </Button>
                                    <Button style={{marginLeft:"10px"}} color="danger" onClick={() => {deleteTeam(props.instance[0], props.teamName[0]);}}>
                                        Delete Team
                                        <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                                    </Button>
                                </Col>
                            </StyledRow>
                            <Row>
                                <Col style={{alignItems:"center", display:"flex", justifyContent:"flex-end"}}>
                                    <Button color="primary" onClick={() => {UpdateNotesUrl(notesUrl);}}>
                                        Update Notes URL
                                        <FAIconButton icon={["fas", "user-plus"]}></FAIconButton>
                                    </Button>
                                    <StyledInput value={notesUrl} placeholder={"Notes URL"} onChange={e => setNotesUrl(e.target.value)} />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <br>
                </br>
                <Collapse isOpen={!isCollapsed}>
                    <Row style={{paddingRight:"10px", paddingLeft:"10px"}}>
                        <Col>
                            {(teamDetails !== undefined) && (Object.entries(teamDetails).length > 0) && teamDetails.syncedObjects !== undefined && displayTeamDetails()}
                        </Col>
                    </Row>
                </Collapse>
            </GameContainer>
        </React.Fragment>
    )
}

export default Halloween2023InstanceTeamAdmin;

const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
`;

const StyledP = styled.p`
    margin: 0px;
`;

const StyledRow = styled(Row)`
    margin-bottom: 10px;
`;

const GameContainer = styled(Container)`
    border-color: black;
    border-style: solid;
    border-radius: 10px;
    color: white;
    background-color: rgba(0,0,0,0.7);
    padding: 5px;
    margin-bottom: 10px;
`;

const StyledInput = styled(Input)`
    width: auto;
    margin-left: 8px;
`;

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;

const StyledButton = styled(Button)`
    display: flex;
    margin: 0px;
    margin-left: 10px;
`;