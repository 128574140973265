import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
//import Unity, { UnityContext } from "react-unity-webgl";
import styled, { createGlobalStyle } from 'styled-components'
import LoadingWheel from '../../assets/images/LoadingWheel.svg';
import { lostAtSeaDB } from '../../utils/database/firebase-lost-at-sea';
import { setTeamBoolean, setPlayerString } from "../../utils/firestore-functions";
import { isMobile } from 'react-device-detect';

export const LostAtSea = (props) => {
  
  const [progression, setProgression] = useState(0);
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState({});
  /*
  const [lostAtSeaUnityContext, setLostAtSeaUnityContext]= useState(new UnityContext({
    loaderUrl: "https://" + props.environment + "/lost-at-sea/webgl.loader.js",
    dataUrl: "https://" + props.environment + "/lost-at-sea/webgl.data",
    frameworkUrl: "https://" + props.environment + "/lost-at-sea/webgl.framework.js",
    codeUrl: "https://" + props.environment + "/lost-at-sea/webgl.wasm",
    streamingAssetsUrl: "https://" + props.environment + "/lost-at-sea/StreamingAssets",
  }))
*/
  let { collection } = useParams();
  let { document } = useParams();
/*
  useEffect(
    () => {
      console.log(collection);
      console.log(document);
      lostAtSeaDB.collection(collection).doc(document).get().then(doc => 
        {
          console.log(doc.data());
          setPlayers(doc.data().playerNames);
        });
    }, [lostAtSeaUnityContext]);

  useEffect(
    () => {
      lostAtSeaUnityContext.on("ReactGetURLInformation", () => {
        lostAtSeaUnityContext.send(
          "React Communicator", 
          "ReactListener", 
          ("SetURLParameters" + "*" + collection + "*" + document)
        );
      })

      lostAtSeaUnityContext.on("ReactGetServerUTC", () => {
        console.log("Getting UTC from react");
        fetch("https://us-central1-escape-room-1980b.cloudfunctions.net/getUTCTime", {
              "method": "GET",
              "headers": {
              }
            })
            .then(response => response.json())
            .then(response => {
              lostAtSeaUnityContext.send(
                "LostSpecific", 
                "ReturnServerUTC", 
                response.result.toString()
              );
            })
            .catch(err => { console.log(err); 
            });
      });
      
      lostAtSeaUnityContext.on("ReactGetEnvironment", () => {
        var s = getEnvironment();
        console.log("About to trigger: SetEnvironment with: " + s);
        lostAtSeaUnityContext.send(
          "React Communicator", 
          "ReactListener", 
          ("SetEnvironment" + "*" + s)
        );
      })
      
      lostAtSeaUnityContext.on("progress", progress => {
          if (progress >= 1){
            setLoading(false)
          }
            setProgression(progress);
        });
      
        lostAtSeaUnityContext.on("ReactListenToTeam", () => {
          lostAtSeaDB.collection(collection).doc(document).onSnapshot(function(doc) 
          {
            if (doc.data != null && doc.data != undefined){
              lostAtSeaUnityContext.send(
                "React Communicator", 
                "ReactListener", 
                ("ReturnTeamData*" + JSON.stringify(doc.data()))
              );
            }
            else{
              lostAtSeaUnityContext.send(
                "React Communicator",
                "ThrowError",
                "Could not find team data"
              );
            }
          });
        });
    
        lostAtSeaUnityContext.on("ReactListenToGame", () => {
          lostAtSeaDB.collection(collection).doc("gameSettings").onSnapshot(function(doc) 
          {
            if (doc.data != null){
              lostAtSeaUnityContext.send(
                "React Communicator",  
                "ReactListener", 
                ("ReturnGameData*" + JSON.stringify(doc.data()))
              );
            }
            else{
              lostAtSeaUnityContext.send(
                "React Communicator",
                "ThrowError",
                "Could not find game data"
              );
            }
          });
        });        
        
        lostAtSeaUnityContext.on("ReactGetLostAtSeaPlayers", () => {
          lostAtSeaDB.collection(collection).doc(document).get().then(doc => 
          {
            if (doc.data != null){
              lostAtSeaUnityContext.send(
                "React Communicator",  
                "ReactListener", 
                ("ReturnLostAtSeaPlayerNames*" + JSON.stringify(doc.data()))
              );
            }
            else{
              lostAtSeaUnityContext.send(
                "React Communicator",
                "ThrowError",
                "Unable to return Players"
              );
            }
          });
        });

        lostAtSeaUnityContext.on("ReactSubmitScore", (information) => {
          var strings = information.split("*");
          var score = parseInt(strings[0]);
          var teamName = strings[1];
          lostAtSeaDB.runTransaction(function(transaction) {
            return transaction.get(lostAtSeaDB.collection(collection).doc("gameSettings")).then(function(doc) {
              if (doc.exists){
                var d = doc.data();
                var obj = d.teamScores;
        
                if (obj[teamName] === undefined || obj[teamName].toString() === ""){
                  obj[teamName] = score;
                  transaction.update(lostAtSeaDB.collection(collection).doc("gameSettings"), {
                    teamScores: obj
                  });
                }
              }
              else{
                console.log("Doc does not exist");
              }
            });
          });
        });

        lostAtSeaUnityContext.on("ReactAddDocumentArrayProperty", (data) => {
          var strings = data.split("*");
          var propertyName = strings[0]; 
          var propertyValue = strings[1]; 
          return lostAtSeaDB.runTransaction((transaction) => {
            return transaction.get(lostAtSeaDB.collection(collection).doc(document)).then((doc) => {
              var obj = doc.data();
              if(!obj[propertyName].includes(propertyValue)){
                obj[propertyName].push(propertyValue); 
                transaction.update(lostAtSeaDB.collection(collection).doc(document), {
                    ...obj,
                });
              }
            })
          }).then(() => {       
          }).catch((error) => {       
          })
        });

        lostAtSeaUnityContext.on("ReactRemoveDocumentArrayProperty", (data) => {
          console.log("Remove Doc Called");
          var strings = data.split("*");
          var propertyName = strings[0]; 
          var propertyValue = strings[1]; 
          return lostAtSeaDB.runTransaction((transaction) => {
            return transaction.get(lostAtSeaDB.collection(collection).doc(document)).then((doc) => {
              var obj = doc.data();
              const index = obj[propertyName].indexOf(propertyValue);
              obj[propertyName].splice(index, 1);
              transaction.update(lostAtSeaDB.collection(collection).doc(document), {
                  ...obj,
              });
            })
          }).then(() => {       
          }).catch((error) => {       
          })
        });

        lostAtSeaUnityContext.on("ReactAddDocumentArrayPropertyConditionalLength", (data) => {
          var strings = data.split("*");
          var propertyName = strings[0]; 
          var propertyValue = strings[1]; 
          var maxLength = parseInt(strings[2]); 
          return lostAtSeaDB.runTransaction((transaction) => {
            return transaction.get(lostAtSeaDB.collection(collection).doc(document)).then((doc) => {
              var obj = doc.data();
              if(!obj[propertyName].includes(propertyValue) && obj[propertyName].length < maxLength){
                obj[propertyName].push(propertyValue); 
                transaction.update(lostAtSeaDB.collection(collection).doc(document), {
                    ...obj,
                });
              }
            })
          }).then(() => {       
          }).catch((error) => {       
          })
        });

        lostAtSeaUnityContext.on("ReactUpdateCurveballKeepOne", (data) => {
          var strings = data.split("*");
          var propertyName = "curveballsFinished"; 
          var curveballNumber = parseInt(strings[0]); //This is the curveball number
          var itemKept = strings[1];
          var firstItemRemoved = strings[2];
          var secondItemRemoved = strings[3];

          return lostAtSeaDB.runTransaction((transaction) => {
            return transaction.get(lostAtSeaDB.collection(collection).doc(document)).then((doc) => {
              var obj = doc.data();
              if(obj[propertyName][curveballNumber] === true){ return; } //This should stop the function if this has already been done
              //Change boolean
              obj[propertyName][curveballNumber] = true; 

              //Add the kept item
              obj["broughtItems"].push(itemKept);

              //Remove BOTH items from inventory and priority if they are there
              var index;
              if(obj["priorityItems"].includes(firstItemRemoved)){
                  index = obj["priorityItems"].indexOf(firstItemRemoved);
                  obj["priorityItems"].splice(index, 1);
              }
              if(obj["priorityItems"].includes(secondItemRemoved)){
                  index = obj["priorityItems"].indexOf(secondItemRemoved);
                  obj["priorityItems"].splice(index, 1);
              }

              transaction.update(lostAtSeaDB.collection(collection).doc(document), {
                  ...obj,
              });
            })
          }).then(() => {       
          }).catch((error) => {  
            console.log("There was an issue attempting to finalize the curveball");      
          })
        });
    
    }, [lostAtSeaUnityContext],
  );
 
  const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => (props.backgroundColor.toString())};
  }`;
  

const getEnvironment = () => {
  switch (props.environment) {
    case "cdn.test.deleptual.ca/dev":
      return "dev";
    case "cdn.test.deleptual.ca/staging":
      return "staging";
    case "cdn.moniker-virtual.com":
      return "production";
    default:
      console.log("Lost at sea could not find an environment, we were passed in: " + props.environment + " and are returning: dev");
      return "dev";
  }
};
*/

  return (
      <FullHeightDiv>
        {/*
        <GlobalStyle backgroundColor={"black"} />
        {isMobile && 
        <CenterDiv>
          <WhiteP>Please use a desktop browser to play</WhiteP>
        </CenterDiv>}
        {!isMobile && loading && <LoadingDiv style={{backgroundImage: "url(https://cdn.moniker-virtual.com/lost-at-sea/images/background.png)"}}>
          <LoadingP>Loading {(progression * 100).toFixed(0)}%</LoadingP>
          <LoadingWheelDiv style={{backgroundImage: "url(" + LoadingWheel + ")"}}></LoadingWheelDiv>
        </LoadingDiv>}
        {!isMobile && <Unity unityContext={lostAtSeaUnityContext} style={{width:"100%", height:"100%", position:"absolute", visibility: loading ? "hidden" : "visible"}}/>}
        */}
      </FullHeightDiv>
  );
}

export default LostAtSea;

const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`;

const WhiteP = styled.h3`
  color: white;
`;

const FullHeightDiv = styled.div`
  height: 99vh;
`;

const LoadingP = styled.p`
  text-align: center;
  font-size: 40px;
  color: white;
  background-image: radial-gradient(hsla(267,10%,18%,0.99),hsla(267,10%,40%,0));
  vertical-align: middle;
`;

const LoadingWheelDiv = styled.div`
  display: inline-block;
  text-align: center;
  height: 16px;
  width: 128px;
  z-index: 1;
  vertical-align: middle;
`;

const LoadingDiv = styled.div`
  background-size: cover;
  height: 100%;
  display: inline-block;
  width: 100%;
  text-align: center;
  position: absolute;
  justify-content: center;
  vertical-align: middle;
  object-fit: fill;
`;