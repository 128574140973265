import React from 'react'
import { useState, useEffect } from 'react';

const calculateTime = (currentTime, endTime) => {
    return endTime - currentTime;
};

const formatTime = (t) => {
    var s = "";
    var hours = parseInt((t/(1000*60*60))%24);
    if (hours >= 1){
        s += hours.toFixed(0);
        s += ":";
    }
    var minutes = parseInt((t/(1000*60))%60);
    if (minutes < 10){
        s += "0";
    }
    s += minutes.toFixed(0);
    s += ":";
    var seconds = parseInt((t/(1000))%60);
    if (seconds < 10){
        s +="0";
    }
    s += seconds.toFixed(0);
    return s;
};

const Timer = (props) => {
    const [timeDifference, setTimeDifference] =  useState(new Date());
    const [endTime, setEndTime] = useState(new Date(parseInt(props.startTime) + parseInt(props.duration)));
    
    useEffect(()=>{
        let myInterval = setInterval(() => {
            var td = calculateTime(new Date(), endTime);
            if (td >= 0){
                setTimeDifference(td)
            }
            else{
                setTimeDifference(0);
                if (props.callback !== undefined){
                    props.callback();
                }
                clearInterval(myInterval);
            }
            }, 1000)
            return ()=> {
                clearInterval(myInterval);
            };
    });

    return (
        <React.Fragment>
            {formatTime(timeDifference)}
        </React.Fragment>
    )
}

export default Timer;