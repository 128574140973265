import { Container, Row, Col, Button,  } from "reactstrap";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FinalVerdictFacilitatorTeam from "./final-verdict-facilitator-team";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getGameSettings } from "../../utils/firestore-functions";

const FinalVerdictAdminGame = (props) => {
    const [teamsToggle, setTeamsToggle] = useState(false);
    const [gameSettings, setGameSettings] = useState({});

    const toggleTeam = () => {
        setTeamsToggle(!teamsToggle);
    }

    useEffect(() => {
        var unsubscribe = getGameSettings(props.gameName, setGameSettings);
        return unsubscribe;
    }, []);

    return(
        <React.Fragment>
            <Container>
                <GamePanel>
                <Row>
                    <Col xs="3">
                        <p>Game Name: {props.gameName}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button outline color="primary" onClick={() => toggleTeam()}>
                            {teamsToggle ? <FAIconButton icon={["fas", "chevron-up"]}></FAIconButton> : <FAIconButton icon={["fas", "chevron-down"]}></FAIconButton>}
                            TEAMS - {Object.keys(gameSettings).length !== 0 && Object.keys(gameSettings.teamNames).length}
                        </Button>
                    </Col>
                </Row>
                {teamsToggle && gameSettings.teamNames && Object.entries(gameSettings.teamNames).sort(function(a,b){ return a[1]-b[1]}).map(teamName => (
                    <SpacedRow>
                        <Col>
                            <TeamPanel>
                                <FinalVerdictFacilitatorTeam key={teamName[0]} gameName={props.gameName} teamName={teamName[1]}></FinalVerdictFacilitatorTeam>
                            </TeamPanel>
                        </Col>
                    </SpacedRow>
                ))}
                </GamePanel>
            </Container>
        </React.Fragment>
    )
}

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
`;

const SpacedRow = styled(Row)`
    margin: 10px;
`;

const GamePanel = styled.div`
    height: 100%;
    width: 100%;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    margin: 3px;
    padding: 3px;
    background-color: rgb(242, 254, 255);
    box-shadow: 5px 5px #888888;
`;

const TeamPanel = styled.div`
    height: 100%;
    width: 100%;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    margin: 5px;
    padding: 5px;
    background-color: rgb(242, 254, 255);
    box-shadow: 5px 5px #888888;
`;

export default FinalVerdictAdminGame