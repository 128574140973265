import React, { useState, useEffect} from "react";
import styled from 'styled-components'
import { Button, Collapse } from 'reactstrap';
import ArtemisTeam from './artemis-team';
import { db } from "../../firebase";
import Timer from "../../utils/timer";

const getTeams = (gameName, callback) => {
    db.collection(gameName).get().then((querySnapshot) => {
        var teams = {};
        querySnapshot.forEach((doc) => {
            if (doc.id === "gameSettings"){
                
            }
            else{
                teams[doc.id] = doc.data();
            }
        })
        callback(teams);
    });
  }

export const getGameSettings = (gameName, callback) => {
    var unsubscribe = db.collection(gameName).doc("gameSettings").onSnapshot((document) => {
        console.log("Triggering Hook!")
        if (!document.exists){
            throw "Document not found!";
        }
        else{
            const gameSettings = document.data();
            callback(gameSettings);
        }
    })
    return unsubscribe;
};

export const getUTCTime = (gameName) => {
    fetch("https://us-central1-escape-room-1980b.cloudfunctions.net/getUTCTime", {
        "method": "GET",
        "headers": {}
    })
    .then(response => response.json())
    .then(response => {startGame(gameName, response.result);})
    .catch(err => { console.log(err); });
}

export const startGame = (gameName, time) => {

    return db.runTransaction((transaction) => {
        return transaction.get(db.collection(gameName).doc("gameSettings")).then((document) => {
            var obj = document.data();
            transaction.update(db.collection(gameName).doc("gameSettings"), {
                ...obj,
                hasGameStarted: true,
                startTime: time.toString()
            });
        })
    }).then(() => {
        
    }).catch((error) => {
        
    })
};

export const ArtemisGame = (props) => { 
    const [gameToggle, setGameToggle] = useState(false);
    const [gameInformation, setGameInformation] = useState(false);
    const [scoresToggle, setScoresToggle] = useState(false);
    const [teamsToggle, setTeamsToggle] = useState(false);
    const [gameSettings, setGameSettings] = useState({});
    const [teams, setTeams] = useState({});

    const toggleGame = () => {
        setGameToggle(!gameToggle);
    }

    const toggleGameInformation = () => {
        setGameInformation(!gameInformation);
    }

    const toggleScores = () => {
        setScoresToggle(!scoresToggle);
    }

    const toggleTeams = () => {
        setTeamsToggle(!teamsToggle);
    }

    useEffect(() => {
        var unsubscribe = getGameSettings(props.gameName, setGameSettings);
        return unsubscribe;
    }, []);

    useEffect(() => {
        getTeams(props.gameName, setTeams);
    }, []);

    const openScores = (gameName) =>{
        db.collection(gameName)
        .doc("gameSettings").update({
        scoresOpen: true
        })
        .then(() => {
            console.log("Document successfully written!");
        })
        .catch(error => {
            console.error("Error writing document: ", error);
        });
    };

    const addTeam = (number, gameName) => {
        var pass = "";
        for (var i = 0; i < 4; i++){
            pass += (Math.floor(Math.random() * 9) + 1).toString();
        }
        console.log("Adding Team" + number);
        db.collection(gameName)
        .doc(number.toString()).set({
            items: {
                Compass: "1",
                DehydratedFood: "2",
                FiftyFootNylonRope: "3",
                FirstAidKit: "4",
                InflatableLifeRaft: "5",
                LunarMap: "6",
                MechanicalRepairKit: "7",
                OxygenTanks: "8",
                SelfOxidizingSignalFlare: "9",
                SolarPoweredLights: "10",
                SolarPoweredRadio: "11",
                SpaceBlanket: "12",
                SpaceSuitRepairKit: "13",
                ThirtyLitresOfH20: "14",
                UtilityKnife: "15"
            },
            hasSubmitted: false,
            hasFinished: false,
            strategy: "1",
            passcode: pass,
        })
        .then(() => {
            console.log("Team successfully added!");
        })
        .catch(error => {
            console.error("Error adding team: ", error);
        });
    }

    const resetGame = (gameName) => {
        db.collection(gameName).doc("gameSettings").update({
        hasGameStarted: false,
        startTime: "",
        scoresOpen: false,
        teamScores: {},
        }).then(() => {
            console.log("Document successfully written!");
        })
        .catch(error => {
            console.error("Error writing document: ", error);
        });
    };

    return(
        <div>
            <ArtemisGameDiv>
                <GameTitle onClick={() => toggleGame()}>Artemis {props.gameName.substring(7)}</GameTitle>
                    <Collapse isOpen={gameToggle}>
                        <GameInfoDiv>
                            <SectionHeader onClick={() => toggleGameInformation()}>Game Information</SectionHeader>
                            <Collapse isOpen={gameInformation}>
                                <table style={{marginLeft:"auto", marginRight:"auto", textAlign:"left", tableLayout:"fixed", marginTop:"10px", marginBottom:"10px"}}>
                                    <tbody>
                                        <tr style={{height:"24px"}}>
                                            <td style={{width:"150px", height:"24px"}}>
                                                Has Game Started:
                                            </td>
                                            <td style={{width:"150px", height:"24px"}}>
                                                {Object.keys(gameSettings).length !== 0 && gameSettings.hasGameStarted.toString()}
                                            </td>
                                        </tr>
                                        <tr style={{height:"24px"}}>
                                            <td style={{width:"150px", height:"24px"}}>
                                                Time Remaining:
                                            </td>
                                            <td style={{width:"150px", height:"24px"}}>
                                                {Object.keys(gameSettings).length !== 0 && gameSettings.hasGameStarted && <Timer startTime={gameSettings.startTime} duration={1800000} />}
                                            </td>
                                        </tr>
                                        <tr style={{height:"24px"}}>
                                            <td style={{width:"150px", height:"24px"}}>
                                                Scores Open:
                                            </td>
                                            <td style={{width:"150px", height:"24px"}}>
                                                {Object.keys(gameSettings).length !== 0 && gameSettings.scoresOpen.toString()}
                                            </td>
                                        </tr>
                                        <tr style={{height:"24px"}}>
                                            <td style={{width:"150px", height:"24px"}}>
                                                Admin Passcode:
                                            </td>
                                            <td style={{width:"150px", height:"24px"}}>
                                                {console.log(gameSettings)}
                                                {Object.keys(gameSettings).length !== 0 && gameSettings.passcode.toString()}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>To login with admin, use 'admin' instead of a team number. <br></br>
                                    On the score screen, scroll down slowly to reveal the correct answers. When you arrive at the scores, press space to reveal them.<br></br>
                                    Press the Open Scores button once you want users to be able to log back in and see the score screen for themselves.</p>
                                <Button color="primary" onClick={() => {getUTCTime(props.gameName)}}>Start Game</Button>
                                <SpacingDiv></SpacingDiv>
                                <Button color="primary" onClick={() => {openScores(props.gameName)}}>Open Scores</Button>
                                <SpacingDiv></SpacingDiv>
                                <Button color="primary" onClick={() => {resetGame(props.gameName)}}>Reset Game</Button>
                                <SpacingDiv></SpacingDiv>
                                {teams !== undefined && <Button color="primary" onClick={() => {addTeam(Object.keys(teams).length + 1, props.gameName)}}>Add Team {Object.keys(teams).length + 1}</Button>}
                            </Collapse>
                        </GameInfoDiv>
                        <ScoresDiv>
                            <SectionHeader onClick={() => {toggleScores()}}>Team Scores</SectionHeader>
                            <Collapse isOpen={scoresToggle}>
                                {gameSettings !== undefined && gameSettings.teamScores && Object.entries(gameSettings.teamScores).map(entry =>{
                                    const [key, value] = entry;
                                    return <p key={key}>Team {key} has a score of {value.score} using strategy {value.strategy}</p>
                                })}
                            </Collapse>
                        </ScoresDiv>
                        <TeamsDiv>
                            <SectionHeader onClick={() => {toggleTeams()}}>Teams</SectionHeader>
                            <Collapse isOpen={teamsToggle}>
                                {teams !== undefined &&  Object.entries(teams).map(entry =>{
                                    const [key, value] = entry;
                                    return <ArtemisTeam key={key} id={key} team={value} gameName={props.gameName}></ArtemisTeam>
                                })}
                            </Collapse>
                        </TeamsDiv>
                    </Collapse>
            </ArtemisGameDiv>
        </div>
    )
  }

export default ArtemisGame

const SectionHeader = styled.h3`
    transition: transform 250ms;
    &:hover {
        transform: translateX(5px);
        color: red;
    }
    cursor: pointer;
`;

const GameTitle = styled.p`
    text-align: center;
    font-size: 40px;
    transition: transform 250ms;
    &:hover {
        transform: translateX(5px);
        color: red;
    }
    cursor: pointer;
`;

const ArtemisGameDiv = styled.div`
    text-align: center;
`;

const GameInfoDiv = styled.div`
    text-align: center;
    border: 2px solid black;
    border-radius: 5px;
    box-shadow: 10px 10px 8px #888888;
    margin: 20px;
    background-color: #F0F8FF;
    padding-top: 5px;
    padding-bottom: 10px;
`;

const ScoresDiv = styled.div`
    text-align: center;
    border: 2px solid black;
    border-radius: 5px;
    box-shadow: 10px 10px 8px #888888;
    margin: 20px;
    background-color: #F0F8FF;
`;

const TeamsDiv = styled.div`
    text-align: center;
    border: 2px solid black;
    border-radius: 5px;
    box-shadow: 10px 10px 8px #888888;
    margin: 20px;
    background-color: #F0F8FF;
`;

const SpacingDiv = styled.div`
    display: inline-block;
    width: 30px;
    text-align: center;
`;