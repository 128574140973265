import { initializeApp } from "firebase/app"
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from 'uuid';

const firebaseApp = initializeApp({
    apiKey: "AIzaSyBVypxEsDnxY15IwrVseffsVOOE7aqHvcc",
    authDomain: "deleptual-analytics.firebaseapp.com",
    projectId: "deleptual-analytics",
    storageBucket: "deleptual-analytics.appspot.com",
    messagingSenderId: "272457599839",
    appId: "1:272457599839:web:301de42103916dfba284ea",
    measurementId: "G-QCCNC4SC6Q"
}, "deleptual-analytics");

//firebase.firestore().settings({ experimentalForceLongPolling: true });

const storage = getStorage(firebaseApp);
const storageRef = ref;

export const generateGUID = uuidv4;
export const deleptualAnalyticsDB = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);

export { storage, storageRef, uploadBytesResumable, getDownloadURL, deleteObject };
