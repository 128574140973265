import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import { Button, Container, Row, Col } from 'reactstrap';
import { db } from "../../firebase";

const listenToTeam = (gameName, teamName, callback) => {
    var unsubscribe = db.collection(gameName).doc(teamName).onSnapshot((document) => {
        console.log("Triggering Hook!")
        if (!document.exists){
            throw "Document not found!";
        }
        else{
            const team = document.data();
            callback(team);
        }
    })
    return unsubscribe;
}

const deleteTeam = (gameName, teamName) => {
  db.collection(gameName).doc(teamName).delete().then(function() {
      console.log("Document successfully deleted!");
  }).catch(function(error) {
      console.error("Error removing document: ", error);
  });
};

const resetTeam = (gameName, teamName) =>{
  db.collection(gameName)
  .doc(teamName).update({
      hasSubmitted: false,
      hasFinished: false,
      strategy: "1",
      items: {
          Compass: "1",
          DehydratedFood: "2",
          FiftyFootNylonRope: "3",
          FirstAidKit: "4",
          InflatableLifeRaft: "5",
          LunarMap: "6",
          MechanicalRepairKit: "7",
          OxygenTanks: "8",
          SelfOxidizingSignalFlare: "9",
          SolarPoweredLights: "10",
          SolarPoweredRadio: "11",
          SpaceBlanket: "12",
          SpaceSuitRepairKit: "13",
          ThirtyLitresOfH20: "14",
          UtilityKnife: "15"
      }
  })
  .then(() => {
      console.log("Team successfully reset!");
  })
  .catch(error => {
      console.error("Error resetting team: ", error);
  });
};

const newPassword = (gameName, teamName) =>{
  var pass = "";
  for (var i = 0; i < 4; i++){
      pass += (Math.floor(Math.random() * 9) + 1).toString();
  }
  db.collection(gameName)
  .doc(teamName).update({
      passcode: pass
  })
};

export const ArtemisTeam = (props) => {
    const [teamName, setTeamName] = useState(props.id)
    const [team, setTeam] = useState(props.team);
    const [gameName, setGameName] = useState(props.gameName);

    useEffect(() => {
        var unsubscribe = listenToTeam(gameName, teamName, setTeam);
        return unsubscribe;
    }, []);

    return(
        <ArtemisTeamDiv>
            <Container>
                <Row>
                    <Col>
                        <div>
                            <p>Team Number: {teamName}</p>
                            <p>Password: {team.passcode}</p>
                            <p>Strategy: {team.strategy}</p>
                            <p>Has Submitted: {team.hasFinished.toString()}</p>
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <table>
                                <tbody>
                                <tr>
                                    <td>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <ul>
                                                            {Object.entries(team.items).sort((a,b) => parseInt(a[1]) - parseInt(b[1])).map((key, value) =>
                                                                <li key={value}>{key[0]},{key[1]}</li>
                                                            )}
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <Button color="primary" onClick={() => {resetTeam(gameName, teamName)}}>Reset Team</Button>
                            <div style={{height: "280px"}}></div>
                            <Button color="warning" onClick={() =>{newPassword(gameName, teamName)}}>New Password</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </ArtemisTeamDiv>
    )
};

export default ArtemisTeam

const ArtemisTeamDiv = styled.div`
    border: 2px solid black;
    border-radius: 5px;
    margin: 15px;
    box-shadow: 10px 10px 8px #888888;
`;