import { Container, Row, Col, Collapse } from "reactstrap";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getGameSettings } from "../../utils/firestore-functions";
import FinalVerdictEndGameTeam from "../final-verdict/final-verdict-endgame-team";

export const FinalVerdictEndGameGame = (props) => {
    const [gameSettings, setGameSettings] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        var unsubscribe = getGameSettings(props.gameName, setGameSettings);
        return unsubscribe;
    }, []);

    return(
        <React.Fragment>
            <Container>
                <GamePanel>
                    <Row>
                        <Col xs="12" onClick={() => toggle()}>
                            <GameTitle>
                                {props.gameName}
                                {isOpen ? <FAIcon icon={["fas", "chevron-up"]}></FAIcon> : <FAIcon icon={["fas", "chevron-down"]}></FAIcon>}
                            </GameTitle>
                        </Col>
                    </Row>
                    {gameSettings.teamNames && Object.entries(gameSettings.teamNames).sort(function(a,b){ return a[1]-b[1]}).map(teamName => (
                    <Collapse isOpen={isOpen}>
                        <SpacedRow>
                            <Col>
                                <TeamPanel>
                                    <FinalVerdictEndGameTeam key={teamName[0]} gameName={props.gameName} teamName={teamName[1]}></FinalVerdictEndGameTeam>
                                </TeamPanel>
                            </Col>
                        </SpacedRow>
                    </Collapse>
                    ))}
                </GamePanel>
            </Container>
        </React.Fragment>
    )
}

export default FinalVerdictEndGameGame;

const GameTitle = styled.h3`
    color: black;
`;

const FAIcon = styled(FontAwesomeIcon)`
    padding-left: 10px;
`;

const SpacedRow = styled(Row)`
    margin: 10px;
`;

const GamePanel = styled.div`
    height: 100%;
    width: 100%;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    margin: 3px;
    padding: 3px;
    background-color: rgba(242, 254, 255, 0.3);
`;

const TeamPanel = styled.div`
    height: 100%;
    width: 100%;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    margin: 5px;
    padding: 5px;
    background-color: rgba(25, 0, 107, 0.85);
    color: white;
`;
