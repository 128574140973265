import { Container, Row, Col, Input } from "reactstrap";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getGameSettings } from "../../utils/firestore-functions";
import { convertIntToScheduleTime } from "../../utils/final-verdict-specific";
import Timer from "../../utils/timer";

const FinalVerdictActorGame = (props) => {
    const [gameSettings, setGameSettings] = useState({});

    useEffect(() => {
        var unsubscribe = getGameSettings(props.gameName, setGameSettings);
        return unsubscribe;
    }, []);

    return(
        <React.Fragment>
            <Container>
                <GamePanel>
                <Row>
                    <Col xs="3">
                        <p>Game Name: {props.gameName}</p>
                    </Col>
                    <Col xs="3">
                        <p>{Object.keys(gameSettings).length !== 0 && gameSettings.hasGameStarted && <Timer startTime={gameSettings.startTime} duration={4500000} />}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs="3">
                        <p>Defendant Time Slots</p>
                        <ULNoBullet>
                            {Object.keys(gameSettings).length !== 0 && Object.entries(gameSettings.defendantSchedule).map(([key, value]) => {
                                return (<li>
                                    <Input type="checkbox" id="checkbox2" />
                                    {convertIntToScheduleTime(parseInt(key))} : {value}
                                </li>)
                            })}
                        </ULNoBullet>
                    </Col>
                    <Col>
                        <p>Prosecutor Time Slots</p>
                        <ULNoBullet>
                            {Object.keys(gameSettings).length !== 0 && Object.entries(gameSettings.prosecutorSchedule).map(([key, value]) => {
                                return (<li>
                                    <Input type="checkbox" id="checkbox2" />
                                    {convertIntToScheduleTime(parseInt(key))} : {value}
                                </li>)
                            })}
                        </ULNoBullet>
                    </Col>
                </Row>
                </GamePanel>
            </Container>
        </React.Fragment>
    )
}

const ULNoBullet = styled.ul`
    list-style-type: none;
`;

const FAIcon = styled(FontAwesomeIcon)`
    
`;

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
`;

const SpacedRow = styled(Row)`
    margin: 10px;
`;

const GamePanel = styled.div`
    height: 100%;
    width: 100%;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    margin: 3px;
    padding: 3px;
    background-color: rgb(242, 254, 255);
    box-shadow: 5px 5px #888888;
`;

const TeamPanel = styled.div`
    height: 100%;
    width: 100%;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    margin: 5px;
    padding: 5px;
    background-color: rgb(242, 254, 255);
    box-shadow: 5px 5px #888888;
`;

export default FinalVerdictActorGame