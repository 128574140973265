import { Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { artemisDB, generateGUID } from "../../../../utils/database/firebase-artemis";
import { newGameSettings } from "../../../../utils/lost-at-sea-specific";
import { listenToDocumentProperty } from "../../../../utils/firestore-functions";
import firebase from 'firebase/compat/app';
import AdminGameInstanceButton from '../../admin-game-instance-button';
import ArtemisInstanceAdmin from '../artemis/artemis-instance-admin';

export const ArtemisAdmin = (props) => {
    const [gameNames, setGameNames] = useState(["Delta", "Jupiter", "Mars", "Mercury", "Pluto", "Saturn", "Venus"]);
    const [instanceText, setInstanceText] = useState("");

    const select = (instance) => {
        if (instance[0] === props.selectedInstance[0]){
            props.setSelectedInstance({});
        }
        else{
            props.setSelectedInstance(instance);
        }
    }

    const cleanTitle = (title) => {
        if (title === undefined || title === "")
        {
            return "Select A Game From The Left Panel";
        }
        var words = title.split("-");
        for (let i = 0; i < words.length; i++)
        {
            words[i] = words[i][0].toUpperCase() + words[i].substr(1);
        } 
        words = words.join(' ');
        return words;
    };

    const getInstanceComponent = (game, instance) => {
        if (game === "" || Object.keys(instance).length === 0){
            return null;
        }
        else if (game === "artemis"){
            return <ArtemisInstanceAdmin gameName="Artemis" instance={instance} setSelectedInstance={props.setSelectedInstance} />;
        }
    };

    return(
        <React.Fragment>
            <GameSideBarDiv>
                <LineDivider></LineDivider>
                {gameNames !== undefined && Object.entries(gameNames).map((gameObj) => 
                    <Wrapper key={gameObj[0]} onClick={() => {select(gameObj)}}>
                        <AdminGameInstanceButton app={"Artemis"} db={artemisDB} instanceObject={gameObj} selected={props.selectedInstance[0]===gameObj[0]}></AdminGameInstanceButton>
                    </Wrapper>
                )}
                <LineDivider></LineDivider>
            </GameSideBarDiv>
            <InstanceDiv>
                <CenterDiv>
                    <h1>{cleanTitle(props.selectedGame)}</h1>
                </CenterDiv>
                {getInstanceComponent(props.selectedGame, props.selectedInstance)}
            </InstanceDiv>
        </React.Fragment>
    )
}

export default ArtemisAdmin;

const GameSideBarDiv = styled.div`
    height: 100vh; 
    width: 160px; 
    position: fixed;
    z-index: 1; 
    top: 0; 
    left: 80px;
    background-color: red;
    overflow-x: hidden; 
    padding-top: 20px;
`;

const CenterDiv = styled.div`
    text-align: center;
    color: white;
`;

const InstanceDiv = styled.div`
    width: auto;
    height: 100vh;
    margin-left: 240px;
`;

const Wrapper = styled.div`
`;

const ModalInput = styled(Input)`
    padding: 20px;
    margin: 25px auto;
    width: 80%;
`;

const LineDivider = styled.hr`
    background-color: black;
    margin-left: 15px;
    margin-right: 15px;
`;

const NewInstanceButton = styled(Button)`
    padding: 5px;
    width: 70%;
    margin: auto;
`;

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
`;

const ContainerDiv = styled.div`
    align-items: center;
    text-align: center;
`;
