import { Collapse, Container, Row, Col, Button, InputGroup, InputGroupAddon } from "reactstrap";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FinalVerdictAdminPanel from "../final-verdict/final-verdict-admin-panel";
import FinalVerdictAdminGames from "../final-verdict/final-verdict-admin-games";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import copyTextToClipboard from "../../utils/copy-text-to-clipboard";

const openNewTab = (url) => {
    window.open(url, '_blank');
};

const FinalVerdictAdmin = () => {
    const [adminPanel, setAdminPanel] = useState(false);

    const toggle = () => setAdminPanel(!adminPanel);

    useEffect(() => {

    });

    return(
        <Container>
            <Row>
                <Col>
                    <CenterH3 onClick={() => toggle()}>Games cannot be renamed or deleted once made!
                        {adminPanel ? <FAIconButton icon={["fas", "chevron-up"]}></FAIconButton> : <FAIconButton icon={["fas", "chevron-down"]}></FAIconButton>}
                    </CenterH3>
                    {adminPanel && <AdminPanel isOpen={adminPanel}>
                        <FinalVerdictAdminPanel></FinalVerdictAdminPanel>    
                    </AdminPanel>}
                </Col>
            </Row>
            <Row>
                <Col xs="3">
                </Col>
                <Col>
                    <CenterDiv>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <Button outline color="primary" onClick={() => copyTextToClipboard("https://moniker-virtual.com/final-verdict-endgame")}>
                                    <FAIcon icon={["fas", "copy"]}></FAIcon>
                                </Button>
                            </InputGroupAddon>
                            <InputGroupAddon addonType="append">
                                <Button color="primary" onClick={() => openNewTab("https://moniker-virtual.com/final-verdict-endgame")}>Go To Endgame</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </CenterDiv>
                </Col>
                <Col xs="3">
                </Col>
            </Row>
            <Row>
                <Col xs="3">
                </Col>
                <Col>
                    <CenterDiv>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <Button outline color="primary" onClick={() => copyTextToClipboard("https://moniker-virtual.com/final-verdict-actors")}>
                                    <FAIcon icon={["fas", "copy"]}></FAIcon>
                                </Button>
                            </InputGroupAddon>
                            <InputGroupAddon addonType="append">
                                <Button color="primary" onClick={() => openNewTab("https://moniker-virtual.com/final-verdict-actors")}>Go To Actors</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </CenterDiv>
                </Col>
                <Col xs="3">
                </Col>
            </Row>
            <Row>
                <Col xs="3">
                </Col>
                <Col>
                    <CenterDiv>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <Button outline color="primary" onClick={() => copyTextToClipboard("https://moniker-virtual.com/final-verdict-facilitators")}>
                                    <FAIcon icon={["fas", "copy"]}></FAIcon>
                                </Button>
                            </InputGroupAddon>
                            <InputGroupAddon addonType="append">
                                <Button color="primary" onClick={() => openNewTab("https://moniker-virtual.com/final-verdict-facilitators")}>Go To Facilitators</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </CenterDiv>
                </Col>
                <Col xs="3">
                </Col>
            </Row>
            <Row>
                <Col xs="3"></Col>
                <Col>
                    <CenterDiv>
                        <NoMarginP>Launch Instructions:</NoMarginP>
                        <p>
                            Each team has a custom URL that will contain the live player names and passwords. 
                            It contains a link to the game and a shared doc you can link in the team portion of the admin site below.
                        </p>
                    </CenterDiv>
                </Col>
                <Col xs="3"></Col>
            </Row>
            <Row>
                <Col>
                    <FinalVerdictAdminGames></FinalVerdictAdminGames>
                </Col>
            </Row>
        </Container>
    )
}

export default FinalVerdictAdmin

const FAIcon = styled(FontAwesomeIcon)`
    
`;

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;

const NoMarginP = styled.p`
    padding:0px;
    margin:0px;
    font-weight: bold;
`;

const CenterDiv = styled.div`
    text-align: center;
    margin-top: 3px;
`;

const CenterH3 = styled.h3`
    text-align: center;
`;

const AdminPanel = styled(Collapse)`
    background-color: white;
    text-align: center;
`;