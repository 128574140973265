import React from "react";
//import Unity, { UnityContext } from "react-unity-webgl";
import styled, { createGlobalStyle } from 'styled-components'
import { omegaProtocolZombiesDB } from "../omega-protocol-zombies/firebase-omega-protocol-zombies";
import LoadingWheel from '../../assets/images/LoadingWheel.svg';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => (props.backgroundColor.toString())};
  }
`;

class OmegaProtocolZombies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progression: 0,
      loading: true,
      m_teamNumber: "0",
      m_gameName: "",
    };
    /*
    this.unityContext = new UnityContext({
      loaderUrl: "https://cdn.moniker-virtual.com/omega-protocol-zombies/defaultWebGL.loader.js",
      dataUrl: "https://cdn.moniker-virtual.com/omega-protocol-zombies/defaultWebGL.data",
      frameworkUrl: "https://cdn.moniker-virtual.com/omega-protocol-zombies/defaultWebGL.framework.js",
      codeUrl: "https://cdn.moniker-virtual.com/omega-protocol-zombies/defaultWebGL.wasm",
      streamingAssetsUrl: "https://cdn.moniker-virtual.com/omega-protocol-zombies/StreamingAssets",
    });

    this.unityContext.on("progress", progression => {
      if (progression >= 1){
        this.setState({
          loading: false
        })
      }
      this.setState({
        progression: progression
      });
    });

    this.unityContext.on("CheckTeamPasscode", teamInformation => {
      var strings = teamInformation.split(",");
      var teamNumber = strings[0];
      var teamPasscode = strings[1];
      var gameName = "omegaProtocol" + strings[2];
      console.log("Passing gameName: " + gameName + " teamID: " + teamNumber + " and code: " + teamPasscode);
      omegaProtocolZombiesDB.collection(gameName).doc(teamNumber)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.data() !== undefined && querySnapshot.data().toString() !== ""){
          var obj = querySnapshot.data();
          console.log("Checking Team Passcode" + JSON.stringify(obj));
          this.unityContext.send(
            "ReactListener", 
            "ReturnTeamData", 
            JSON.stringify(obj)
          );
        }
        else{
          this.unityContext.send(
            "ReactListener", 
            "TeamNotFound"
          );
        }
      });
    });

    this.unityContext.on("ListenToTeam", teamInformation => {
      var strings = teamInformation.split(",");
      var teamNumber = strings[0];
      var gameName = strings[1];
      this.setState({ 
        m_teamNumber: teamNumber,
        m_gameName: gameName
      });
      var _this = this;
      omegaProtocolZombiesDB.collection(gameName).doc(teamNumber).onSnapshot(function(doc) 
      {
        if (doc.data != null){
          _this.unityContext.send(
            "ReactListener", 
            "UpdateTeamData", 
            JSON.stringify(doc.data())
          );
        }
        else{
          _this.unityContext.send(
            "ReactListener",
            "TeamNotFound"
          );
        }
      });
    });

    this.unityContext.on("StartTimer", teamInformation => {
      var strings = teamInformation.split(",");
      var teamNumber = strings[0];
      var gameName = strings[1];
      var timeString = strings[2];
      var _this = this;
      omegaProtocolZombiesDB.collection(gameName).doc(teamNumber).get().then((querySnapshot) => {
        var d = querySnapshot.data();
        if (d.hasStarted){
          _this.unityContext.send(
            "ReactListener",
            "SetTimer",
            d.startTime
          )
        }
        else{
          omegaProtocolZombiesDB.collection(gameName).doc(teamNumber).update({
            hasStarted: true,
            startTime: timeString
          });
          _this.unityContext.send(
            "ReactListener",
            "SetTimer",
            timeString
          )
        }
      });
    });

    this.unityContext.on("WonGame", teamInformation => {
      var strings = teamInformation.split(",");
      var teamNumber = strings[0];
      var gameName = strings[1];
      omegaProtocolZombiesDB.collection(gameName).doc(teamNumber).update({
        gameWon: true
      });
    });

    this.unityContext.on("EndTimer", timeString => {
      var strings = timeString.split(",");
      var teamNumber = strings[0];
      var gameName = strings[1];
      var finishTime = strings[2];
      omegaProtocolZombiesDB.collection(gameName)
      .doc(teamNumber).get().then(function(doc) {
        if (doc.exists){
          var d = doc.data();
          if (d.endTime === ""){
            omegaProtocolZombiesDB.collection(gameName).doc(teamNumber).update({
              endTime: finishTime
            });
            console.log("Finish time of: " + finishTime);
          }
          else{
            console.log("Finish time already recorded for this team.");
          }
        }
        else{
          console.log("In endtimer, could not find document for teamNumber: " + teamNumber + " and gameName: " + gameName + " trying to pass time of: " + finishTime);
        }
      });
    });

    this.unityContext.on("SendTeamProgressUpdate", message => {
      console.log(message);
    });

    this.unityContext.on("UpdateLocks", teamInformation => {
      console.log("Updating locks on the react side.");
      var strings = teamInformation.split(",");
      strings.forEach((item) => {
        console.log(item);
      })
      omegaProtocolZombiesDB.collection(this.state.m_gameName)
     .doc(this.state.m_teamNumber).update({
      locks: { 
        basementDoor : (strings[0] === 'True'), 
        cabinConsole : (strings[1] === 'True'), 
        cabinDoor : (strings[2] === 'True'), 
        cabinFridge : (strings[3] === 'True'), 
        houseDoor : (strings[4] === 'True'),
        labFridge : (strings[5] === 'True'), 
        librarySafe : (strings[6] === 'True'), 
        masterBedroomDrawer : (strings[7] === 'True'),
        bathroomMeds : (strings[8] === 'True'),
        outsideCabin : (strings[9] === 'True')
      }})
      .then(() => {
          console.log("Document successfully written!");
      })
      .catch(error => {
          console.error("Error writing document: ", error);
      });
    });

    this.unityContext.on("UpdateChemicals", teamInformation => {
      var strings = teamInformation.split(",");
      omegaProtocolZombiesDB.collection(this.state.m_gameName)
     .doc(this.state.m_teamNumber).update({
      chemicals: { 
        chemical1 : (strings[0] === 'True'), 
        chemical2 : (strings[1] === 'True'), 
        chemical3 : (strings[2] === 'True'), 
        chemical4 : (strings[3] === 'True'), 
        chemical5 : (strings[4] === 'True')
      }})
      .then(() => {
          console.log("Document successfully written!");
      })
      .catch(error => {
          console.error("Error writing document: ", error);
      });
    });
    */
  }

  render() {
    return (
      <div>
        {/*
        <GlobalStyle backgroundColor={"black"} />
        {this.state.loading && <LoadingDiv style={{backgroundImage: "url(https://cdn.moniker-virtual.com/omega-protocol-zombies/images/background.png)"}}>
          <LoadingP>Loading</LoadingP>
          <LoadingWheelDiv style={{backgroundImage: "url(" + LoadingWheel + ")"}}></LoadingWheelDiv>
        </LoadingDiv>}
        <Unity unityContext={this.unityContext} style={{height:"100%", width:"100%", position:"absolute"}}/>
        */}
      </div>
    )
  }
}

export default OmegaProtocolZombies

const LoadingP = styled.p`
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    color: white;
`;

const LoadingWheelDiv = styled.div`
    text-align: center;
    position: absolute;
    top: 50%;
    left: 55%;
    height: 16px;
    width: 128px;
    z-index: 1;
`;

const LoadingDiv = styled.div`
    background-size: cover;
    height: 938px;
    display: inline-block;
    width: 100%;
    text-align: center;
`;