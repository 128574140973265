import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Unity, useUnityContext } from "react-unity-webgl";
import styled, { createGlobalStyle } from 'styled-components'
import LoadingWheel from '../../assets/images/LoadingWheel.svg';
import { isMobile } from 'react-device-detect';
import { pirateshipDB } from "../../utils/database/firebase-pirate-ship";
import { doc, getDoc, runTransaction, onSnapshot } from "@firebase/firestore";
import { getUTCTimeV2 as getUTCTime, startGameV3 as startGame } from '../../utils/firestore-functions';
import { DateTime } from "luxon";
import { useCookies } from 'react-cookie';

const PirateShip = (props) => {
    const [unityIsReady, setUnityIsReady] = useState(false);
    const [cookies, setCookie] = useCookies();
    const [progression, setProgression] = useState(0);
    const [loading, setLoading] = useState(true);
    const [players, setPlayers] = useState({});

    const { unityProvider, sendMessage, addEventListener, isLoaded, loadingProgression } = useUnityContext({
          loaderUrl: "https://" + props.environment + "/pirate-ship/webgl.loader.js",
          dataUrl: "https://" + props.environment + "/pirate-ship/webgl.data",
          frameworkUrl: "https://" + props.environment + "/pirate-ship/webgl.framework.js",
          codeUrl: "https://" + props.environment + "/pirate-ship/webgl.wasm",
          streamingAssetsUrl: "https://" + props.environment + "/pirate-ship/StreamingAssets",
    })
      
    let { collection } = useParams();
    let { document } = useParams();

    const GlobalStyle = createGlobalStyle`
      body {
        background-color: ${props => (props.backgroundColor.toString())};
      }
    `;
    
    const startCompareTime = async () => {
      getUTCTime(null, null, compareTime, null);
    }

    const compareTime = async (database, game, serverTime, minutesOffset) => {
      let docRef = doc(pirateshipDB, collection, "gameSettings");
      let timeToPlay = false;
      try {
        await runTransaction(pirateshipDB, async (transaction) => {
          let docData = (await transaction.get(docRef)).data();
          let t = docData["scheduleTime"];
          let scheduledTime = DateTime.fromISO(t);
          if (serverTime > scheduledTime){
            timeToPlay = true;
          }
        });
      } catch (e) {
        console.log("Failed to update map fragments:", e);
      }
      if (timeToPlay){
        sendMessage(
          "ReactCommunicator", 
          "ReactListener", 
          ("GameCanBeStarted")
        );
      }
      else{
        return false;
      }
    };

    useEffect(() => {
      if (isLoaded){
        setUnityIsReady(true);
      }
    }, [isLoaded]);

    useEffect(
      () => {
        async function Prepare () {
          const ref = doc(pirateshipDB, collection, document);
          const data = await getDoc(ref);
          setPlayers(data.data().playerNames);
        }
        Prepare();
      }, [unityProvider, unityIsReady]);

    useEffect(
      () => {
        if (unityIsReady){

          console.log("Unity is loaded");
          sendMessage("ReactCommunicator", "ReactListener", "ReactLoaded");
        
          addEventListener("ReactGetURLInformation", () => {
          console.log("React Get Url Information being called");
          sendMessage(
            "ReactCommunicator", 
            "ReactListener", 
            ("SetURLParameters" + "*" + collection + "*" + "gameSettings")
          );
        })
        
        addEventListener("ReactJoinRoom", async () => {
          if (cookies['moniker-abandonShip'] !== undefined && cookies['moniker-abandonShip'] === "playing"){
            sendMessage(
              "ReactCommunicator", 
              "ReactListener", 
              ("JoinRoomSuccess")
            );
            return;
          }
          let docRef = doc(pirateshipDB, collection, "gameSettings");
          try {
            await runTransaction(pirateshipDB, async (transaction) => {
              let docData = (await transaction.get(docRef)).data();
              console.log(docData);
              console.log(docData["seatsCurrent"]);
              console.log(docData["seatsMax"]);
              if(docData["seatsCurrent"] < docData["seatsMax"]){
                docData["seatsCurrent"]++;
                setCookie('moniker-abandonShip', 'playing', { path: '/'});
                transaction.update(docRef, docData);
                sendMessage(
                  "ReactCommunicator", 
                  "ReactListener", 
                  ("JoinRoomSuccess")
                );
              }
              else{
                sendMessage(
                  "ReactCommunicator", 
                  "ReactListener", 
                  ("JoinRoomFailure")
                );
              }
            });
          } catch (e) {
            console.log("Failed function call ReactJoinRoom:", e);
          }
        });

        addEventListener("ReactGetURLInformation", () => {
          sendMessage(
            "ReactCommunicator", 
            "ReactListener", 
            ("SetURLParameters" + "*" + collection + "*" + document)
          );
        });

        addEventListener("ReactOpenScoreScreen", () => {
          window.open("/pirate-ship/" + collection + "/scores")
        });

        addEventListener("ReactAskForClue", async (information) => {
          const docRef = doc(pirateshipDB, collection, document);
          try{
            await runTransaction(pirateshipDB, async (transaction) =>{
              let docData = (await transaction.get(docRef)).data();
              console.log(docData.clues[information]);
              for(let i = 0; i < docData.clues[information].length; i++)
              {
                console.log(docData.clues[information][i]);
                if(!docData.clues[information][i])
                {
                    docData.clues[information][i] = true;
                    break;
                }
              }
              transaction.update(docRef, docData);
            })
          } catch (e) {
            console.log("Failed to ask for clue");
          }
        });

        addEventListener("ReactStartGameTimer", async (information) => {
          if(information == "ahoy")
          {
            console.log("Correct, going to start game");
            getUTCTime(pirateshipDB, collection, startGame)
          }
        });

        addEventListener("ReactUpdatePuzzle", async (information) => {
          var strings = information.split("*");
          var puzzleName = strings[0];
          var status = strings[1];
          const docRef = doc(pirateshipDB, collection, document);
          try {
            await runTransaction(pirateshipDB, async (transaction) => {
              let docData = (await transaction.get(docRef)).data();
              docData[puzzleName] = status;

              transaction.update(docRef, docData);
            });
          } catch (e) {
            console.log("Transaction Failed:", e);
          }
        });

        addEventListener("ReactUpdateMapFragments", async (information) => {
          var fragmentNumber = information; //May need to parse int this?
          const docRef = doc(pirateshipDB, collection, document);
          try {
            await runTransaction(pirateshipDB, async (transaction) => {
              let docData = (await transaction.get(docRef)).data();
              docData["mapFragments"][fragmentNumber] = true;
              
              transaction.update(docRef, docData);
            });
          } catch (e) {
            console.log("Failed to update map fragments:", e);
          }
        });

        addEventListener("ReactFinishGame", async (information) => {
          var strings = information.split("*");
          var finishedOnTime = strings[0];
          var timeStamp = strings[1];
          const docRef = doc(pirateshipDB, collection, document);
          try {
            await runTransaction(pirateshipDB, async (transaction) => {
              let docData = (await transaction.get(docRef)).data();
              
              if(docData["finishingTime"] !== ""){ return; }

              if(finishedOnTime === "False"){
                docData["finishingTime"] = "Did Not Finish";
              }
              else{
                docData["finishingTime"] = timeStamp.toString();
              }

              docData["score"] = CalculateScore(docData, finishedOnTime, timeStamp);
              docData["finishedGame"] = true;
              
              transaction.update(docRef, docData);
            });
          } catch (e) {
            console.log("Failed to update map fragments:", e);
          }
        });

        addEventListener("ReactCheckTime", async () => {
          startCompareTime();
        });


        addEventListener("ReactGetServerUTC", () => {
        fetch("https://us-central1-escape-room-1980b.cloudfunctions.net/getUTCTime", {
              "method": "GET",
              "headers": {
              }
            })
            .then(response => response.json())
            .then(response => {
              sendMessage(
                "PirateSpecific", 
                "ReturnServerUTC", 
                response.result.toString()
              );
            })
            .catch(err => { console.log(err); 
            });
      });

      addEventListener("ReactGetEnvironment", () => {
        console.log("REACT GET ENVIRONMENT *****************");
        var currentEnvironment = getEnvironment();
        sendMessage(
          "ReactCommunicator", 
          "ReactListener", 
          ("SetEnvironment" + "*" + currentEnvironment)
        );
      })

      const getEnvironment = () => {
        switch (props.environment) {
          case "cdn.test.deleptual.ca/dev":
            return "dev";
          case "cdn.test.deleptual.ca/staging":
            return "staging";
          case "cdn.moniker-virtual.com":
            return "production";
          default:
            console.log("Pirate Ship could not find an environment, we were passed in: " + props.environment + " and are returning: dev");
            return "dev";
        }
      };

      addEventListener("ReactListenToTeam", () => {
        const docRef = doc(pirateshipDB, collection, document);
        onSnapshot(docRef, (d) => {
          if (d.data != null && d.data != undefined){
            sendMessage(
              "ReactCommunicator", 
              "ReactListener", 
              ("ReturnTeamData*" + JSON.stringify(d.data()))
            );
          }
          else{
            sendMessage(
              "ReactCommunicator",
              "ThrowError",
              "Could not find team data"
            );
          }
        })
      });

      addEventListener("ReactListenToGame", () => {
        const docRef = doc(pirateshipDB, collection, "gameSettings");
        onSnapshot(docRef, (d) => {
          if (d.data != null){
            sendMessage(
              "ReactCommunicator",  
              "ReactListener", 
              ("ReturnGameData*" + JSON.stringify(d.data()))
            );
          }
          else{
            sendMessage(
              "ReactCommunicator",
              "ThrowError",
              "Could not find game data"
            );
          }
        });
      });

      addEventListener("progress", progress => {
          if (progress >= 1){
            setLoading(false)
          }
            setProgression(progress);
        });
      }
      },[unityProvider, unityIsReady],
    );

    const CalculateScore = (docData, finishedOnTime, timeStamp) => {
      var finalScore = 0;

      if(finishedOnTime){
        var timeSegments = timeStamp.split(":");
        var seconds = parseInt(timeSegments[1]) * 60;
        seconds += parseInt(timeSegments[2]);

        if(seconds <= 1200){
          finalScore += 20; //This has to be a weighted percentage
        }
        else{
          var overParTime = seconds - 1200;
          var remainingTime = 2100;
          finalScore += (1 - (overParTime / remainingTime)) * 20;
        }
      }

      var puzzleScore = 0;
      var clues = docData.clues;

      if(docData.shacklesUnlocked){
        puzzleScore += 20;
        puzzleScore -= ClueCheck("shacklesUnlocked", clues);
      }
      if(docData.cellOpened){
        puzzleScore += 20;
        if(clues["cellOpened"][0]){ puzzleScore -= 1};
        if(clues["cellOpened"][1]){ puzzleScore -= 3};
        if(clues["cellOpened"][2]){ puzzleScore -= 4};
        if(clues["cellOpened"][3]){ puzzleScore -= 5};
        if(clues["cellOpened"][4]){ puzzleScore -= 7};
      }
      if(docData.treasureChestOpened){
        puzzleScore += 20;
        puzzleScore -= ClueCheck("treasureChestOpened", clues);
      }
      if(docData.holdDoorOpened){
        puzzleScore += 20;
        puzzleScore -= ClueCheck("holdDoorOpened", clues);
      }
      if(docData.hatchGrateOpened){
        puzzleScore += 20;
        puzzleScore -= ClueCheck("hatchGrateOpened", clues);
      }
      if(docData.rumBarrelsOpened){
        puzzleScore += 20;
      }
      if(docData.captainsQuartersOpened){
        puzzleScore += 20;
        if(clues["captainsQuartersOpened"][0]){ puzzleScore -= 1};
        if(clues["captainsQuartersOpened"][1]){ puzzleScore -= 3};
        if(clues["captainsQuartersOpened"][2]){ puzzleScore -= 4};
        if(clues["captainsQuartersOpened"][3]){ puzzleScore -= 5};
        if(clues["captainsQuartersOpened"][4]){ puzzleScore -= 7};
      }
      if(docData.captainsDrawerOpened){
        puzzleScore += 20;
        puzzleScore -= ClueCheck("captainsDrawerOpened", clues);
      }
      if(finishedOnTime != "False"){
        puzzleScore += 20;
        puzzleScore -= ClueCheck("navigationSystemResolved", clues);
      }

      puzzleScore = puzzleScore * (80 / 180);
      finalScore += puzzleScore;

      finalScore = Math.round((finalScore + Number.EPSILON) * 100) / 100
      return finalScore;
    }

    const ClueCheck = (puzzleName, clues) => {
      var deduction = 0;
      if(clues[puzzleName][0]){ deduction += 2};
      if(clues[puzzleName][1]){ deduction += 4};
      if(clues[puzzleName][2]){ deduction += 6};
      if(clues[puzzleName][3]){ deduction += 8};

      return deduction;
    }

   return ( 
   <FullHeightDiv>
    <GlobalStyle backgroundColor={"black"} />
    {isMobile && 
    <CenterDiv>
      <WhiteP>Please use a desktop browser to play</WhiteP>
    </CenterDiv>}
    {!isMobile && !isLoaded && <LoadingDiv style={{backgroundImage: "url(https://monikerspace.nyc3.cdn.digitaloceanspaces.com/pirate-ship/images/Title.png)"}}>
      <LoadingP>Loading {(loadingProgression * 100).toFixed(0)}%</LoadingP>
      <LoadingWheelDiv style={{backgroundImage: "url(" + LoadingWheel + ")"}}></LoadingWheelDiv>
    </LoadingDiv>}
    {!isMobile && <Unity unityProvider={unityProvider} style={{width:"100%", height:"100%", position:"absolute", visibility: isLoaded ? "visible" : "hidden"}}/>}
  </FullHeightDiv>);
}
export default PirateShip;

const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`;

const WhiteP = styled.h3`
  color: white;
`;

const FullHeightDiv = styled.div`
  height: 99vh;
`;

const LoadingP = styled.p`
  text-align: center;
  font-size: 40px;
  color: white;
  background-image: radial-gradient(hsla(267,10%,18%,0.99),hsla(267,10%,40%,0));
  vertical-align: middle;
`;

const LoadingWheelDiv = styled.div`
  display: inline-block;
  text-align: center;
  height: 16px;
  width: 128px;
  z-index: 1;
  vertical-align: middle;
`;

const LoadingDiv = styled.div`
  background-size: cover;
  height: 100%;
  display: inline-block;
  width: 100%;
  text-align: center;
  position: absolute;
  justify-content: center;
  vertical-align: middle;
  object-fit: fill;
`;