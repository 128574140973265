import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/analytics";

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyDN0o5psUEk5CWEReU_ADzSqXANt7i2fd4",
    authDomain: "escape-room-1980b.firebaseapp.com",
    databaseURL: "https://escape-room-1980b.firebaseio.com",
    projectId: "escape-room-1980b",
    storageBucket: "escape-room-1980b.appspot.com",
    messagingSenderId: "253129114251",
    appId: "1:253129114251:web:34b597738aee76d11d8a8e",
    measurementId: "G-2XL9WFV9F3"
}, "omegaProtocolZombies");

firebase.firestore().settings({ experimentalForceLongPolling: true });
const omegaProtocolZombiesDB = firebaseApp.firestore();

export const analytics = firebase.analytics();
export { omegaProtocolZombiesDB };