import { Container, Row, Col, Collapse } from "reactstrap";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { getTeam } from "../../utils/firestore-functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const countPlayers = (guilt, players) => {
    var ctr = 0;
    var entries = Object.entries(players);
    entries.forEach(p => {
        if (p[1].vote === guilt){
            ctr++;
        }
    })
    return ctr;
};

const juryResult = (players) => {
    var guilty = countPlayers("guilty", players);
    var notGuilty = countPlayers("not guilty", players);
    if (guilty > 0 && notGuilty === 0){
        return "GUILTY";
    }
    else if (notGuilty > 0 && guilty === 0){
        return "NOT GUILTY"
    }
    return "Hung Jury";
};

export const FinalVerdictEndGameTeam = (props) => {
    const [team, setTeam] = useState({});
    const [players, setPlayers] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        var unsubscribe = getTeam(props.gameName, props.teamName, setTeam, setPlayers);
        return unsubscribe;
    }, []);

    return(
        <TeamPanel>
            <Container>
                <Row>
                    <Col xs="12" onClick={() => toggle()}>
                        <h2>
                            {props.teamName}
                            {isOpen ? <FAIcon icon={["fas", "chevron-up"]}></FAIcon> : <FAIcon icon={["fas", "chevron-down"]}></FAIcon>}
                        </h2>
                    </Col>
                </Row>
                <Collapse isOpen={isOpen}>
                    <Row>
                        <Col xs="4">
                        </Col>
                        <Col xs="2">
                            <TightP>
                                Guilty
                            </TightP>
                        </Col>
                        <Col xs="2">
                            <TightP>
                                Not Guilty
                            </TightP>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="4">
                        </Col>
                        <Col xs="2">
                            <TightP>
                                {players !== undefined && countPlayers("guilty", players)}
                            </TightP>
                        </Col>
                        <Col xs="2">
                            <TightP>
                                {players !== undefined && countPlayers("not guilty", players)}
                            </TightP>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <WhiteLine></WhiteLine>
                            <AnswerDiv>
                                <Verdict>{players !== undefined && juryResult(players)}</Verdict>
                            </AnswerDiv>
                        </Col>
                    </Row>
                </Collapse>
            </Container>
        </TeamPanel>
    )
}

const AnswerDiv = styled.div`
    background-color: rgba(255,255,255,1);
    margin: auto;
    border-radius: 25px;
    max-width: 400px;
`;

const TightP = styled.p`
    margin:0;
    padding:0;
    font-size: 1.5rem;
`;

const FAIcon = styled(FontAwesomeIcon)`
    padding-left: 10px;
`;

const Verdict = styled.p`
    color: black;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: -5px;
`;

const WhiteLine = styled.hr`
    background-color: white;
`;

const TeamPanel = styled.div`
    margin:10px;
`;

export default FinalVerdictEndGameTeam