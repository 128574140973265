import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from 'reactstrap';

export const ArtemisScoreScreenEvent = (props) => {

    return(
        <StyledContainer>
            <StyledRow>
                <StyledCol xs="4">
                    <StyledImage src={"https://cdn.moniker-virtual.com/artemis/images/events/" + props.event.name + ".png"} />
                </StyledCol>
                <StyledCol>
                    <Container>
                        <Row>
                            <StyledCol>
                                <StyledH2>{props.event.name}</StyledH2>
                            </StyledCol>
                        </Row>
                        <Row>
                            <StyledCol xs="4">
                                <BoldP>Stay Put: </BoldP>
                            </StyledCol>
                            <StyledCol>
                                <StyledP>{props.event.stay}</StyledP>
                            </StyledCol>
                        </Row>
                        <StyledHR />
                        <Row>
                            <StyledCol xs="4">
                                <BoldP>Circumvent the Crater: </BoldP>
                            </StyledCol>
                            <StyledCol>
                                <StyledP>{props.event.circumvent}</StyledP>
                            </StyledCol>
                        </Row>
                        <StyledHR />
                        <Row>
                            <StyledCol xs="4">
                                <BoldP>Fracking: </BoldP>
                            </StyledCol>
                            <StyledCol>
                                <StyledP>{props.event.through}</StyledP>
                            </StyledCol>
                        </Row>
                    </Container>
                </StyledCol>
            </StyledRow>
        </StyledContainer>
    );
}

export default ArtemisScoreScreenEvent;

const StyledHR = styled.hr`
    background-color: white;
    margin: 0px;
`;

const StyledContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: ${props => props.side};
`;

const StyledRow = styled(Row)`
    margin-bottom: 20px;
    border-radius: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: white;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
`;

const StyledH2 = styled.h2`
    color: white;
`;

const StyledP = styled.p`
    color: white;
    margin: 0px;
`;

const BoldP = styled.p`
    color: white;
    font-weight: bold;
    margin: 0px;
`;

const StyledImage = styled.img`
    width: 400px;
    height: 200px;
    border-radius: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: white;
    margin: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
`;

const StyledCol = styled(Col)`
    display: flex;    
    margin: 0px;
    align-items: center;
`;
