import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const AdminGameButton = (props) => {

    if (props.selected === true) {
        return(
            <SelectedGameDiv>
                <SelectedGameIcon>
                    <SelectedFAIcon  icon={["fas", props.icon]}>
                    </SelectedFAIcon>
                </SelectedGameIcon>
            </SelectedGameDiv>
        );
    }
    else{
        return(
            <GameDiv>
                <GameIcon>
                    <FAIcon  icon={["fas", props.icon]}>
                    </FAIcon>
                </GameIcon>
            </GameDiv>
        );
    }
}

export default AdminGameButton;

const GameDiv = styled.div`
    height: 50px;
    width: 100%;
    background-color: blue;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {   
        transform: translateX(8px);
        color: gold;
        font-weight: bold;
    }
`;

const GameIcon = styled.div`
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SelectedGameDiv = styled.div`
    height: 50px;
    width: 100%;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(8px);
`;

const SelectedGameIcon = styled.div`
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const FAIcon = styled(FontAwesomeIcon)`
    color: white;
`;

const SelectedFAIcon = styled(FontAwesomeIcon)`
    color: red;
`;
