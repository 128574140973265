import React from "react";
import styled from 'styled-components'
import OmegaProtocolTeam from '../components/omegaProtocolTeam';

class OmegaProtocolGame extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isOpen: true
    };
  };

  toggle(){
      this.setState({isOpen: !this.state.isOpen})
  }

  render() {
    return(
        <div>
            <OmegaProtocolGameDiv>
                <p>{this.props.gameName}</p>
                <p>Game Length: {this.props.gameInformation.gameSettings.length}</p>
                <div>
                    {this.props.gameInformation.teams && Object.entries(this.props.gameInformation.teams).map(entry =>{
                        const [key, value] = entry;
                        return <OmegaProtocolTeam key={key} id={key} team={value} gameName={this.props.gameName}></OmegaProtocolTeam>
                    })}
                </div>
            </OmegaProtocolGameDiv>
        </div>
    )
  }
}

export default OmegaProtocolGame

const OmegaProtocolGameDiv = styled.div`
    text-align: center;
    border: 1px solid black;
    border-radius: 5px;
`;
