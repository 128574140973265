import React, { useState } from "react";
import { Row, Col, Modal, ModalBody } from "reactstrap";
import ExperienceInputFormTab from "../cost-summary/experience-tab-form-input";
import {  
        WhiteForm, 
        TabButton, 
        ActiveTabButton, 
        SecondaryButton, 
        PrimaryButton, 
        currencyOptions, } from "../../../../utils/budget-tool-specific";
import { useEffect } from "react";
import { GetDB, generateGUID } from "../../../../utils/database/firebase-budget-tool";
import { listenToDocument, updateDocumentProperty } from "../../../../utils/firestore-functions";
import firebase from "firebase/compat/app";
import SummaryTabForm from "./summary-tab-form";
import ClientOutput from "./client-output";
import FormInputExperience from "../../budget-tool/input-components/form-input-experience";
import FormInputTripLength from "../../budget-tool/input-components/form-input-exp-triplength";


const ExperienceGuideInput = (props) => {

    const [data, setData] = useState(
        {   title: {value: "", invalid: false, required: true},
            attendees: {value: 0, invalid: false, required: true},
            tripLength: {value: 0, invalid: false, required: true},
            currency: {value: "CAD$", invalid: false, required: true},
            contingency: {value: 0, invalid: false, required: true},   
            experiences: {value: {}, invalid: false, required: true},
            overhead: {value: 10, invalid: false, required: true},   
            margin: {value: 10, invalid: false, required: true},   
            occupancy: {value: "Single Occupancy", invalid: false, required: false},
            notes: {value: "", invalid: false, required: false},
    })

    const budgetDB = GetDB();

    const occupancyChecked = (value) => {
        if (value === "Double Occupancy"){
            return true;
        }
        return false;
    }

    const toggleOccupancy = () => {
        if (data.occupancy.value === "Single Occupancy"){
            setData({
                ...data, 
                occupancy:{
                    ...data.occupancy,
                    value: "Double Occupancy"
                }
            });
        }
        else{
            setData({
                ...data, 
                occupancy:{
                    ...data.occupancy,
                    value: "Single Occupancy"
                }
            });
        }
    }

    const [tabKey, setTabKey] = useState(0);
    const [copyAlert, setCopyAlert] = useState(false);

    useEffect (() =>{
        var unsubscribe = listenToDocument(budgetDB, "experienceGuides", props.id, SetGuideContent);
        return unsubscribe;
    }, [])

    const validate = (value) => { 
        if (value === undefined) {
            return true;
        } else {
            return false;
        }
    }

    const SetGuideContent = (guide) => {
        let newData = {...data};
        newData.title.value = guide.title;
        newData.attendees.value =  guide.attendees;
        newData.tripLength.value = guide.tripLength;
        newData.experiences.value = guide.experiences;
        newData.contingency.value = guide.contingency;
        newData.currency.value = guide.currency;
        newData.occupancy.value = guide.occupancy;
        if (guide.occupancy !== undefined){
            newData.occupancy.value = guide.occupancy;
        }
        if (guide.overhead !== undefined){
            newData.overhead.value = guide.overhead;
        }
        if (guide.margin !== undefined){
            newData.margin.value = guide.margin;
        }
        if (guide.notes !== undefined){
            newData.notes.value = guide.notes;
        }
        setData(newData);     
    }

    const createNewExperience = () => {

        const newTitle = "Vacation " + (Object.keys(data.experiences.value).length +  1); 
        const timeStamp = firebase.firestore.Timestamp.now()

        let newExperience = {
            title: newTitle,
            destCurrency: "CAD$",
            base: 1,
            convert: 1,
            location: "",
            timeCreated: timeStamp,
            id: generateGUID(),
            accommodationCost: 0,
            flightCost: 0,
            costPerTransportation: 0,
            transportation: {},
            costPerActivity: 0,
            numActivities: 0,
            costPerBreakfast: 0,
            numBreakfast: 0,
            costPerLunch: 0,
            numLunch: 0,
            costPerDinner: 0,
            numDinner: 0,
            costPerCoffee: 0,
            numCoffee: 0,
            costPerAlcohol: 0,
            numAlcohol: 0,
            av: {value: 0, pp: "per day"},
            setupFee: {value: 0, pp: "per day"},
            roomRental: {value: 0, pp: "per day"},
            numMeetings: 0,
            miscellaneousCosts: [],
            index: Object.keys(data.experiences.value).length + 1,
            notes: "",
        }
        updateGuideDetails(props.id);
        updateDocumentProperty(budgetDB, "experienceGuides", props.id, "experiences." + newExperience.id, newExperience); 
        setTabKey(Object.keys(data.experiences.value).length+1);
    }

    const handleSubmit = (e, index) => {
        e.preventDefault();
        // focus on next input box
    }

    const updateGuideDetails = (id) => {
        updateDocumentProperty(budgetDB, "experienceGuides", id, "title", data.title.value);
        updateDocumentProperty(budgetDB, "experienceGuides", id, "attendees", data.attendees.value);
        updateDocumentProperty(budgetDB, "experienceGuides", id, "tripLength", data.tripLength.value);
        updateDocumentProperty(budgetDB, "experienceGuides", id, "currency", data.currency.value);
        updateDocumentProperty(budgetDB, "experienceGuides", id, "contingency", data.contingency.value);
        if (data.occupancy.value !== undefined){
            updateDocumentProperty(budgetDB, "experienceGuides", id, "occupancy", data.occupancy.value);
        }
        if (data.overhead !== undefined){
            updateDocumentProperty(budgetDB, "experienceGuides", id, "overhead", data.overhead.value);
        }
        if (data.margin !== undefined){
            updateDocumentProperty(budgetDB, "experienceGuides", id, "margin", data.margin.value);
        }
        if (data.index !== undefined){
            updateDocumentProperty(budgetDB, "experienceGuides", id, "index", data.index);
        }
        if (data.notes !== undefined){
            updateDocumentProperty(budgetDB, "experienceGuides", id, "notes", data.notes);
        }
    }

    const copyURL = () => {
        let url = window.location.href.replace("edit/", "");
        navigator.clipboard.writeText(url);
        setCopyAlert(!copyAlert);
    }


    return (
        <React.Fragment>
            <Modal isOpen={copyAlert} toggle={() => setCopyAlert(!copyAlert)}>
                <ModalBody toggle={() => setCopyAlert(!copyAlert)}>
                    <Row style={{display: "flex", alignItems: "center"}}>
                        <Col>
                            <p style={{margin: "0px"}}>URL copied to clipboard!</p>
                        </Col>
                        <Col style={{display: "flex", justifyContent: "flex-end"}}>
                        <PrimaryButton style={{margin: "0px"}}onClick={() => setCopyAlert(!copyAlert)} >
                            Okay
                        </PrimaryButton>
                    </Col>
                    </Row>
                
                </ModalBody>
            </Modal>
            <Row>
                <Col>
                    <WhiteForm style={{marginRight: "15px", marginLeft: "15px"}} onSubmit={e => { handleSubmit(e, 1); }}>
                        {props.client && <ClientOutput data={data}/>}

                        {!props.client && <React.Fragment>
                        <Row>
                            <Col>
                                <FormInputExperience title="" 
                                            id="title" data={data} 
                                            setData={(x) => setData(x)} 
                                            type="text"
                                            fontSize={"30px"}/>
                            </Col>
                            <Col lg="auto" style={{display: "flex", alignItems: "center", paddingLeft: "0px"}}>
                                    <SecondaryButton style={{margin: "0px 0px 16px 0px"}} onClick={() => copyURL()}>Copy Client URL</SecondaryButton>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormInputExperience title="Attendees" 
                                            toggle
                                            toggleValue={data.occupancy.value}
                                            toggleAction={() => {toggleOccupancy()}}
                                            toggleTitle="DBL Occupancy"
                                            toggleChecked={occupancyChecked(data.occupancy.value)}
                                            id="attendees" data={data} 
                                            setData={(x) => setData(x)} 
                                            type="number"/>
                            </Col>
                            <Col>
                                <FormInputTripLength title="Trip Length" 
                                            id="tripLength" data={data} 
                                            setData={(x) => setData(x)} 
                                            type="number"/>
                               
                            </Col>
                            <Col lg="2">
                                <FormInputExperience title="Client Currency" 
                                            id="currency" data={data} 
                                            setData={(x) => setData(x)} 
                                            type="select"
                                            options={currencyOptions()}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="2">
                                <FormInputExperience title="Contingency" 
                                            id="contingency" data={data} 
                                            setData={(x) => setData(x)} 
                                            type="number"
                                            contingency
                                            />
                            </Col>
                            {data.overhead !== undefined && <Col lg="2">
                                <FormInputExperience title="Moniker Professional Planning Overhead" 
                                            id="overhead" data={data} 
                                            setData={(x) => setData(x)} 
                                            type="number"
                                            contingency
                                            />
                            </Col>}
                            {data.margin !== undefined && <Col lg="2">
                                <FormInputExperience title="Moniker Profit Margin" 
                                            id="margin" data={data} 
                                            setData={(x) => setData(x)} 
                                            type="number"
                                            contingency
                                            />
                            </Col>}
                        </Row></React.Fragment>}
                        <Row id="tab-header">
                            <Col>
                                {tabKey !== 0 && <TabButton onClick={x => setTabKey(0)}>
                                    Summary
                                </TabButton>}
                                {tabKey === 0 && <ActiveTabButton>
                                    Summary
                                </ActiveTabButton>}
                                {data.experiences.value !== undefined && Object.entries(data.experiences.value).sort((a,b) => {
                                    
                                    return a[1].timeCreated - b[1].timeCreated;
                                }).map((experience, i) => {

                                    if (tabKey !== i+1) {
                                        return (
                                            <TabButton key={i} onClick={x => setTabKey(i+1)}>
                                                {experience[1].title}
                                            </TabButton>
                                        )
                                    } else {
                                        return (
                                            <ActiveTabButton key={i}>
                                                {experience[1].title}
                                            </ActiveTabButton>
                                        )
                                    }
                                })}

                                {!props.client && <TabButton onClick={x => createNewExperience(x)}>
                                        +
                                </TabButton>}
                            </Col>
                        </Row>
                        {tabKey === 0 && <SummaryTabForm guideData={data} />}

                        {data.experiences.value!== undefined && Object.entries(data.experiences.value).sort((a,b) => {
                                
                                return a[1].timeCreated - b[1].timeCreated;
                            }).map((experience, i) => {

                            if (i+1 === tabKey) {
                                return (<ExperienceInputFormTab client={props.client} 
                                            key={i+1} 
                                            guideid={props.id} 
                                            experience={experience} 
                                            guideData={data}
                                            updateGuideDetails={(x) => updateGuideDetails(x)}
                                            setTabKey={x => setTabKey(x)}/>)
                                
                            } 
                        })} 
                    </WhiteForm>
                </Col>
            </Row>
        </React.Fragment>
    )

}
export default ExperienceGuideInput;