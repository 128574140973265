import React, { useState, useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import styled, { createGlobalStyle } from 'styled-components'
import LoadingWheel from '../../assets/images/LoadingWheel.svg';
import { db } from "../../firebase";
import firebase from "firebase/compat/app";
import { setTeamBoolean, setPlayerString, setTeamBooleanArrayValue } from "../../utils/firestore-functions";
import { isMobile } from 'react-device-detect';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => (props.backgroundColor.toString())};
  }
`;

const FinalVerdict = (props) => {
  const [unityIsReady, setUnityIsReady] = useState(false);
  const [progression, setProgression] = useState(0);
  const [loading, setLoading] = useState(true);
  const [teamNumber, setTeamNumber] = useState(0);
  const [gameName, setGameName] = useState("");
    
  /*
  const { unityProvider, sendMessage, addEventListener, isLoaded, loadingProgression } = useUnityContext({
    loaderUrl: "https://" + props.environment + "/final-verdict/chrome/Default%20WebGL.loader.js",
    dataUrl: "https://" + props.environment + "/final-verdict/chrome/Default%20WebGL.data",
    frameworkUrl: "https://" + props.environment + "/final-verdict/chrome/Default%20WebGL.framework.js",
    codeUrl: "https://" + props.environment + "/final-verdict/chrome/Default%20WebGL.wasm",
    streamingAssetsUrl: "https://" + props.environment + "/final-verdict/chrome/StreamingAssets",
  })
  
  */
  const { unityProvider, sendMessage, addEventListener, isLoaded, loadingProgression } = useUnityContext({
    loaderUrl: "https://cdn.moniker-virtual.com/final-verdict/chrome/webgl.loader.js",
    dataUrl: "https://cdn.moniker-virtual.com/final-verdict/chrome/webgl.data",
    frameworkUrl: "https://cdn.moniker-virtual.com/final-verdict/chrome/webgl.framework.js",
    codeUrl: "https://cdn.moniker-virtual.com/final-verdict/chrome/webgl.wasm",
    streamingAssetsUrl: "https://cdn.moniker-virtual.com/final-verdict/chrome/StreamingAssets",
  });

  useEffect(() => {
    if (isLoaded){
      setUnityIsReady(true);
    }
  }, [isLoaded]);

  useEffect(
    () => {
      if (unityIsReady){

        console.log("Unity is loaded");
        sendMessage("React Communicator", "ReactLoaded");
      
        /*

        addEventListener("ReactGetURLInformation", () => {
          console.log("React Get Url Information being called");
          sendMessage(
            "ReactCommunicator", 
            "ReactListener", 
            ("SetURLParameters" + "*" + collection + "*" + "gameSettings")
          );
        })

        */

        addEventListener("ReactSetTeamNotes", (information) => {
          var strings = information.split("*");
          var gameNameSuffix = strings[0];
          var teamName = strings[1]; 
          //var playerName = strings[2]; This is done client side now already.
          var note = strings[3]; 
          const gameName = "FinalVerdict" + gameNameSuffix;
          return db.runTransaction((transaction) => {
              return transaction.get(db.collection(gameName).doc(teamName)).then((document) => {
                  var obj = document.data();
                  obj.notes.push(note);
                  transaction.update(db.collection(gameName).doc(teamName), {
                      ...obj,
                  });
              })
          }).then(() => {
              
          }).catch((error) => {
              console.log(error);
          })
        })
        
        addEventListener("ReactSetTeamBoolean", (information) => {
          setTeamBoolean(information);
        });
        
        addEventListener("ReactSetTeamBooleanArrayValue", (information) => {
          console.log(information);
          let strings = information.split("*");
          var gameNameSuffix = strings[0];
          var teamName = strings[1]; //team name
          var array = strings[2]; //array name
          var index = strings[3]; //index of value to change"
          var value = strings[4]; //true or false"
          const gameName = "FinalVerdict" + gameNameSuffix;
          db.collection(gameName).doc(teamName).get().then(doc => {
            if (doc.data() !== undefined && doc.data().toString() !== ""){
              var obj = doc.data();
              let count = 0;
              obj[array].map((el) => {
                if (el === true){
                  count++;
                }
              })
              if (count < 3){
                setTeamBooleanArrayValue(information);
              }
            }
          });
        });
        
        addEventListener("ReactSetPlayerString", (information) => {
          setPlayerString(information);
        });
        
        addEventListener("ReactGetServerUTC", () => {
          fetch("https://us-central1-escape-room-1980b.cloudfunctions.net/getUTCTime", {
                "method": "GET",
                "headers": {
                }
              })
              .then(response => response.json())
              .then(response => {
                sendMessage(
                  "React Communicator", 
                  "ReturnServerUTC", 
                  response.result.toString()
                );
              })
              .catch(err => { console.log(err); 
              });
        });
        
        addEventListener("ReactCopyToClipboard", (stringToCopy) => {
          navigator.clipboard.writeText(stringToCopy);
        });
        
        addEventListener("ReactGetFinalVerdictGames", () => {
          db.collection("FinalVerdictAdminSettings").doc("gameNames").get().then(doc => {
            if (doc.data() !== undefined && doc.data().toString() !== ""){
              var obj = doc.data();
              sendMessage(
                "Login Manager", 
                "ReturnFinalVerdictGameNames", 
                JSON.stringify(obj)
              );
            }
          });
        });
        
        addEventListener("ReactGetFinalVerdictTeams", (gameNameSuffix) => {
          var gameName = "FinalVerdict" + gameNameSuffix;
          db.collection(gameName).doc("gameSettings").get().then(doc => {
            if (doc.data() !== undefined && doc.data().toString() !== ""){
              var obj = doc.data();
              sendMessage(
                "Login Manager", 
                "ReturnFinalVerdictTeamNames", 
                JSON.stringify(obj)
              );
            }
          });
        });
        
        addEventListener("ReactGetFinalVerdictPlayers", (data) => {
          var strings = data.split("*");
          var gameName = "FinalVerdict" + strings[0];
          var teamName = strings[1];
          db.collection(gameName).doc(teamName).get().then(doc => {
            if (doc.data() !== undefined && doc.data().toString() !== ""){
              var obj = doc.data();
              sendMessage(
                "Login Manager", 
                "ReturnFinalVerdictPlayerNames", 
                JSON.stringify(obj)
              );
            }
          });
        });
        
        addEventListener("ReactSendString", (data) => {
          var strings = data.split("*");
          var message = strings[0];
          var type = strings[1];
          if (type === "database search"){
            db.collection("FinalVerdictAdminSettings").doc("databaseSearches").update({
              searches: firebase.firestore.FieldValue.arrayUnion(message)
            });
          }
        });
        
        addEventListener("ReactRequestAppointment", (appointmentData) => {
          var strings = appointmentData.split("*");
          var gameName = "FinalVerdict" + strings[0];
          var teamName = strings[1]; //team name
          var appointmentSlot = parseInt(strings[2]); // number?
          var appointmentType = strings[3]; //"defendant or prosecutor"
          //TODO: Get prosecutor or defendant schedule object
          //TODO: Look at object to see if this team has selected
          //TODO: Look at object to see if the time slot is free
          db.runTransaction(function(transaction) {
            return transaction.get(db.collection(gameName).doc("gameSettings")).then(function(doc) {
              if (doc.exists){
                var d = doc.data();
                var obj = {};
                if (appointmentType === "defendant"){
                  obj = d.defendantSchedule;
                }
                else if (appointmentType === "prosecutor"){
                  obj = d.prosecutorSchedule;
                }
                else{
                  console.log(appointmentType + " was found, but we were expecting 'defendant' or 'prosecutor'");
                }
                var teamAlreadyHasSpot = false;
                var spotIsAlreadyTaken = false;
                Object.entries(obj).map(([key,value]) => {
                  if (value === teamName){
                    teamAlreadyHasSpot = true;
                  }
                })
                if (obj[appointmentSlot] !== ""){
                  spotIsAlreadyTaken = true;
                }
                
                if (!teamAlreadyHasSpot && !spotIsAlreadyTaken){
                  obj[appointmentSlot] = teamName;
                  if (appointmentType === "defendant"){
                    transaction.update(db.collection(gameName).doc("gameSettings"), {
                      ...d,
                      ["defendantSchedule." + appointmentSlot]:teamName
                    })
                  }
                  else if (appointmentType === "prosecutor"){
                    transaction.update(db.collection(gameName).doc("gameSettings"), {
                      ...d,
                      ["prosecutorSchedule." + appointmentSlot]:teamName
                    })
                  }
                }
              }
              else{
                console.log("Doc does not exist when trying to request react appointment with data: " + appointmentData);
              }
            });
          });
        });
        
        addEventListener("ReactGetGameInformation", gameNameSuffix => {
          var gameName = "FinalVerdict" + gameNameSuffix;
          db.collection(gameName).doc("gameSettings").onSnapshot((querySnapshot) => {
            if (querySnapshot.data() !== undefined && querySnapshot.data().toString() !== ""){
              var obj = querySnapshot.data();
              sendMessage(
                "React Communicator", 
                "ReturnGameData", 
                JSON.stringify(obj)
              );
            }
            else{
              sendMessage(
                "React Communicator", 
                "GameNotFound"
              );
            }
          });
        });
        
        addEventListener("ReactGetTeamInformation", teamInformation => {
          var strings = teamInformation.split("*");
          var gameName = "FinalVerdict" + strings[0];
          var teamNumber = strings[1];
          var playerName = strings[2];
          //var passcode = strings[3];
          db.collection(gameName).doc(teamNumber).onSnapshot((querySnapshot) => {
            if (querySnapshot.data() !== undefined && querySnapshot.data().toString() !== ""){
              var obj = querySnapshot.data();
              if (obj.playerInformation[playerName] !== undefined){
                sendMessage(
                  "React Communicator", 
                  "ReturnTeamData", 
                  JSON.stringify(obj)
                );
              }
              else{
                sendMessage(
                  "React Communicator", 
                  "TeamNotFound"
                );
              }
            }
            else{
              sendMessage(
                "React Communicator", 
                "TeamNotFound"
              );
            }
          });
        });
      
        addEventListener("progress", progress => {
          if (progress >= 1){
            setLoading(false)
          }
            setProgression(progress);
        });
      
        addEventListener("CheckTeamPasscode", teamInformation => {
          var strings = teamInformation.split(",");
          var teamNumber = strings[0];
          var teamPasscode = strings[1];
          var gameName = strings[2];
          console.log("Passing gameName: " + gameName + " teamID: " + teamNumber + " and code: " + teamPasscode);
          db.collection(gameName).doc(teamNumber)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.data() !== undefined && querySnapshot.data().toString() !== ""){
              var obj = querySnapshot.data();
              console.log("Checking Team Passcode" + JSON.stringify(obj));
              sendMessage(
                "React Communicator", 
                "ReturnTeamData", 
                JSON.stringify(obj)
              );
            }
            else{
              sendMessage(
                "React Communicator", 
                "TeamNotFound"
              );
            }
          });
        });
      
        addEventListener("ListenToTeam", teamInformation => {
          var strings = teamInformation.split(",");
          var teamNumber = strings[0];
          var gameName = strings[1];
          setGameName(gameName);
          setTeamNumber(teamNumber);
          db.collection(gameName).doc(teamNumber).onSnapshot(function(doc) 
          {
            if (doc.data != null){
              sendMessage(
                "React Communicator", 
                "UpdateTeamData", 
                JSON.stringify(doc.data())
              );
            }
            else{
              sendMessage(
                "React Communicator",
                "TeamNotFound"
              );
            }
          });
        });
      
        addEventListener("ListenToGame", gameName => {
          setGameName(gameName);
          db.collection(gameName).doc("gameSettings").onSnapshot(function(doc) 
          {
            if (doc.data != null){
              sendMessage(
                "React Communicator",  
                "UpdateGameData", 
                JSON.stringify(doc.data())
              );
            }
            else{
              sendMessage(
                "React Communicator",
                "GameNotFound"
              );
            }
          });
        });
      
        addEventListener("CheckAdminPasscode", gameInformation => {
          var strings = gameInformation.split(",");
          var gameName = strings[1];
          db.collection(gameName).doc("gameSettings")
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.data() !== undefined && querySnapshot.data().toString() !== ""){
              var obj = querySnapshot.data();
              sendMessage(
                "React Communicator", 
                "ReturnAdminData", 
                JSON.stringify(obj)
              );
            }
            else{
              sendMessage(
                "React Communicator", 
                "AdminNotFound"
              );
            }
          });
        });
      
        addEventListener("ReactUpdateScore", scoreInformation => {
          var strings = scoreInformation.split(",");
          var score = strings[1];
          var strategy = strings[2];
          db.runTransaction(function(transaction) {
            return transaction.get(db.collection(gameName).doc("gameSettings")).then(function(doc) {
              if (doc.exists){
                var d = doc.data();
                var obj = d.teamScores;
                if (obj[teamNumber] === undefined || obj[teamNumber].toString() === ""){
                  obj[teamNumber] = { score, strategy };
                  transaction.update(db.collection(gameName).doc("gameSettings"), {
                    teamScores: obj
                  });
                  transaction.update(db.collection(gameName).doc(teamNumber), {
                    hasFinished: true
                  });
                }
                console.log("Score Updated");
              }
              else{
                console.log("Failed when trying to update score for, score passed in: " + score);
              }
            });
          });
        })
      
        addEventListener("ReactUpdateItems", itemsInformation => {
          var strings = itemsInformation.split("*");
          var passcode = strings[1]
          var jsonItems = strings[2];
          var _strategy = strings[3];
          var obj = JSON.parse(jsonItems);
          console.log(obj);
          db.runTransaction(function(transaction) {
            return transaction.get(db.collection(gameName).doc(teamNumber)).then(function(doc) {
              if (doc.exists){
                var d = doc.data();
                if (d.passcode === passcode){
                  transaction.update(db.collection(gameName).doc(teamNumber),{
                    strategy: _strategy,
                    items: obj
                  });
                }
                else{
                  console.log("Passcodes don't match.");
                }
              }
              else{
                console.log("In React Update Items, could not find document for teamNumber: " + teamNumber + " and gameName: " + gameName + " trying to pass items: " + jsonItems);
              }
            });
          });
        });
    }
  }, [unityProvider, unityIsReady],
  );

  
  
  return (
      <FullHeightDiv>
        <GlobalStyle backgroundColor={"black"} />
        {isMobile && 
        <CenterDiv>
          <WhiteP>Please use a desktop browser to play</WhiteP>
        </CenterDiv>}
        {!isMobile && !isLoaded && <LoadingDiv style={{backgroundImage: "url(https://cdn.moniker-virtual.com/final-verdict/Images/Final-Verdict-Loading-Screen.png)"}}>
          <LoadingP>Loading {(loadingProgression * 100).toFixed(0)}%</LoadingP>
          <LoadingWheelDiv style={{backgroundImage: "url(" + LoadingWheel + ")"}}></LoadingWheelDiv>
        </LoadingDiv>}
        {!isMobile && <Unity unityProvider={unityProvider} style={{width:"100%", height:"100%", position:"absolute", visibility: isLoaded ? "visible" : "hidden"}}/>}
      </FullHeightDiv>
  );
}

export default FinalVerdict;

const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`;

const WhiteP = styled.h3`
  color: white;
`;

const FullHeightDiv = styled.div`
  height: 99vh;
`;

const LoadingP = styled.p`
  text-align: center;
  font-size: 40px;
  color: white;
  background-image: radial-gradient(hsla(267,10%,18%,0.99),hsla(267,10%,40%,0));
  vertical-align: middle;
`;

const LoadingWheelDiv = styled.div`
  display: inline-block;
  text-align: center;
  height: 16px;
  width: 128px;
  z-index: 1;
  vertical-align: middle;
`;

const LoadingDiv = styled.div`
  background-size: cover;
  height: 100%;
  display: inline-block;
  width: 100%;
  text-align: center;
  position: absolute;
  justify-content: center;
  vertical-align: middle;
  object-fit: fill;
`;