import { Button, Container, Row, Col, InputGroup, InputGroupAddon, Input, InputGroupText } from "reactstrap";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FinalVerdictAdminPlayer from "../final-verdict/final-verdict-admin-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addPlayer, deleteTeam, renameTeam, getTeam, resetTeam, setTeamString, setTeamBoolean } from "../../utils/firestore-functions";

const countPlayers = (guilt, players) => {
    var ctr = 0;
    var entries = Object.entries(players);
    entries.forEach(p => {
        if (p[1].vote === guilt){
            ctr++;
        }
    })
    return ctr;
}

const copyTextToClipboard = (text) => {
    var textArea = document.createElement("textarea");
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copying text command was ' + msg);
    } catch (err) {
      console.log('Oops, unable to copy');
    }
    document.body.removeChild(textArea);
}

const FinalVerdictAdminTeam = (props) => {
    const [team, setTeam] = useState({});
    const [players, setPlayers] = useState({});
    const [playerNameInput, setPlayerNameInput] = useState("");
    const [teamNameInput, setTeamNameInput] = useState("");
    const [googleSheetInput, setGoogleSheetInput] = useState("");
    const [playersToggle, setPlayersToggle] = useState(false);
    const [addPlayerMessage, setAddPlayerMessage] = useState("");
    const [renameTeamMessage, setRenameTeamMessage] = useState("");

    useEffect(() => {
        var unsubscribe = getTeam(props.gameName, props.teamName, setTeam, setPlayers);
        return unsubscribe;
    }, []);

    const togglePlayers = () => setPlayersToggle(!playersToggle);

    return(
        <TeamPanel>
            <Container>
                <Row>
                    <Col xs="3">
                        <p>Team Name: {props.teamName}</p>
                    </Col>
                    <Col xs="3">
                        <InputGroup style={{"width":"100%"}}>
                            <Input value={teamNameInput} placeholder={"new team name"} onChange={e => setTeamNameInput(e.target.value)}>
                            </Input>
                            <InputGroupAddon addonType="append">
                                <Button color="primary" onClick={() => renameTeam(props.gameName, props.teamName, teamNameInput, team, setRenameTeamMessage)}>
                                    <FAIcon icon={["fas", "edit"]}></FAIcon>
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                    <Col xs="3">
                        <Button style={{"width":"100%"}} color="warning" onClick={() => resetTeam(props.gameName, props.teamName)}>
                            <FAIconButton icon={["fas", "exclamation-circle"]}></FAIconButton>
                            Reset Team
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs="2">
                        <p>Guilty:</p>
                        <p>Not Guilty:</p>
                    </Col>
                    <Col xs="1">
                        <p>{players !== undefined && countPlayers("guilty", players)}</p>
                        <p>{players !== undefined && countPlayers("not guilty", players)}</p>
                    </Col>
                    <Col xs="3">
                        <InputGroup style={{"width":"100%"}}>
                            <Input value={playerNameInput} placeholder={"new player name"} onChange={e => setPlayerNameInput(e.target.value)}>
                            </Input>
                            <InputGroupAddon addonType="append">
                                <Button color="primary" onClick={() => addPlayer(props.gameName, props.teamName, playerNameInput, setAddPlayerMessage)}>
                                    <FAIcon icon={["fas", "user-plus"]}></FAIcon>
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                    <Col>
                        <p>{addPlayerMessage}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs="3">
                        <p>Found Fingerprint: {Object.keys(team).length !== 0 && team.foundFingerprint ? 
                            <FAIcon size="lg" icon={["fas", "toggle-on"]} onClick={() => setTeamBoolean(props.gameName + "*" + props.teamName + "*" + "foundFingerprint" + "*" + !team.foundFingerprint)}></FAIcon> : 
                            <FAIcon size="lg" icon={["fas", "toggle-off"]} onClick={() => setTeamBoolean(props.gameName + "*" + props.teamName + "*" + "foundFingerprint" + "*" + !team.foundFingerprint)}></FAIcon>}
                        </p>
                    </Col>
                    <Col xs="3">
                        <p>Opened Computer: {Object.keys(team).length !== 0 && team.openedComputer ? 
                            <FAIcon size="lg" icon={["fas", "toggle-on"]} onClick={() => setTeamBoolean(props.gameName + "*" + props.teamName + "*" + "openedComputer" + "*" + !team.openedComputer)}></FAIcon> : 
                            <FAIcon size="lg" icon={["fas", "toggle-off"]} onClick={() => setTeamBoolean(props.gameName + "*" + props.teamName + "*" + "openedComputer" + "*" + !team.openedComputer)}></FAIcon>}
                        </p>
                    </Col>
                    <Col xs="3">
                        <p>Opened Folder: {Object.keys(team).length !== 0 && team.openedFolder ? 
                            <FAIcon size="lg" icon={["fas", "toggle-on"]} onClick={() => setTeamBoolean(props.gameName + "*" + props.teamName + "*" + "openedFolder" + "*" + !team.openedFolder)}></FAIcon> : 
                            <FAIcon size="lg" icon={["fas", "toggle-off"]} onClick={() => setTeamBoolean(props.gameName + "*" + props.teamName + "*" + "openedFolder" + "*" + !team.openedFolder)}></FAIcon>}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs="3">
                        <p>Prosecutor Videos: {Object.keys(team).length !== 0 && team.prosecutorVideos !== undefined && team.prosecutorVideos.map((video, index) => {
                            return (<div>
                                <p>{index + 1}: {video.toString()}</p>
                            </div>)
                        })}</p>
                    </Col>
                    <Col xs="3">
                        <p>Defendant Videos: {Object.keys(team).length !== 0 && team.defendantVideos !== undefined && team.defendantVideos.map((video, index) => {
                            return (<div>
                                <p>{index + 1}: {video.toString()}</p>
                            </div>)
                        })}</p>
                    </Col>
                </Row>
                {/*
                <Row>
                    <Col>
                        Team URL
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <Button color="primary" onClick={() => copyTextToClipboard("https://moniker-virtual.com/final-verdict-launch/"+ props.gameName + "/" + props.teamName)}>
                                    <FAIcon icon={["fas", "copy"]}></FAIcon>
                                </Button>
                            </InputGroupAddon>
                            <InputGroupAddon addonType="append">
                                <InputGroupText>https://moniker-virtual.com/final-verdict-launch/{props.gameName}/{props.teamName}</InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Row>
                */}
                <Row>
                    <Col>
                        Team Link
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <Button color="primary" onClick={() => copyTextToClipboard(team.googleSheetLink.toString())}>
                                    <FAIcon icon={["fas", "copy"]}></FAIcon>
                                </Button>
                            </InputGroupAddon>
                            <InputGroupAddon addonType="append">
                                <InputGroupText>{Object.keys(team).length !== 0 && team.googleSheetLink.toString()}</InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputGroup style={{"width":"100%"}}>
                            <Input value={googleSheetInput} placeholder={"team link"} onChange={e => setGoogleSheetInput(e.target.value)}>
                            </Input>
                            <InputGroupAddon addonType="append">
                                <Button color="primary" onClick={() => setTeamString(props.gameName + "*" + props.teamName  + "*" + "googleSheetLink" + "*" +  googleSheetInput)}>
                                    <FAIcon icon={["fas", "edit"]}></FAIcon>
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Row>
                <br></br>
                <Row>
                    <Col xs="3">
                    </Col>
                    <Col xs="3">
                        {Object.keys(players).length === 0 && 
                        <Button style={{"width":"100%"}} color="danger" onClick={() => deleteTeam(props.gameName, props.teamName)}>
                            <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                            Delete Team
                        </Button>}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button outline color="primary" onClick={() => togglePlayers()}>
                            {playersToggle ? <FAIconButton icon={["fas", "chevron-up"]}></FAIconButton> : <FAIconButton icon={["fas", "chevron-down"]}></FAIconButton>}
                            PLAYERS - {Object.keys(players).length}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {playersToggle && Object.keys(players).length > 0 && <PlayerPanel>
                            {Object.entries(players).sort().map((p) => (
                                <FinalVerdictAdminPlayer key={p[0]} gameName={props.gameName} teamName={props.teamName} playerName={p[0]} passcode={p[1].passcode} vote={p[1].vote}></FinalVerdictAdminPlayer>
                            ))}
                        </PlayerPanel>}
                    </Col>
                </Row>
            </Container>
        </TeamPanel>
    )
}

const FAIcon = styled(FontAwesomeIcon)`
    
`;

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
`;

const TeamPanel = styled.div`
    margin:10px;
`;

const PlayerPanel = styled.div`
    
`;

export default FinalVerdictAdminTeam