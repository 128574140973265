import { Container, Row, Col, Button, InputGroup, InputGroupAddon, Input } from "reactstrap";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FinalVerdictAdminTeam from "../final-verdict/final-verdict-admin-team";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addTeam, deleteGame, getGameSettings, getUTCTime, resetGame } from "../../utils/firestore-functions";
import { convertIntToScheduleTime } from "../../utils/final-verdict-specific";
import Timer from "../../utils/timer";

const FinalVerdictFacilitatorGame = (props) => {
    const [teamsToggle, setTeamsToggle] = useState(false);
    const [teamNameInput, setTeamNameInput] = useState("");
    const [addTeamMessage, setAddTeamMessage] = useState("");
    const [gameSettings, setGameSettings] = useState({});

    const toggleTeam = () => {
        setTeamsToggle(!teamsToggle);
    }

    useEffect(() => {
        var unsubscribe = getGameSettings(props.gameName, setGameSettings);
        return unsubscribe;
    }, []);

    return(
        <React.Fragment>
            <Container>
                <GamePanel>
                <Row>
                    <Col xs="3">
                        <p>Game Name: {props.gameName}</p>
                    </Col>
                    <Col xs="3">
                        <p>Has Game Started: {Object.keys(gameSettings).length !== 0 && gameSettings.hasGameStarted.toString()}</p>
                    </Col>
                    <Col xs="3">
                        <p>{Object.keys(gameSettings).length !== 0 && gameSettings.hasGameStarted && <Timer startTime={gameSettings.startTime} duration={4500000} />}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button color="primary" onClick={() => getUTCTime(props.gameName)}>
                            Start 75 min Game
                        </Button>
                    </Col>
                    <Col>
                        <Button color="primary" onClick={() => getUTCTime(props.gameName, 15)}>
                            Start 60 min Game
                        </Button>
                    </Col>
                    <Col>
                        <Button color="primary" onClick={() => getUTCTime(props.gameName, 20)}>
                            Start 55 min Game
                        </Button>
                    </Col>
                    <Col>
                        <Button color="primary" onClick={() => getUTCTime(props.gameName, 25)}>
                            Start 50 min Game
                        </Button>
                    </Col>
                    <Col>
                        <Button color="warning" onClick={() => resetGame(props.gameName)}>
                            <FAIconButton icon={["fas", "exclamation-circle"]}></FAIconButton>
                            Reset Game
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs="3">
                        {gameSettings.length === 0 && 
                        <Button style={{"width":"100%"}} color="danger" onClick={() => deleteGame(props.gameName, props.teamName)}>
                            <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                            Delete Game
                        </Button>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col xs="3">
                        <InputGroup style={{"width":"100%"}}>
                            <Input value={teamNameInput} placeholder={"team name"} onChange={e => setTeamNameInput(e.target.value)}>
                            </Input>
                            <InputGroupAddon addonType="append">
                                <Button color="primary" onClick={() => addTeam(props.gameName, teamNameInput, setAddTeamMessage)}>
                                    <FAIcon icon={["fas", "user-plus"]}></FAIcon>
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                    <Col>
                        <p>{addTeamMessage}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs="3">
                        <p>Defendant Time Slots</p>
                        <ULNoBullet>
                            {Object.keys(gameSettings).length !== 0 && Object.entries(gameSettings.defendantSchedule).map(([key, value]) => {
                                return (<li>{convertIntToScheduleTime(parseInt(key))} : {value}</li>)
                            })}
                        </ULNoBullet>
                    </Col>
                    <Col>
                        <p>Prosecutor Time Slots</p>
                        <ULNoBullet>
                            {Object.keys(gameSettings).length !== 0 && Object.entries(gameSettings.prosecutorSchedule).map(([key, value]) => {
                                return (<li>{convertIntToScheduleTime(parseInt(key))} : {value}</li>)
                            })}
                        </ULNoBullet>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button outline color="primary" onClick={() => toggleTeam()}>
                            {teamsToggle ? <FAIconButton icon={["fas", "chevron-up"]}></FAIconButton> : <FAIconButton icon={["fas", "chevron-down"]}></FAIconButton>}
                            TEAMS - {Object.keys(gameSettings).length !== 0 && Object.keys(gameSettings.teamNames).length}
                        </Button>
                    </Col>
                </Row>
                {teamsToggle && gameSettings.teamNames && Object.entries(gameSettings.teamNames).sort(function(a,b){ return a[1]-b[1]}).map(teamName => (
                    <SpacedRow>
                        <Col>
                            <TeamPanel>
                                <FinalVerdictAdminTeam key={teamName[0]} gameName={props.gameName} teamName={teamName[1]}></FinalVerdictAdminTeam>
                            </TeamPanel>
                        </Col>
                    </SpacedRow>
                ))}
                </GamePanel>
            </Container>
        </React.Fragment>
    )
}

const ULNoBullet = styled.ul`
    list-style-type: none;
`;

const FAIcon = styled(FontAwesomeIcon)`
    
`;

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
`;

const SpacedRow = styled(Row)`
    margin: 10px;
`;

const GamePanel = styled.div`
    height: 100%;
    width: 100%;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    margin: 3px;
    padding: 3px;
    background-color: rgb(242, 254, 255);
    box-shadow: 5px 5px #888888;
`;

const TeamPanel = styled.div`
    height: 100%;
    width: 100%;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    margin: 5px;
    padding: 5px;
    background-color: rgb(242, 254, 255);
    box-shadow: 5px 5px #888888;
`;

export default FinalVerdictFacilitatorGame