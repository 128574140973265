import { initializeApp } from "firebase/app"
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from 'uuid';

const firebaseApp = initializeApp({
    apiKey: "AIzaSyBNl_KcAQP3DfNewRHy38vKN1KgEampntg",
    authDomain: "holiday-house-escape.firebaseapp.com",
    projectId: "holiday-house-escape",
    storageBucket: "holiday-house-escape.appspot.com",
    messagingSenderId: "952221137117",
    appId: "1:952221137117:web:d326fac552517845134334",
    measurementId: "G-5GNH9SLZN2"
}, "holiday-house");

//firebase.firestore().settings({ experimentalForceLongPolling: true });

const storage = getStorage(firebaseApp);
const storageRef = ref;

export const generateGUID = uuidv4;
export const holidayHouseDB = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);

export { storage, storageRef, uploadBytesResumable, getDownloadURL, deleteObject };