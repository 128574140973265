import React from "react";
import styled, { createGlobalStyle } from 'styled-components'
import { db, auth } from "../firebase";
import OmegaProtocolGame from '../components/omegaProtocolGame';
import { Button } from 'reactstrap';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => (props.backgroundColor.toString())};
  }
`;

class MonikerAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        omegaProtocolDelta: {
            gameSettings : {},
            teams: {},
            gameName: "omegaProtocolDelta"
        },
        omegaProtocolEcho: {
            gameSettings : {},
            teams: {},
            gameName: "omegaProtocolEcho"
        },
        omegaProtocolGamma: {
            gameSettings : {},
            teams: {},
            gameName: "omegaProtocolGamma"
        },
        omegaProtocolTheta: {
            gameSettings : {},
            teams: {},
            gameName: "omegaProtocolTheta"
        },
        omegaProtocolPi: {
            gameSettings : {},
            teams: {},
            gameName: "omegaProtocolPi"
        }
    };
  };

  addTeam(number, gameName) {
    var pass = "";
    for (var i = 0; i < 4; i++){
        pass += (Math.floor(Math.random() * 9) + 1).toString();
    }
    console.log("Adding Team" + number);
    db.collection(gameName)
    .doc(number.toString()).set({
        locks: { 
            basementDoor : false, 
            cabinConsole : false, 
            cabinDoor : false, 
            cabinFridge : false, 
            houseDoor : false,
            labFridge : false, 
            librarySafe : false, 
            masterBedroomDrawer : false,
            bathroomMeds : false,
            outsideCabin: false,
        },
        chemicals: {
            chemical1 : false, 
            chemical2 : false, 
            chemical3 : false, 
            chemical4 : false, 
            chemical5 : false
        },
        hasStarted: false,
        startTime: "",
        endTime: "",
        gameWon: false,
        password: pass
    })
    .then(() => {
        console.log("Team successfully added!");
    })
    .catch(error => {
        console.error("Error adding team: ", error);
    });
  }

  componentDidMount() {
    auth.signInAnonymously().catch(function(error) {
    });
    var _this = this;
    db.collection("omegaProtocolDelta").onSnapshot(function(docs) {
        var obj = {};
        docs.forEach(doc => {
            if (doc.id.toString() === "gameSettings"){
                _this.setState(state => ({
                    omegaProtocolDelta: {
                        ...state.omegaProtocolDelta,
                        gameSettings: doc.data()
                    }
                }));
            }
            else{
                obj = {};
                obj = _this.state.omegaProtocolDelta.teams;
                obj[doc.id] = doc.data();
                _this.setState(state => ({
                    omegaProtocolDelta: {
                        ...state.omegaProtocolDelta,
                        teams : obj
                    }
                }));
            }
        })
    });
    db.collection("omegaProtocolEcho").onSnapshot(function(docs) {
        var obj = {};
        docs.forEach(doc => {
            if (doc.id.toString() === "gameSettings"){
                _this.setState(state => ({
                    omegaProtocolEcho: {
                        ...state.omegaProtocolEcho,
                        gameSettings: doc.data()
                    }
                }));
            }
            else{
                obj = {};
                obj = _this.state.omegaProtocolEcho.teams;
                obj[doc.id] = doc.data();
                _this.setState(state => ({
                    omegaProtocolEcho: {
                        ...state.omegaProtocolEcho,
                        teams : obj
                    }
                }));
            }
        })
    });
    db.collection("omegaProtocolGamma").onSnapshot(function(docs) {
        var obj = {};
        docs.forEach(doc => {
            if (doc.id.toString() === "gameSettings"){
                _this.setState(state => ({
                    omegaProtocolGamma: {
                        ...state.omegaProtocolGamma,
                        gameSettings: doc.data()
                    }
                }));
            }
            else{
                obj = {};
                obj = _this.state.omegaProtocolGamma.teams;
                obj[doc.id] = doc.data();
                _this.setState(state => ({
                    omegaProtocolGamma: {
                        ...state.omegaProtocolGamma,
                        teams : obj
                    }
                }));
            }
        })
    });
    db.collection("omegaProtocolTheta").onSnapshot(function(docs) {
        var obj = {};
        docs.forEach(doc => {
            if (doc.id.toString() === "gameSettings"){
                _this.setState(state => ({
                    omegaProtocolTheta: {
                        ...state.omegaProtocolTheta,
                        gameSettings: doc.data()
                    }
                }));
            }
            else{
                obj = {};
                obj = _this.state.omegaProtocolTheta.teams;
                obj[doc.id] = doc.data();
                _this.setState(state => ({
                    omegaProtocolTheta: {
                        ...state.omegaProtocolTheta,
                        teams : obj
                    }
                }));
            }
        })
    });
    db.collection("omegaProtocolPi").onSnapshot(function(docs) {
        var obj = {};
        docs.forEach(doc => {
            if (doc.id.toString() === "gameSettings"){
                _this.setState(state => ({
                    omegaProtocolPi: {
                        ...state.omegaProtocolPi,
                        gameSettings: doc.data()
                    }
                }));
            }
            else{
                obj = {};
                obj = _this.state.omegaProtocolPi.teams;
                obj[doc.id] = doc.data();
                _this.setState(state => ({
                    omegaProtocolPi: {
                        ...state.omegaProtocolPi,
                        teams : obj
                    }
                }));
            }
        })
    });
  };

  render() {
    return(
        <div>
            <GlobalStyle backgroundColor={"black"} />
            {this.state.omegaProtocolDelta && <OmegaProtocolGameDiv>
                <OmegaProtocolGame gameInformation={this.state.omegaProtocolDelta} gameName="omegaProtocolDelta"></OmegaProtocolGame>
                <Button onClick={this.addTeam.bind(this, Object.keys(this.state.omegaProtocolDelta.teams).length + 1, "omegaProtocolDelta")}>Add Team {Object.keys(this.state.omegaProtocolDelta.teams).length + 1}</Button>
            </OmegaProtocolGameDiv>}
            <br></br>
            {this.state.omegaProtocolEcho && <OmegaProtocolGameDiv>
                <OmegaProtocolGame gameInformation={this.state.omegaProtocolEcho} gameName="omegaProtocolEcho"></OmegaProtocolGame>
                <Button onClick={this.addTeam.bind(this, Object.keys(this.state.omegaProtocolEcho.teams).length + 1, "omegaProtocolEcho")}>Add Team {Object.keys(this.state.omegaProtocolEcho.teams).length + 1}</Button>
            </OmegaProtocolGameDiv>}
            <br></br>
            {this.state.omegaProtocolGamma && <OmegaProtocolGameDiv>
                <OmegaProtocolGame gameInformation={this.state.omegaProtocolGamma} gameName="omegaProtocolGamma"></OmegaProtocolGame>
                <Button onClick={this.addTeam.bind(this, Object.keys(this.state.omegaProtocolGamma.teams).length + 1, "omegaProtocolGamma")}>Add Team {Object.keys(this.state.omegaProtocolGamma.teams).length + 1}</Button>
            </OmegaProtocolGameDiv>}
            <br></br>
            {this.state.omegaProtocolTheta && <OmegaProtocolGameDiv>
                <OmegaProtocolGame gameInformation={this.state.omegaProtocolTheta} gameName="omegaProtocolTheta"></OmegaProtocolGame>
                <Button onClick={this.addTeam.bind(this, Object.keys(this.state.omegaProtocolTheta.teams).length + 1, "omegaProtocolTheta")}>Add Team {Object.keys(this.state.omegaProtocolTheta.teams).length + 1}</Button>
            </OmegaProtocolGameDiv>}
            <br></br>
            {this.state.omegaProtocolPi && <OmegaProtocolGameDiv>
                <OmegaProtocolGame gameInformation={this.state.omegaProtocolPi} gameName="omegaProtocolPi"></OmegaProtocolGame>
                <Button onClick={this.addTeam.bind(this, Object.keys(this.state.omegaProtocolPi.teams).length + 1, "omegaProtocolPi")}>Add Team {Object.keys(this.state.omegaProtocolPi.teams).length + 1}</Button>
            </OmegaProtocolGameDiv>}
        </div>
    )
  }
}

export default MonikerAdmin

const OmegaProtocolGameDiv = styled.div`
    text-align: center;
    background-color: white;
    text-align: center;
    border: 2px solid black;
    border-radius: 5px;
`;