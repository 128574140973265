import { Container, Col, Row, Table, Collapse } from 'reactstrap';
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { artemisDB } from "../../../../utils/database/firebase-artemis";
import { listenToDocument } from "../../../../utils/firestore-functions";
import ArtemisScoreScreenItem from './artemis-score-screen-item';
import ArtemisScoreScreenEvent from './artemis-score-screen-event';

export const ArtemisScores = (props) => {
    
    const [gameDetails, setGameDetails] = useState([]);
    const [ctr, setCtr] = useState(0);
    const [isOpenIdealStrategy, setIsOpenIdealStrategy] = useState(false);
    const [isOpenEvents, setIsOpenEvents] = useState(false);
    const [isOpenItems, setIsOpenItems] = useState(false);
    const [isOpenTeams, setIsOpenTeams] = useState(false);
    const items = [
        {name: "Compass", description: "Zero Use"},
        {name: "50 Ft Nylon Rope", description: "Zero use in stay-put scenario"},
        {name: "Inflatable Life Raft", description: "Considering you don't intend to step outside of the landing module, there is no use for the inflatable life raft"},
        {name: "Utility Knife", description: "Minimal use, while there may be limited applications within the module, it may pose a risk to the space suits in cramped quarters."},
        {name: "Flare", description: "Unlikely to hear or see the drone from inside the vessel"},
        {name: "Lunar Map", description: "May prove useful in helping direct the rescue crew to your landing site"},
        {name: "Space Suit Repair Kit", description: "You will want to ensure that you maintain the integrity of your spacesuits"},
        {name: "Solar-Powered Lights", description: "Will conserve your lander's limited electricity reserve and divert the energy from more priority functions such as life-suppoty and heating/cooling"},
        {name: "First Aid Kit", description: "Having painkillers and minor first-aid items on hand would prove useful, as your team will need to be kept in good health and with their wits about them while they wait"},
        {name: "Space Blanket", description: "The space blanket may prove helpful to conserve body heat and limit the energy allocated towards the life-support unit in keeping the module's internal heating and cooling at 100% capacity"},
        {name: "Dehydrated Food", description: "Food will be necessary, especially during a post-traumatic stress scenario such as a crash landing"},
        {name: "Solar Powered Radio", description: "Will allow you to be able to communicate with the outpost to direct them to your landing site"},
        {name: "Mechanical Repair Kit", description: "Vibrations could cause a crach in the window/airlock and you need to repair these vital items to survive while waiting "},
        {name: "30 Litres of H20", description: "Basic need for survival"},
        {name: "Oxygen", description: "Basic need for survival"},
    ];
    const events = [
        {name: "Solar Flare", stay: "-5% if you didn't rank the Radio in your top 10", circumvent: "-10% but +5% if you ranked the solar blanket in your top 10", through: "-10% but +5% if you ranked the Solar Blanket in your top 10"},
        {name: "Drone Fly-By", stay: "0% change", circumvent: "-10% but +5% if you ranked the Signal Flare in your top 10 and +5% if you ranked the Space Suit Repair Kit in your top 10", through: "-10% but +10% if you ranked the Signal Flare in your top 10"},
        {name: "Fracking", stay: "-10% change but +5% if you ranked the Mechanical Kit in your top 10", circumvent: "0% change", through: "0% change"}
    ];

    let { gameName } = useParams();

    useEffect(() => {
        var unsubscribe = listenToDocument(artemisDB, "artemis" + gameName, "gameSettings", setGameDetails);
        return unsubscribe;
    }, [props.instance]);

    useEffect(
        () => {
            function listener(e) {
                console.log(e)
                console.log(ctr);
                if (e.code === 'Space'){
                    setCtr(ctr + 1);
                }
            }
            window.addEventListener('keydown', listener);
            return function cleanup() {
                window.removeEventListener('keydown', listener)
            };
        }, [ctr]
    );

      const getStrategy = (strategy) => {
        if (strategy === "1"){
            return "Stay Put";
        }
        else if (strategy === "2"){
            return "Through";
        }
        return "Circumvent"
      }

      let num = 0;
      let side = "flex-end";

    return(
        <React.Fragment>
            <br />
            <Container>
                <Row>
                    <FlexCol>
                        <TitleContainer>
                            <StyledH1>Project Artemis</StyledH1>
                        </TitleContainer>
                    </FlexCol>
                </Row>
                <br />
                <Row>
                    <FlexCol>
                        <BoldP>
                            A Lunar Disaster Scenario Answer Key
                        </BoldP>
                    </FlexCol>
                </Row>
                <Row>
                    <FlexCol>
                        <StyledP>
                            Can you help your crew survive a crash landing on the moon?
                        </StyledP>
                    </FlexCol>
                </Row>
                <Row>
                    <FlexCol onClick={() => {setIsOpenIdealStrategy(!isOpenIdealStrategy)}}>
                        <StrategyP>
                            Optimal Strategy
                        </StrategyP>
                    </FlexCol>
                </Row>
                <Row>
                    <FlexCol>
                        <Collapse isOpen={isOpenIdealStrategy}>
                            <StyledContainer>
                                <StyledAnswer>
                                    STAY PUT
                                </StyledAnswer>
                            </StyledContainer>
                        </Collapse>
                    </FlexCol>
                </Row>
            </Container>
            <Container>
                <Row>
                    <FlexCol onClick={() => {setIsOpenItems(!isOpenItems)}}>
                        <StrategyP>Item Rankings</StrategyP>
                    </FlexCol>
                </Row>
            </Container>
            <Collapse isOpen={isOpenItems}>
                {items.map(item => {
                    if (side === "flex-end"){
                        side = "flex-start";
                    }
                    else{
                        side = "flex-end";
                    }
                    return <ArtemisScoreScreenItem side={side} item={item} />
                })}
            </Collapse>
            <Container>
                <Row>
                    <FlexCol onClick={() => {setIsOpenEvents(!isOpenEvents)}}>
                        <StrategyP>Effects of the Events</StrategyP>
                    </FlexCol>
                </Row>
            </Container>
            <Collapse isOpen={isOpenEvents}>
                {events.map(event => {
                    return <ArtemisScoreScreenEvent event={event} />
                })}
            </Collapse>
            <Container>
                <Row>
                    <FlexCol onClick={() => {setIsOpenTeams(!isOpenTeams)}}>
                        <StrategyP>Team Results</StrategyP>
                    </FlexCol>
                </Row>
                <Collapse isOpen={isOpenTeams}>
                    <Row>
                        <Col>
                            {ctr === 0 && <WhiteCenterP>Press Space to Begin</WhiteCenterP>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {ctr !== 0 && <StyledTable>
                                <StyledTR>
                                    <th>Rank</th>
                                    <th>Control Center</th>
                                    <th>Strategy</th>
                                    <th>Survival %</th>
                                </StyledTR>
                                {gameDetails !== undefined && gameDetails.teamScores !== undefined && Object.entries(gameDetails.teamScores)
                                    .sort((a,b) => parseInt(a[1].score) > parseInt(b[1].score) ? 1 : parseInt(a[1].score) < parseInt(b[1].score) ? -1 : 0)
                                    .map((team) => {
                                        num++;
                                        return (
                                            (ctr >= num) && <StyledTR key={team[0]}>
                                                <td>{Object.entries(gameDetails.teamScores).length - num + 1}</td>
                                                <td>CC {team[0]}</td>
                                                <td>{getStrategy(team[1].strategy)}</td>
                                                <td>{team[1].score}</td>
                                            </StyledTR>)
                                })}
                            </StyledTable>}
                        </Col>
                    </Row>
                </Collapse>
            </Container>
            <Background></Background>
            <BackgroundCover></BackgroundCover>
        </React.Fragment>
    )
}

export default ArtemisScores;

const StyledTable = styled(Table)`
    background-color: rgba(0,0,0,0.8);
`;

const StyledTR = styled.tr`
    color: white;
`;

const StrategyP = styled.p`
    font-size: 64px;
    color: white;
`;

const StyledAnswer = styled.h1`
    color: white;
    margin-top: 30px;
    margin-bottom: 30px;
`;

const StyledContainer = styled(Container)`
    border-radius: 20px;
    border-width: 1px;
    border-color: white;
    border-style: solid;
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FlashAnimation = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const WhiteCenterP = styled.p`
    color: white;
    margin: 0px;
    font-weight: bold;
    text-align: center;
    animation: ${FlashAnimation} 2s linear infinite alternate;
`;

const TitleContainer = styled.div`
    border-color: white;
    border-style: solid;
    border-radius: 10px;
    border-width: 1px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
`;

const StyledH1 = styled.h1`
    color: white;
`;

const FlexCol = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const BackgroundCover = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0px;
    left: 0px;
    z-index: -1;
`;

const Background = styled.div`
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-image: url("https://cdn.moniker-virtual.com/artemis/images/background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: white;
    top: 0px;
    left: 0px;
    z-index: -2;
    color: white;
`;

const StyledP = styled.p`
    color: white;
    font-weight: regular;
    margin: 0px;
    text-align: center;
`;

const BoldP = styled.p`
    color: white;
    font-weight: bold;
    margin: 0px;
    text-align: center;
`;
