import React, { useEffect, useState } from "react";
import { Col, Button, Row } from "reactstrap";
import { Colors, 
    OutputRow, 
    BoldLabel, 
    LeftLabel, 
    HighlightedCost, 
    TableTitle,
    WhiteTableContainer,
    UnderlineButton,} from "../../../../utils/budget-tool-specific";

import AccommodationRow from "./exp-accommodation-row";
import FlightRow from "./exp-flight-row";
import ActivityRow from "./exp-activity-row";
import TransportationRow from "./exp-transportation-row";
import MealsRow from "./exp-meals-row";
import MiscellaneousRow from "./exp-miscellaneous-row";
import FinalCostRow from './final-cost-row';
import ContingencyRow from "./exp-contingency-row";
import { displayValue } from "../../helper";
import { calculateSubCostPerAttendee, 
    calculateTotalCostPerAttendee, 
    calculateSubCostNoFlightsPerAttendee,
    calculateCostPerAttendee, } from "./cost-helper";
import MeetingsRow from "./exp-meetings.row";
import styled from "styled-components";
import CostOutput from "./cost-output";

const CostSummary = ({guideData, expData}) => {

    const [clientCurrency, setClientCurrency] = useState(true);

    const toggleCurrency = (value) => {
        setClientCurrency(value);
    }

    const activeButton = {
        backgroundColor: Colors.primaryAccent,
        border: "2px solid" + Colors.primaryAccent,
        borderRadius: "0px"
    }
    const inactiveButton = {
        backgroundColor: "white",
        color: Colors.primaryAccent,
        border: "2px solid" + Colors.primaryAccent,
        borderRadius: "0px"
    }

    const expDataRX = {...expData, base: {value: 1, invalid: false, required: true}, convert: {value: 1, invalid: false, required: true}};
    const guideDataRX = {...guideData, currency: {value: expData.destCurrency.value, invalid: false, required: true}};

    return (
            <React.Fragment>
            {guideData && expData && <React.Fragment>
                <Row>
                    <Col>
                        <h2 style={{color: Colors.primary, marginRight: "20px"}}>Cost Summary</h2>
                    </Col>
                    <Col style={{display: "flex", justifyContent:"flex-end", paddingRight: "30px"}}>
                    </Col>
                </Row>
                
                <br/>

                <h3 style={{color: Colors.primaryAccent}}>{expData.title.value}, {expData.location.value}</h3>
                <Row>
                    <Col>
                        <PrimaryButton style={clientCurrency ? activeButton : inactiveButton} onClick={() => {toggleCurrency(true)}}>{guideData.currency.value}</PrimaryButton>
                        <PrimaryButton style={!clientCurrency ? activeButton : inactiveButton} onClick={() => {toggleCurrency(false)}}>{expData.destCurrency.value}</PrimaryButton> 
                    </Col>
                </Row>      
           <br/>
                { clientCurrency && <CostOutput guideData={guideData} expData={expData}/>}
                {!clientCurrency && <CostOutput guideData={guideDataRX} expData={expDataRX}/>}
            </React.Fragment>}
            
        </React.Fragment>
    )
}

export default CostSummary;


const PrimaryButton = styled(Button)`
    &: focus {
        border: none;
    }
`